import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from './Table'
import * as Icon from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link, Navigate } from 'react-router-dom'
import currency from 'currency.js'

import { apiProducts, apiArticles } from './services/api/utilities'
import { apiPlantIdentify } from './services/api/plant-id'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import ConfirmProductModal from './Modals/ConfirmProductModal'
import AddArticleModal from './Modals/AddArticleModal'
import ImageDropperAccordion from './ImageDropperAccordion'
import PermissionsGate from './PermissionsGate'

import cloneDeep from 'lodash'

const imageRoot=`${process.env.REACT_APP_IMAGES}/`;
const thumbnailRoot=imageRoot + "thumbnails/";


const currencyFormat = {
  groupSeparator : ', ',
  group : ', ',
  decimalSeparator : '.',
  decimal : '.',
  prefix : '€ ',
  symbol : '€ ',
  fixedDecimalLength : 2,
};
const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function() {
			resolve(reader.result);
		};
		reader.onerror = reject;
	});
}

function readFile(file) {
	return new Promise((resolve, reject) => {
		var fr = new FileReader();
		fr.onload = () => {
			resolve(fr.result)
		};
		fr.onerror = reject;
		fr.readAsText(file.blob);
	});
}

function sortPrices( a, b ) {
  if ( a.pa < b.pa ){
    return -1;
  }
  if ( a.pa > b.pa ){
    return 1;
  }
  return 0;
}



function ArticlesOverview () {
  const [articles, setArticles] = useState([]);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [articleModalInitalState, setArticleModalInitalState] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState();
  const [redirect, setRedirect, ] = useState();
  const dispatch=useDispatch();
  const identifyThreshold = 0.9;


  const keys = ['pa', 'pv'];
  const prices = articles.filter((value, index, self) =>
    self.findIndex(v => keys.every(k => v[k] === value[k])) === index
  ).map(({pv,pa}) => ({ pv, pa})).sort(sortPrices);

  const handleUpload = (e, priceIndex) => {
		let fileList = Array.from(e);
		// Get Base64 from Uploaded Images
		Promise.all(fileList.map(file => getBase64(file)))
			// Pass those to Plant-id
			.then(files =>
				// apiPlantIdentify.test(
					apiPlantIdentify.post(
					       files.map(b64 => ({ images: [b64] }))
					// Use response to populate "New Products" Array for Modal
        ).then(data => setSuggestions(fileList.map((file, i) => {
  					let { suggestions } = data[i];
            // Return suggestions or
  					// if any suggestion beats threshold, guess
            return mostLikelyToProducts(suggestions, file, priceIndex);
				})))
      );
	}

  const mostLikelyToProducts = (suggestions, file, priceIndex) => {
    // If the certainty rate of any suggestions surpasses the configured rate,
    //   return that suggestion, mapped to product
    // Else return all mapped to products
    let guess = suggestions.filter((s,i) =>
      s.probability >= identifyThreshold).length === 1 ?
        suggestions.find(s => s.probability >= identifyThreshold)
        : false;
    return ({
      name: guess ?
        guess.plant_name :
        '',
      images: [{
        url: URL.createObjectURL(file),
        path: URL.createObjectURL(file)
      }],
      suggestions: guess ?
        [guess] :
        suggestions,
      file: file,
      priceIndex,
      pv : prices[priceIndex].pv,
      pa : prices[priceIndex].pa,
    })
  }

  const handleSelect = (product) => {
    if(product?.id === undefined) return false;
    let {id, pv, pa} = product;
    setArticleModalInitalState({ product_id : id, pv, pa});
    setShowArticleModal(true);
  }

  const handleCreate = (product, article = null) => {
		apiProducts.uploadImage({ id : product.id, data: product.file}).then((image) => {
      if(article) apiArticles.getSingle(article.id).then(data => setArticles([...articles, {...data, images: [image]}]));
    });
	}

  const handleMerge = (product) => {
    // apiArticles.post({articles : product.articles});
		apiProducts.uploadImage({ id : product.id, data: product.file });
	}

  const handleDelete = (id) => {
		apiArticles.remove(id);
		setArticles(articles.filter((article) => article.id !== id));
	}


  useEffect(() => {
    let active = true;
    apiArticles.getAll().then(data =>{
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      if(active) {
        setArticles(data);
        setError();
      }
    });

    return () => {
      active = false;
    }
  },[]);


  if(redirect) return (<Navigate to={redirect} />)



  return (
    <>
      <h2>Articles</h2>
      {error ? <Alert>{error}</Alert> : null}
      <ConfirmProductModal
        onMerge={ handleMerge }
        onCreate = { handleCreate }
        products = { suggestions }/>
      <AddArticleModal
        initialState={articleModalInitalState}
        setUpdate={(newArticle) => {setArticles([...articles, newArticle])}}
        show={showArticleModal}
        setShow={setShowArticleModal} />
      <ImageDropperAccordion
        handleChange={handleUpload}
        handleSelect={handleSelect}
        entryLimit={20}
  		  deleteImage = { handleDelete }
  		  types = {['PNG', 'JPEG', 'JPG']}
  		  data = {
    			prices.map(price => {
            let priceArticles = articles.filter(
              article => article.pv === price.pv
              && article.pa == price.pa);
            return {
    					header: <>{currency(price.pv, currencyFormat).format()} <span className="currency-pa">{currency(price.pa, currencyFormat).format()}</span></>,
              price,
              images: priceArticles.map((article) => ({
    						id: article.id,
    						name:  <span>{article.product_name} <span className="article-size">({article.size} cm)</span></span>,
    						path: article.images.length ? article.images[0].thumbnail_path : null,
    						onClick : () => {setRedirect(`/product/${article.product_id}`)}
    					})),
            }
    			})
    		} />
    </>
  )

}

export default ArticlesOverview;
