import React from 'react'


function FlexTableFooter ({
   footerGroups = [],
  }) {


    return (
      <div className="tfoot">
        {footerGroups.map(group => (
          <div className="tr" {...group.getFooterGroupProps()}>
            {group.headers.map((column, i) => (
              <div className={`td ${i === 0 ? 'col-sticky' : ''}`}
                {...column.getFooterProps()}>
                {column.render('Footer')}
              </div>
            ))}
          </div>
        ))}
      </div>
    )
}



export default FlexTableFooter
