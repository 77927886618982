import { apiProvider } from './provider';


export class ApiCore {


	constructor(options) {

		// Set defaults
		options = {
			post: false,
			...options
		};


		if (options.post) {
			this.post = (model) => {
				if(Array.isArray(model)) {
					return Promise.all(model.map(m => apiProvider.post(options.url, m)));
				} else {
					return apiProvider.post(options.url, model);
				}
			};
		}
		if (options.test) {
			this.test = (model) => {
				if(Array.isArray(model)) {
					return Promise.all(model.map(m => apiProvider.test(options.url, m)));
				} else {
					return apiProvider.test(options.url, model);
				}
			};
		}
	}
}
