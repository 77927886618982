import React, { useEffect, useState, useRef } from 'react'
import FlexTable from './Table'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { Typeahead } from 'react-bootstrap-typeahead'
import Row from 'react-bootstrap/Row'
import Column from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'


// import { AddressAutofill, SearchBox } from '@mapbox/search-js-react'

import CurrencyInput, { formatValue } from 'react-currency-input-field'
import currency from 'currency.js'


import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
// import { FileUploader } from 'react-drag-drop-files'
import ImageDropper from './ImageDropper'
import { Link, Navigate, useParams } from 'react-router-dom'
import { apiProducts, apiProductCategories, apiArticles } from './services/api/utilities'
import { apiPlantIdentify } from './services/api/plant-id'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import PermissionsGate from './PermissionsGate'
import EntityTags from './EntityTags'
import Mapbox from './Mapbox'
import ArticlesForm from './ArticlesForm'

import useToken from './hooks/useToken'


const currencyFormat = {
  groupSeparator : ', ',
  decimalSeparator : '.',
  prefix : '€ ',
  fixedDecimalLength : 2,
};



const imageRoot=`${process.env.REACT_APP_IMAGES}/`;
const thumbnailRoot=imageRoot + "thumbnails/";

// const getBase64 = (file) => {
//    var reader = new FileReader();
//    reader.readAsDataURL(file);
//    reader.onload = function () {
//      console.log(reader.result);
//    };
//    reader.onerror = function (error) {
//      console.log('Error: ', error);
//    };
// }

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);



function ProductDetail () {
  const {token} = useToken();
  const [loading, setLoading] = useState(true);
  const [backToOverview, setBackToOverview] = useState(false);
  const [error, setError] = useState();
  const [update, setUpdate] = useState(false);
  const [product, setProduct] = useState({images:[]});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const id = useParams().productId;

  const [multiplier, setMultiplier] = useState(1);
  let previous = undefined;

  const handleChange = (files) => {
    let fileArray = Array.from(files);

    setProduct({...product, images: [...product.images,...fileArray.map(img => ({
      added : true
    }))]});
    Promise.all(fileArray.map(file =>
      apiProducts.uploadImage({id, data:file}))
    ).then(() => setUpdate(!update));
  }

  const allowNewCategory = (results,props) => {
    // IF ENTRY SAME AS EXISTING OPTION, DON'T SHOW AS NEW
    let newCategory = props.text;
    while(newCategory.charAt(0) === "-")
      newCategory = newCategory.substring(1);
    newCategory = newCategory.trim();
    if(!newCategory || newCategory === "") return false;
    if(categories.filter(r => r.name === newCategory).length > 0)
      return false;
    else
      return true;
  }

  const handleNewCategory = (category) => {

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let fields = [ "name",
          "availability",
          "product_category_id"
        ];

    let request = fields.reduce(
      (acc,current) => ({
         ...acc,
         [current] : e.target.elements[current].value
       }),{id});

    // ["pv_default","pa_default"].forEach((currencyField, i) => {
    //   request[currencyField] = currency(request[currencyField]);
    // });

    apiProducts.patch(id,request).then(res => {
      if(res.hasOwnProperty('error')) {
        setError(res.error);
        return res;
      }
      setError();
    });


        // = Array.from(e.target.elements).map(e => e.value);


  }

  const handleDelete = (index) => {
    product.images.splice(index,1,{
        ...product.images[index],
        deleted : true
      });
    setProduct({...product});

    apiProducts.deleteImage({
      id,
      index
    }).then(() => setUpdate(!update));
  }

  const handleChangeAlt = (index, alt) => {
    apiProducts.editImage({id, index, alt});
    setProduct({
      ...product,
      images : [...product.images.map((image, i) => (index === i ? {
        ...image,
        alt
      } : image))]
    });
  }

  const moveImage = (idxFrom,idxTo) => {
    if(!product) return true;


    product.images.splice(idxTo, 0, product.images.splice(idxFrom, 1)[0]);
    apiProducts.editImage({id, index: idxFrom, idx: idxTo});

    setProduct({ ...product});

  }


  useEffect(() => {
    let active = true;
    Promise.all([
      apiProducts.getSingle(id).then(data =>{
        if(data.hasOwnProperty('error') && active) {
         setError(data.error);
         return data;
        }
        if(active) {
          setProduct(data);
          setSelectedAvailability([data.availability]);

        }
        return data;
      }),
      apiProductCategories.getAll().then(data =>{
        if(active) {
          setCategories(data);

        }
        return data;
      })]).then((data) => {
        setLoading(false);
        let selCat = data[1].filter(c => c.id === data[0].product_category_id);
        if(selCat.length > 0) setSelectedCategory([{...selCat[0], label: selCat[0].name}]);
      });

    return () => {
      active = false;
    }
  },[update]);

  if(backToOverview)
    return(<Navigate to="/products" />)

  if(loading || !product) return "...";

  return (
    <>
      <h2>{product.name} <EntityTags api={apiProducts} id={product.id} initial={product.tags} /></h2>
      { error ?
        <Alert>{error}</Alert> : null
      }



      <ImageDropper
        editImageAlt={handleChangeAlt}
        handleChange={handleChange}
        moveImage={moveImage}
        deleteImage={handleDelete}
        types={["JPG", "PNG", "GIF"]}
        images={product.images.map((image, idx) => ({
          image_path : `${imageRoot}product_${image.hash}.png`,
          thumbnail_path : `${thumbnailRoot}product_${image.hash}.png`,
          alt : image.alt,
          idx,
          deleted : image.deleted ? true : false,
          added : image.added ? true : false,
        }))} />


        <Form onSubmit={handleSubmit}>
          <Row>
            <Column md="6" lg="5">
              <Form.Group>
                <Form.Control name="name"  defaultValue={product.name}></Form.Control>
                <Form.Label>Nom</Form.Label>
              </Form.Group>
            </Column>
          </Row>
          <Row>
            <Column xs="6" lg="4">
              <Form.Group>
                <Form.Control
                  name="product_category_id"
                  value={selectedCategory.length > 0 ? parseInt(selectedCategory[0].id) : ''}
                  type="hidden"></Form.Control>
                <Typeahead
                  id="product-category-name"
                  onChange={(c) => {
                    if(c.length === 0) {
                      setSelectedCategory([]);
                      return true;
                    }
                    let newCategories = c.filter(cat => cat.customOption).map(cat => ({
                      name : cat.label,
                      label : (cat.parent_id ? "- " : "") + cat.label,
                      id : cat.id,
                      customOption : cat.customOption
                    }));

                    if(newCategories.length > 0) {
                      apiProductCategories.post(newCategories).then(insertedData => {
                        apiProductCategories.getAll().then(data => {
                          
                          setCategories(data);
                          setSelectedCategory(insertedData);
                        });
                      });
                    } else {
                      setSelectedCategory(c);
                    }
                  }}
                  labelKey="label"
                  emptyLabel={`No matches`}
                  allowNew={allowNewCategory}
                  clearButton={true}
                  options={categories.map(category => ({id : category.id, label : (category.parent_id ? "- " : "") + category.name, parent_id : category.parent_id}))}
                  placeholder="Choose a category..."
                  defaultSelected={selectedCategory}
                />
                <Form.Label>Categorie</Form.Label>
              </Form.Group>
            </Column>
            <Column xs="6" lg="3">
              <Form.Group>
                <Form.Select name="availability" aria-label="availability" defaultValue={product.availability}>
                  {['OK','FEW','EMPTY'].map((status,i) => (
                    <option key={`availability-${i}`} value={status}>{status}</option>
                  ))}
                </Form.Select>
                <Form.Label>Status</Form.Label>
              </Form.Group>
            </Column>
          </Row>
          <Row>
            <ArticlesForm
              product_id={product.id}
              isSubmit={isSubmit}
              onSubmit={(articles) => {setIsSubmit(false)}} />
          </Row>
          <Row>
            <Column xs="12">
              <Button type="submit">Sauver les changements</Button>
            </Column>
          </Row>
          <Row>
            <Column xs="12">
              {/*handleDelete, message, title*/}
              <ConfirmDeleteModal
                message={`Vous souhaitez supprimez le produit ${product.name}?`}
                handleDelete={() => {
                  apiProducts.remove(product.id).then(data => {
                    if(data.hasOwnProperty('error')) {
                      setError(data.error);
                      return data;
                    }
                    setBackToOverview(true);
                  });
                }}>
                <Button variant="danger">Supprimer le produit</Button>
              </ConfirmDeleteModal>
            </Column>
          </Row>


        </Form>

    </>
  )

}

export default ProductDetail;
