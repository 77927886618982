import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import { Form } from 'react-bootstrap'
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'
import { apiCompanies } from '../services/api/utilities'


function AddCompanyModal({setUpdate, initialState, show, setShow}){
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    name = null
  } = initialState;


  const handleConfirm = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = [
      "name"
    ];
    let model = {};
    fields.map(field => {model[field] = elems.namedItem(field)?.value});

    apiCompanies.post({ companies : [model]}).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }

      setUpdate([...data.map(company => ({
        ...company,
        label : company.name,
        id : company.id,
      }))]);
      setShow(false);
      setError();
    });

  }


  return(
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un entreprise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            <Row>
              <Col xs="12">
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control type="text" name="name" defaultValue={name} />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddCompanyModal
