import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import * as Icon from 'react-bootstrap-icons'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom'
import { apiBills, apiContacts, apiCompanies, apiClients, apiArticles } from './services/api/utilities'

import ArticlesSelector from './ArticlesSelector'
import BillArticlesTable from './BillArticlesTable'
import ArticlesGallery from './ArticlesGallery'


function BillPreview() {
  const id = useParams().billId;
  const user = useSelector(state => (state.user));
  const [bill, setBill] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDownload = () => {
    const FileDownload = require('js-file-download');
    let author = `${user.first_name} ${user.last_name}`;
    let filename = `${bill.bill_no}.pdf`;
    apiBills.getPdf({ id }).then(data => {
      if(data.hasOwnProperty('error')) {
        console.warn(data.error);
        return data;
      }
      FileDownload(data, filename);
    });
  }

  useEffect(() => {
    let active = true;

    apiBills.getSingle(id).then(data => {

      if(data.hasOwnProperty('error')) {
        setError(data);
        setLoading(false);
        return data;
      }
      if(active) {
        setBill(data);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    }
  },[]);


  if(loading) return("...");

  
  const {
    bill_no, client_name,
    from_address1, from_address2, from_locality, from_country, from_postal_code,
    rcs, tva, rib, company_name,
    contact_salutation, contact_tel, contact_mail, contact_first_name, contact_last_name
  } = bill;

  const {
    address1, address2, locality, country, postal_code,
  } = bill.addresses.length ? bill.addresses[0] : {};


  return (
    <div className="bill-preview">

      <Row>
        <Col><h2><Link to="/bills" style={{color : 'white'}}>Facture {bill_no}</Link></h2></Col>
      </Row>



      <div className="bill-preview-box">
      <Row>
        <Col>
          <h4>Addresse (de)</h4>
          {from_address2} {from_address1}<br />
          {from_postal_code} {from_locality}<br />
          {from_country}
          <br />
        </Col>
      </Row>

      <Row>
        <Col>
          <h4>À {contact_salutation} {contact_first_name} {contact_last_name} ({client_name})</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <strong>{company_name ? company_name : client_name}</strong><br />
          {address2} {address1}<br />
          {postal_code} {locality}<br />
          {country}<br />
          RIB: {rib}<br />
          RCS: {rcs}<br />
          TVA: {tva}<br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg="8" style={{ whiteSpace : 'pre'}}>
          {bill.text_top}
        </Col>
      </Row>

      <Row>
        <Col><h4 id="articles">Articles <Icon.Box /></h4></Col>
      </Row>
      <Row>
        <Col xs="12" lg="8">
          <BillArticlesTable
            bill={bill}
            readOnly={true} />

        </Col>
      </Row>


      {!bill.articles.filter(article => article.attach_images).length ? null :
        <>
          <Row>
            <Col><h4 id="articles">Images <Icon.Camera /></h4></Col>
          </Row>
          <Row>
            <Col xs="12" lg="8">
              <ArticlesGallery
                articles={bill.articles.filter(article => article.attach_images)} />
            </Col>
          </Row>
        </>}


      <Row>
        <Col xs="12" lg="8" style={{
          whiteSpace : 'pre'
        }}>
          {bill.text_bottom}
        </Col>
      </Row>
      </div>

      <Button variant="primary" size="sm"><a href={`${process.env.REACT_APP_API_ROOT}/bill/${id}/pdf`}><Icon.FilePdf /> Créer un pdf</a></Button>
      <Button onClick={() => navigate(`/bill/${bill.id}/edit`)} variant="secondary" size="sm">Modifier la facture</Button>


    </div>
  )
}

export default BillPreview
