import React, { useEffect, useState, useRef } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, Navigate, useParams } from 'react-router-dom'
import { apiClients, apiGeography, apiContacts, apiCompanies } from './services/api/utilities'

import AddressForm from './AddressForm'
import AddAddressModal from './Modals/AddAddressModal'
import AddContactModal from './Modals/AddContactModal'
import AddCompanyModal from './Modals/AddCompanyModal'


function ClientForm() {
  const id = useParams().clientId;
  const [client, setClient] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [fieldContactManager, setFieldContactManager] = useState([]);
  const [fieldContact, setFieldContact] = useState([]);
  const [fieldCompany, setFieldCompany] = useState([]);
  const [addresses, setAddresses] = useState(client.addresses);

  const [contacts, setContacts] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [contactModalShow, setContactModalShow] = useState(false);
  const [contactManagerModalShow, setContactManagerModalShow] = useState(false);
  const [companyModalShow, setCompanyModalShow] = useState(false);







  useEffect(() => {
    let active = true;

    apiClients.getSingle(id).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data);
        return data;
      }
      if(active) {
        setClient(data);
        setAddresses(data.addresses);
      }
      let { contact_id, manager_contact_id, company_id } = data;

      let requests = [];

      requests.push(apiContacts.getAll().then(dataContacts => {
        if(dataContacts.hasOwnProperty('error')) {
          setError(dataContacts);
          return dataContacts;
        }
        if(active) setContacts(dataContacts);

        let selectedContact = dataContacts
                .filter(contact => contact.id === contact_id)
                .map(contact => ({
                  id: contact.id,
                  label: `${contact.first_name} ${contact.last_name}`,
                  first_name : contact.first_name,
                  last_name : contact.last_name,
                }));

        let selectedContactManager = dataContacts
                .filter(contact => contact.id === manager_contact_id)
                .map(contact => ({
                  id: contact.id,
                  label: `${contact.first_name} ${contact.last_name}`,
                  first_name : contact.first_name,
                  last_name : contact.last_name,
                }));

        if(contact_id !== undefined && selectedContact.length)
          setFieldContact(selectedContact);
        if(manager_contact_id !== undefined && selectedContactManager.length)
          setFieldContactManager(selectedContactManager);
      }));

      requests.push(apiCompanies.getAll().then(dataCompanies => {
        if(dataCompanies.hasOwnProperty('error')) {
          setError(dataCompanies);
          return dataCompanies;
        }

        if(active) setCompanies(dataCompanies);
        let selectedCompany = dataCompanies
                .filter(company => company.id === company_id)
                .map(company => ({ label : company.name, id: company.id}));
        if(company_id !== undefined && selectedCompany.length)
          setFieldCompany(selectedCompany);
      }));

      Promise.all(requests).then(() => {
        if(active)
          setLoading(false);
      });
    });




    return () => {
      active = false;
    }
  },[]);


  const allowNewContact = (results,props) => {
    // IF ENTRY SAME AS EXISTING OPTION, DON'T SHOW AS NEW
    let newContact = props.text;
    newContact = newContact.trim();
    if(!newContact || newContact === "") return false;
    if(contacts.filter(contact => newContact === `${contact.first_name} ${contact.last_name}`).length > 0)
      return false;
    else
      return true;
  }

  const handleNewContact = (roleIndex,initialState) => {
    const setModalShow = [
      setContactModalShow,
      setContactManagerModalShow
    ];
    const setField = [
      setFieldContact,
      setFieldContactManager,
     ];
    if(roleIndex < 0 || roleIndex > setModalShow.length || roleIndex > setField.length)
      return false;

    let helperProps = {};
    let newLabel = initialState.label.trim();
    switch (newLabel.split(' ').length) {
      case 1:
        helperProps = {
          last_name :newLabel.split(' ')[0],
        }
        break;
      case 2:
        helperProps = {
          first_name :newLabel.split(' ')[0],
          last_name :newLabel.split(' ')[1],
        }
        break;
      case 3:
        if(newLabel.split(" ")[0].includes("."))
          helperProps = {
            salutation : newLabel.split(' ')[0],
            first_name :newLabel.split(' ')[1],
            last_name :newLabel.split(' ')[2],
          }
        else
          helperProps = {
            first_name :`${newLabel.split(' ')[0]} ${newLabel.split(' ')[1]}`,
            last_name :newLabel.split(' ')[2],
          }
        break;
      default:
        helperProps = {
          first_name :`${newLabel.split(' ')[0]} ${newLabel.split(' ')[1]}`,
          last_name :newLabel.split(' ')[2],
        }
    }

    setField[roleIndex]([{...initialState, ...helperProps}]);
    setModalShow[roleIndex](true);
  }

  const allowNewCompany = (results,props) => {
    // IF ENTRY SAME AS EXISTING OPTION, DON'T SHOW AS NEW
    let newCompany = props.text;
    newCompany = newCompany.trim();
    if(!newCompany || newCompany === "") return false;
    if(companies.filter(company => newCompany === company.name).length > 0)
      return false;
    else
      return true;
  }

  const handleNewCompany = (initialState) => {
    let helperProps = {};
    let newLabel = initialState.label.trim();
    setFieldCompany([{...initialState, name : newLabel}]);
    setCompanyModalShow(true);
  }


  const addAddress = (address) => {
    setAddresses([...addresses,address]);
  }

  const removeAddress = (addressIndex) => {
    setAddresses(addresses.map((a,i) => i===addressIndex ? {...a, delete : true} : a));
    apiClients.deleteAddress({index : addressIndex, id : id}).then(data => {
      setAddresses(addresses.filter((addr,i) => i!==addressIndex));
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    let clientUpdate = {};

    let fields = Array.from(e.target.elements).filter(f => f.name);
    let clientFields = ["name", "company_id", "tva", "rib", "rcs", "contact_id", "manager_contact_id"]
                        .map(key => clientUpdate[key] = key.substr(-2) === "id" ? parseInt(fields.filter(input => input.name === key)[0].value) : fields.filter(input => input.name === key)[0].value);

    apiClients.patch(client.id, clientUpdate);

    addresses.map((address, index) => {
      let addressUpdate = {};
      let addressFields = [`address1`,`address2`,`locality`,`postal_code`,`country_code`]
                          .map(key => addressUpdate[key] = fields.filter(input => input.name === `addr-${index}-${key}`)[0].value);

      apiClients.editAddress({ index, id: client.id, ...addressUpdate});
    });

  }


  if(loading) return("...");


  
  return (
    <>
    <Form onSubmit={onSubmit}>
      <Row>
        <Col><h2><Link to="/clients" style={{color : 'white'}}>Client</Link></h2></Col>
      </Row>
      <Row>
        <Col><h3>Details</h3></Col>
      </Row>
      <Row>
        <Col xs="12" md="8" lg="6">
          <Form.Control type="text" name="name" defaultValue={client.name}/>
          <Form.Label>Nom</Form.Label>
        </Col>
        <Col xs="12" md="4" lg="3">
          <Form.Control type="hidden" value={fieldCompany.length ? parseInt(fieldCompany[0].id) : undefined} name="company_id" />
          <Typeahead
            id="company"
            labelKey="label"
            allowNew={allowNewCompany}
            clearButton={true}
            defaultSelected={fieldCompany}
            onChange={(selection) => { setFieldCompany(selection)}}
            onChange={(selection) => {
              if(selection.length && selection[0].customOption)
                handleNewCompany(selection[0]);
              else
                setFieldCompany(selection)
              }}
            options={companies.map((company, id) => ({id : company.id, label : company.name }))}
             />
          <Form.Label>Entreprise</Form.Label>
        </Col>
      </Row>

      <Row>
        <Col xs="6" md="4" lg="3">
          <Form.Control type="text" name="tva" defaultValue={client.tva} />
          <Form.Label>TVA</Form.Label>
        </Col>
        <Col xs="6" md="4" lg="3">
          <Form.Control type="text" name="rib" defaultValue={client.rib} />
          <Form.Label>RIB</Form.Label>
        </Col>
        <Col xs="6" md="4" lg="3">
          <Form.Control type="text" name="rcs" defaultValue={client.rcs} />
          <Form.Label>RCS</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col><h3>Contact</h3></Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg="5">
          <Form.Control type="hidden" value={fieldContact.length ? parseInt(fieldContact[0].id) : undefined} name="contact_id" />
          <Typeahead
            id="contact"
            allowNew={allowNewContact}
            clearButton={true}
            onChange={(selection) => {
              if(selection.length && selection[0].customOption)
                handleNewContact(0,selection[0]);
              else
                setFieldContact(selection)
              }}
            selected={fieldContact}
            options={contacts.map((contact, id) => ({
              id : contact.id,
              label : `${contact.first_name} ${contact.last_name}`,
              first_name : contact.first_name,
              last_name : contact.last_name,
            }))}
             />
          <Form.Label><Icon.PersonBadge />Contact</Form.Label>
        </Col>
        <Col xs="12" md="6" lg="4">
          <Form.Control type="hidden" value={fieldContactManager.length ? parseInt(fieldContactManager[0].id) : undefined} name="manager_contact_id" />
          <Typeahead
            id="manager"
            allowNew={allowNewContact}
            clearButton={true}
            selected={fieldContactManager}
            onChange={(selection) => {
              if(selection.length && selection[0].customOption)
                handleNewContact(1,selection[0]);
              else
                setFieldContactManager(selection)
              }}
            options={contacts.map((contact, id) => ({
              id : contact.id,
              label : `${contact.first_name} ${contact.last_name}`,
              first_name : contact.first_name,
              last_name : contact.last_name,
            }))}
             />
          <Form.Label><Icon.PersonBadge />Manager</Form.Label>
        </Col>
      </Row>
      {!addresses.length ? null :<> <Row>
        <Col><h3>{client.addresses.length > 1 ? 'Addresses' : 'Addresse'}</h3> </Col>
      </Row>
      <Row>
        <Col lg="9">
          <Accordion alwaysOpen>
            {addresses.map((address, i) => (
              <Accordion.Item eventKey={i} key={`address-form-container-${i}`} className={`${address.delete ? 'delete' : ''} `}>
                <Accordion.Header>
                  {`${address.address1} ${address.address2}, ${address.locality}, ${address.country}`}
                </Accordion.Header>
                <Accordion.Body>
                  <AddressForm
                    key={address.address1}
                    onUpdate={(u) => console.log(u)}
                    name={`addr-${i}`}
                    initialState={address}
                    useMap={false} />
                  <Button onClick={() => removeAddress(i)} variant="danger">Supprimer l'addresse</Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row></>}
      <Button type="submit">Sauvegarder des changements</Button>
    </Form>
    <AddContactModal
      show={contactModalShow}
      setShow={setContactModalShow}
      initialState={{
        ...(fieldContact.length ? fieldContact[0] : {}),
        company_id : fieldCompany.length ? fieldCompany[0].id : null
      }}
      setUpdate={(newContact) => {
        setContacts([...contacts, ...newContact]);
        setFieldContact(newContact);
      }} />
    <AddContactModal
      show={contactManagerModalShow}
      setShow={setContactManagerModalShow}
      initialState={{
        ...(fieldContactManager.length ? fieldContactManager[0] : {}),
        company_id : fieldCompany.length ? fieldCompany[0].id : null
      }}
      setUpdate={(newContact) => {
        setContacts([...contacts, ...newContact]);
        setFieldContactManager(newContact);
      }} />
    <AddCompanyModal
      show={companyModalShow}
      setShow={setCompanyModalShow}
      initialState={{
        ...(fieldCompany.length ? fieldCompany[0] : {})
      }}
      setUpdate={(newCompany) => {
        setCompanies([...companies, ...newCompany]);
        setFieldCompany(newCompany);
      }} />
    <AddAddressModal clientId={id} setUpdate={addAddress} />
    </>
  )
}

export default ClientForm
