import React, { Suspense, useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Link, Navigate, useParams } from 'react-router-dom'
import { apiProducts, apiArticles } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import ArticleEdit from './ArticleEdit'



function ArticlesForm({
    product_id,
    isSubmit = false,
    onSubmit = (arg) => (null),
    getProduct = () => (null),
    mini = false,
    pa = 0,
    pv = 0,
    allowNew = true,
    allowDelete = true,
  }) {

  const newArticle = {
    id : 'new',
    pa,
    pv,
    size: 15,
    tarif : null,
    type : 'blur',
    product_id : product_id === undefined ? null : product_id
  };
  const [articles, setArticles] = useState([]);
  // const [articles, setArticles] = useState(mini ? [newArticle] : []);
  const [loading, setLoading] = useState(true);
  const [newCount, setNewCount] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [error, setError] = useState();



  useEffect(() => {
    let active = true;

    if(product_id && !mini) {
      apiProducts.getArticles({ id : product_id }).then(data => {
        if(active)  {
            setArticles(data);
            setLoading(false);
        }
      });
    } else {
      if(active) {
        setArticles([{...newArticle}]);
        setLoading(false)
      }
    }

    return () => {
      active = false;
    }
  },[]);

  useEffect(() => {
    let active = true;
    if(isSubmit) {
      if(product_id === undefined) product_id = getProduct()?.id;
      apiArticles.putMultiple({
        articles : articles.map(article => ((""+article.id).includes('new') ? {...article, id: null} : article))
      }).then(res => {
        if(res.hasOwnProperty('error')) {
          setLoading(false);
          return setError(res.error);
        }
        apiProducts.getArticles({ id : product_id }).then(data => {
          if(active)  {
              setLoading(false);
              if(data.hasOwnProperty('error')) return setError(res.error);
              setArticles(data);
              onSubmit(data);
          }
        });
      });
    }
    return () => {
      active = false;
    }
  }, [isSubmit]);






  const handleAdd = (article) => {
    setArticles([...articles,{...article, id: `new-${newCount}`}]);
    setNewCount(newCount+1);
  }

  const handleDelete = (id) => {
    setArticles(articles.filter(article => article.id !== id));
    apiArticles.remove(id);
  }



  return (
    <>
      {mini ? null :<Col xs="12">
        <h3 id="articles">Articles</h3>

      </Col>}
      {loading ? null :
        <div className={`articles-form ${mini ? `articles-form-mini` : ""}`}>
          {articles.map((article,i) => (
            <ArticleEdit
              key={`article-${article.id}`}
              handleDelete={() => handleDelete(article.id)}
              allowDelete={allowDelete}
              article={article}
              layout={mini ? "mini" : "default"}
              onUpdate={(updatedArticle) => {
                setArticles(articles.map((article) => {
                  if(article.id !== updatedArticle.id) return article;
                  return updatedArticle;
              }))}} />
            )
          )}
          {allowNew ? <Button size="sm" variant="secondary" onClick={() => handleAdd(newArticle)}>
            Ajouter un tarif <Badge><Icon.Plus /></Badge>
          </Button> : null}
        </div>

      }
    </>
  )
}

export default ArticlesForm
