import React, { useEffect, useState, useRef } from 'react'
import FlexTable from './Table'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import Row from 'react-bootstrap/Row'
import Column from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'



import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom'
import { apiProducts, apiProductCategories, apiArticles } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import PermissionsGate from './PermissionsGate'
import EntityTags from './EntityTags'

const imageRoot=`${process.env.REACT_APP_IMAGES}/`;
const thumbnailRoot=imageRoot + "thumbnails/";




function ProductSelector ({ onSubmit = (fieldProduct) => {}, placeholder = "Chercher un produit"}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [products, setProducts] = useState([]);
  const [fieldProduct, setFieldProduct] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    let active = true;
    apiProducts.getAll().then(data => {
      setProducts(data);
      setLoading(false);
    });
    return () => {
      active = false;
    }
  },[]);

  const handleChange = ([product]) => {

    onSubmit([product]);
  }

  const renderMenu= (results, menuProps) => {
    return (
      <Menu {...menuProps} maxHeight={'50vh'}>

      {results.map((result, index) => (
          <MenuItem option={result} position={index}>
            {renderProductLabel(result)}

          </MenuItem>
      ))}
    </Menu>
    )
  }

  const renderProductLabel = ({ label, images}) => {
    return (
      <div className="product-selector-label">
        {!images.length ? null :
            <div className="product-selector-label-col asl-col-0">
              <img src={images[0].thumbnail_path} />
            </div>

        }
        <div className="product-selector-label-col asl-col-1">{label}</div>
      </div>
    )
  }


  if(loading || !products.length) return null;
  return (
      <Typeahead
        className="product-selector"
        id={`product-selector-${Math.random()}`}
        onChange={(c) => handleChange(c)}
        labelKey="label"
        emptyLabel={`Produit non trouvable`}
        allowNew={false}
        clearButton={true}
        maxHeight={'30vh'}
        options={products.map(({id, name, images}) => ({id, label: name, images}))}
        placeholder={placeholder}
        defaultSelected={fieldProduct}
        renderMenu={renderMenu}
      />
  )

}

export default ProductSelector
