import React from 'react'

const sortFloatNegative = (rowA, rowB, columnId) => {
  /*
  REFACTORING
  location: FlexTable
  hook?: yes
  */
  let [a, b] = [rowA.values[columnId], rowB.values[columnId]];

  const replaceNonNumeric = /[^0-9.-]/gi

  a = Number(String(a).replace(replaceNonNumeric, ''))
  b = Number(String(b).replace(replaceNonNumeric, ''))

  return a === b ? 0 : a > b ? 1 : -1;
}


const handleScroll = () => {
  /*
  REFACTORING
  location: FlexTable
  hook?: yes
  */
  let stickyCols = document.querySelectorAll('.th.col-sticky, .td.col-sticky');
  if(stickyCols.length === 0) return true;
  let offsetLeft = document.querySelectorAll('.th.col-sticky, .td.col-sticky')[0].offsetLeft;
  if(offsetLeft !== 0) {
    stickyCols.forEach((col, i) => {
      if(!col.classList.contains('col-shadow'))
        col.classList.add('col-shadow');
    });
  } else {
    stickyCols.forEach((col, i) => {
      if(col.classList.contains('col-shadow'))
        col.classList.remove('col-shadow');
    });
  }
}

const defaultFilterTypes = {
      /*
      REFACTORING
      location: FlexTable
      hook?: no
      config: yes
      */

      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    };

const defaultColumnProps = {
  /*
  REFACTORING
  location: FlexTable
  hook?: no
  config: yes
  */

  // Let's set up our default Filter UI
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 150, // width is used for both the flex-basis and flex-grow
  maxWidth: 200, // maxWidth is only used as a limit for resizing
  sortType : sortFloatNegative,
  Placeholder : () => { return null },
  plottable : false
};



const IndeterminateCheckbox = React.forwardRef(
  /*
  REFACTORING
  location: FlexTable
  hook?: no
  config: no
  */
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export {
  sortFloatNegative,
  handleScroll,
  defaultFilterTypes,
  defaultColumnProps,
  IndeterminateCheckbox
}
