import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom'


// Route Components
import PermissionsGate, {hasPermission, anyPermission} from './PermissionsGate'
import PermissionsForm2 from './PermissionsForm2'
import ArticlesOverview from './ArticlesOverview'
import ProductsOverview from './ProductsOverview'
import ProductDetail from './ProductDetail'
import AddressForm from './AddressForm'
import BillsOverview from './BillsOverview'
import BillPreview from './BillPreview'
import BillForm from './BillForm'
import ClientsOverview from './ClientsOverview'
import ClientForm from './ClientForm'
import ContactOverview from './ContactOverview'
import ContactForm from './ContactForm'
import PricesOverview from './PricesOverview'
import ApiPreloader from './ApiPreloader'
import Dashboard from './Dashboard'
import Roles from './Roles'
import RoleDetail from './RoleDetail'
import RolesTable from './RolesTable'
import Users from './Users'
import UserDetail from './UserDetail'
import UsersTable from './UsersTable'
import HttpStatusError from './HttpStatusError'



function AppRoutes () {
  const user = useSelector(state => state.user);
  const location = useLocation();
  const updateScrollPosition =  () => {

    let hash = location.hash;
    if(hash) {
      let element = document.querySelector(hash);
      if(element) {
        element.scrollIntoView();
        clearInterval(interval);
      }

    }
  };

  const interval = setInterval(() => {
    if(location.hash) updateScrollPosition();
    else clearInterval(interval);
  }, 2 );



  return (
    <Routes>
        {hasPermission(['comptabilite'],user.permissions) ?
          <>
            <Route path="/bills" element={<BillsOverview />} />
            <Route path="/bill/:billId" element={<BillPreview />} />
            <Route path="/bill/:billId/edit" element={<BillForm />} />
            <Route path="/articles" element={<ArticlesOverview />} />
            <Route path="/products" element={<ProductsOverview />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/clients" element={<ClientsOverview />} />
            <Route path="/client/:clientId" element={<ClientForm />} />
            <Route path="/contacts" element={<ContactOverview />} />
            <Route path="/contact/:contactId" element={<ContactForm />} />
          </> : null
        }
        <Route index element={<Suspense fallback="loading"><Dashboard /></Suspense>} />
        {hasPermission(['edit_permissions'],user.permissions) ?
          <Route path="/roles" element={<Suspense fallback="loading"><Roles /></Suspense>}>
             <Route index element={<Suspense fallback="loading"><RolesTable /></Suspense>} />
             <Route path=":roleId" element={<Suspense fallback="loading"><RoleDetail /></Suspense>} />
          </Route> : null}

        <Route path="/users" element={<Suspense fallback="loading"><Users /></Suspense>}>
        {anyPermission(['edit_permissions','edit_user','add_user','rm_user'],user.permissions) ?
         <Route index element={<Suspense fallback="loading"><UsersTable /></Suspense>} />
         : null }
         <Route path=":userId" element={<Suspense fallback="loading"><UserDetail currentUser={user} /></Suspense>} />
        </Route>
        <Route path="*" element={<HttpStatusError code="404" message="Le site n'a pas été trouvé" />} />

    </Routes>
  )
}

export default AppRoutes
