import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { Form } from 'react-bootstrap';
import { apiRoles } from '../services/api/utilities';
import useToken from '../hooks/useToken';



function AddRoleFormModal({setUpdate}){
  const { token } = useToken();
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleConfirm = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = { token };
    for(let i=0;i<(elems.length-1);i++) {
      fields[elems.item(i).name] = elems.item(i).value;
    }

    apiRoles.post(fields).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setUpdate(true);
      handleClose();
    });

  }


  return(
    <>
      <Button size="sm" variant="secondary" onClick={handleShow}>
        Ajouter une role <Badge><Icon.Plus /></Badge>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            <Form.Group>
              <Form.Label>Name of new role</Form.Label>
              <Form.Control type="text" name="name" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddRoleFormModal;
