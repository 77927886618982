import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';
import { apiUsers } from '../services/api/utilities';


function RequestResetModal({setSuccess = (msg) => {}}){
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleConfirm = (e) => {
    e.preventDefault();
    let mail = e.target.elements.mail.value;
    apiUsers.forgotPassword({mail}).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setSuccess(`Un mail a été envoyé à '${mail}'`);
      handleClose();
    });

  }

  return(
    <>
      <Button size="sm" variant="link" onClick={handleShow}>
        J'ai oublié mon mot de passe
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Demander réinitialisation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert type="warning">{error}</Alert> : ''}
          <Form size="md" onSubmit={handleConfirm}>
            <Form.Group>
              <Form.Control required name="mail" type="text" />
              <Form.Label>Votre adresse mail</Form.Label>
            </Form.Group>
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default RequestResetModal;
