import React, { Suspense, useEffect, useState}  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import Container from 'react-bootstrap/Container'

import AppRoutes from './AppRoutes'
import AppOfflineRoutes from './AppOfflineRoutes'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'

import useToken from './hooks/useToken'
import usePersistFromDb from './hooks/usePersistFromDb'

import { apiSession, apiTables, apiGeography, apiUsers } from './services/api/utilities'

import { setUser, setUserPermissions } from './actions'

// For future reference:
// const Dashboard = React.lazy(() => import('./Dashboard'));



function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { token, setToken, verifyToken } = useToken();
  const { getPersist, lastModified, getLastStoreUser, persistComplete, updatePersist, updateStateFromDB } = usePersistFromDb();

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const defaultPath = useSelector(state => state.interface.path);

  const handleLogout = (e) => {
    updatePersist().then(data => {
      dispatch({ type: 'RESET_USER' });
      // Set token to undefined and the hook handles the storage and logout
      apiSession.logout();
      setToken();
    });
  }



  useEffect(() => {
    if( token === "") {
      setLoaded(true);
      return () => {};
    }


    //verify session and get current user data
    apiSession.getSingle().then(data => {
      if(data.hasOwnProperty('error')) {
        // If session request returns an error:
        // - log out
        setLoaded(true);
        handleLogout();
        return data;
      }






      // Go to last path from redux-state unless another path is specified
      if(location.pathname=="/" && defaultPath !== undefined)
        navigate(defaultPath);

      // Set user
      dispatch(setUser(data.user));
      dispatch(setUserPermissions(data.user.permissions));
      // setLoaded(true);

      // Update State from DB
      updateStateFromDB().then(data => {
        let dbTables = [];
        if(data.hasOwnProperty('dashboard'))
          dbTables = data.dashboard.tables;

        // Get tables
        apiTables.getAll().then(metadata => {
          if(metadata.hasOwnProperty('error')) {
            setLoaded(true);
            return metadata;
          }
          if(metadata.some((table,i) =>
            Date.parse(table.modified) > Date.parse(dbTables[i]?.modified)
          ) || metadata.length !== dbTables.length)
            dispatch({ type : 'SET_TABLES', payload : metadata});
          setLoaded(true);
        });
      });


      // Set geography
      apiGeography.getAll().then(
        data => dispatch({type : 'SET_GEOGRAPHY', payload : data})
      );


    });

    // Periodically verify token
    const interval = setInterval(() => {
      verifyToken().then((tokenActive) => !tokenActive ? setToken() : null)
    }, 60000 * 15 ); // 15min = 60000 * 15



    return () => {
      // Clear token check on unmount
      clearInterval(interval);
    }
  }, [token]);



  // Wait for user data to load before displaying anything
  if(!loaded)
    return (<p></p>);

  // If there is no token or token is no longer active
  // - allow login
  // - allow password reset
  if(token === "")
    return (<AppOfflineRoutes />)

  // Wait for persist action before displaying App
  if(!persistComplete)
    return(<p>...</p>);

  return (
    <Container className={`${sidebarExpanded ? 'container-sidebar-expanded ' : 'container-sidebar-collapsed '}`}>
      <AppSidebar setSidebarExpanded={setSidebarExpanded} handleLogout={handleLogout} />
      <AppHeader />
      <AppRoutes />
    </Container>
  )
}

export default App
