import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { Typeahead, AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import {setUserCoordinates} from '../actions';
import axios from 'axios';


const OSM_API_ENDPOINT = 'https://photon.komoot.io/api/';


/*
FILTERS are passed as osm_tags as an object like

const tags = {
  place: ["city","locality","house"],
  boundary : ["administrative"],
}

bounding box can be provided as an array bbox
[longmin,latmin,longmax,latmax]

eg France: [-5.225,41.333,9.55,51.2]
Austria: [1.2,46.373,19,49.017]
*/

const types = {
  "city" : {
    place: ["city","locality"],
    // boundary : ["administrative"],
  },
  "locality" : {
    place: ["city","town","locality","village","hamlet","suburb","province"],
  },
  "country" : {
    place : ["country"],
  },
  "street" : {
    // highway : ["street","road","pedestrian","residential","tertiary",
              // "unclassified","primary","secondary"],
    living_street : ["*"],
    highway : ['']
  }
}




function AddressTypeahead(props) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selected, setSelected] = useState();
  const coords = useSelector(state => (state.user.coords));
  const dispatch = useDispatch();
  const requestDelay = 1000; // 1s
  let delay;

  const defaultQuery = {
    lang : 'fr'
  }
  const {
    promptLimit = 3,
    defaultSelected,
    defaultInputValue,
    inputProps,
    placeholder = [],
    limit,
    name,
    bbox
   } = props;

  const selectedProp = props.selected;

  const getCoords = () => {
    if(coords.lat !== null && coords.lon !== null) return coords;
    return navigator.geolocation.getCurrentPosition(({coords}) => dispatch(setUserCoordinates({
      lat: coords.latitude,
      lon: coords.longitude
    })));
  }

  const getQueryString = (queryObj = {...defaultQuery}) => {
    let queryString = '?';
    Object.keys(queryObj).forEach((key) => {
      if(["string","number"].includes(typeof(queryObj[key])))
        queryString = `${queryString}${key}=${queryObj[key]}&`;
      if(Array.isArray(queryObj[key]))
        queryObj[key].map(value => {queryString = `${queryString}${key}=${value}&`});
    });
    return queryString;
  }

  const bboxToString = () => {
    if(!bbox) return false;
    if(typeof(bbox) === "string" && bbox.split(',').length === 4)
      return bbox;
    if(Array.isArray(bbox))
      return bbox.reduce((c,t) => (`${c},${t}`),'').substr(1)
  };

  const getOsmTags = useCallback(() => {
    let tags = [];
    // If no osm_tag properties given, return empty array
    if(!props.type && !props.tags) return tags;
    // If preset type given, test availability, map tags to array
    if(types.hasOwnProperty(props.type)) {
      Object.keys(types[props.type]).forEach((key) => {
        let values = types[props.type][key];
        tags = [...tags,...values.map(value =>
          (value==='' ? key : `${key}:${value}`)
        )];
      });
    }
    // Map provided tags to array
    if(props.tags) {
      Object.keys(props.tags).forEach(key => {
        let values = props.tags[key];
        // If string given, add one tag
        if(typeof(values) === "string")
          tags = [...tags,`${key}:${values}`];
        // If array given, add one tag for each item
        if(Array.isArray(values))
          tags = [...tags,...values.map(value => (`${key}:${value}`))];
      });
    }
    return tags;
  },[props.tags,props.type]);


  const formatDefault = (feature) => (
    `${feature.label}`
  );

  const handleChange = (q) => {
    if(q.length < promptLimit) {
      setSelected([]);
      return true;
    }

    window.clearTimeout(delay);

    let {lat,lon} = coords;
    let query = {
      ...defaultQuery,
      q
    };
    // if(coords.lat !== null && coords.lon !== null) query = {...query, ...coords};
    if(props.tags || props.type) query = {...query, osm_tag : getOsmTags()};
    if(bbox) query = {...query, bbox : bboxToString()};
    if(limit) query = {...query, limit};

    setLoading(true);
    delay = window.setTimeout(() =>
      axios.get(`${OSM_API_ENDPOINT}${getQueryString(query)}`)
      .then(handleResponse)
      .catch(handleError)
    );
  };

  const filterSuggestions = props.filterSuggestions ? props.filterSuggestions : (data) => (true);

  const handleResponse = (({data}) => {
    setLoading(false);
    let results = data.features;
    setSuggestions(results);
    return data;
  });

  const handleError = (err => {
    return err;
  });

  useEffect(() => {
    getCoords();
  },[]);

  useEffect(() => {
    // IF selection given as prop, set selection state to undefined
    setSelected();
  },[selectedProp]);

  return(
    <AsyncTypeahead
      isLoading={loading}
      onSearch={handleChange}
      searchText="Searching..."
      name={name}
      id="city"
      maxResults={100}
      useCache={false}
      labelKey="label"
      allowNew={false}
      clearButton={true}
      inputProps={inputProps ? inputProps : {}}
      placeholder={placeholder.length > 0 ? placeholder[0].label : null}
      filterBy={(option, props) => {
        return true;
      }}
      options={suggestions.filter(filterSuggestions).map((suggestion, id) =>

       {
         return ({
          ...suggestion,
          id,
          label : suggestion.properties ? suggestion.properties.name : suggestion.label
        });
      })}

      onChange={(c) => {
        setSelected(c);
        if(props.hasOwnProperty('onChange')) props.onChange(c);

      }}
      selected={selected ? selected : (selectedProp ? selectedProp : [])}
      renderMenuItemChildren={(option, typeaheadProps, index) => {
        return props.format ? props.format(option, typeaheadProps, index) : formatDefault(option);
      }}
      defaultInputValue={defaultInputValue}
    />
  )
}

export const apiPhoton = (queryString) => {
  return axios.get(`${OSM_API_ENDPOINT}?${queryString}`);
};

export default AddressTypeahead
