import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from './Table'
import * as Icon from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import currency from 'currency.js'

import { apiContacts } from './services/api/utilities'
import AddContactModal from './Modals/AddContactModal'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import PermissionsGate from './PermissionsGate'
import GlobalFilter from './GlobalFilter'



function ContactOverview () {
  const [contacts, setContacts] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [error, setError] = useState();

  const defaultContact = {
    company_id : null,
    salutation : null,
    first_name : null,
    last_name : null,
    mail : null,
    tel: null
  };

  useEffect(() => {
    apiContacts.clearCache();
  },[contacts.length])

  useEffect(() => {
    let active = true;
    apiContacts.getAll().then(data =>{
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      if(active) {
        setContacts(data);
        setError();
      }
    });

    return () => {
      active = false;
    }
  },[]);

  const handleDelete = (contactId) => {
      apiContacts.remove(contactId).then(data => {
        if(data.hasOwnProperty('error')) {
          setError(data.error);
          return data;
        }
        setError();
        setContacts(contacts.filter((contact, i) => parseInt(contact.id) !== parseInt(contactId)));
      });
  }

  

  return (
    <>
      <h2>Contacts
        {` `}<Button variant="secondary" onClick={() => setShowContactModal(true)}>
          <Icon.PersonRolodex /> Ajouter un contact
        </Button>
      </h2>
      {error ? <Alert>{error}</Alert> : null}
      <AddContactModal
        setUpdate={(contact) => setContacts([...contacts,contact])}
        initialState={defaultContact}
        show={showContactModal}
        setShow={setShowContactModal} />
      <Table
        className="manageTable"
        data={contacts}
        columns={React.useMemo(() => ([{
          Header: 'Nom',
          accessor: 'name',
          sortType : 'basic',
          width : 280,
          Cell: ({cell : { row : { original: {
            salutation, first_name, last_name, id
          }}}}) => (
            <Link to={`/contact/${id}`}>
              {salutation ? `${salutation} ` : null}{first_name} {last_name}
            </Link>
          )
        },{
          Header: 'Mail',
          accessor: 'mail',
          sortType : 'basic',
          width : 280
        },{
          Header: 'Tel',
          accessor: 'tel',
          sortType : 'basic',
          width : 280
        },{
          Header: 'Entreprise',
          accessor: 'company_name',
          sortType : 'basic',
          width : 280
        },{
          Header: 'Edit',
          accessor: 'edit',
          disableSortBy : true,
          width : 120,
          Cell : (fields) => {
            const {cell} = fields,
              {original, values} = cell.row;
            return(
              <>
                <ConfirmDeleteModal
                  message={`Vous souhaitez supprimez le contact ${original.first_name} ${original.last_name}?`}
                  handleDelete={() => handleDelete(original.id)} />
                <Link to={`/contact/${original.id}`}>
                  <Icon.Gear />
                </Link>
              </>
            );
          }
        }]),[])}/>

    </>
  )

}

export default ContactOverview

// AddContactModal({setUpdate, initialState, show, setShow}){
