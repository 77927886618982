import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import { Form } from 'react-bootstrap'
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'
import { apiContacts } from '../services/api/utilities'


function AddContactModal({setUpdate, initialState, show, setShow}){
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    salutation = null,
    first_name = null,
    last_name = null,
    mail = null,
    tel = null,
    company_id = null,
  } = initialState;

  const handleConfirm = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = [
      "salutation",
      "first_name",
      "last_name",
      "mail",
      "tel",
      "company_id",
    ];
    let model = {};
    fields.map(field => {
      model[field] = field.substr(-3) === "_id" ?
      parseInt(elems.namedItem(field)?.value)
      : elems.namedItem(field)?.value
    });

    apiContacts.post({ contacts : [model]}).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }

      setUpdate([...data.map(contact => ({
        ...contact,
        label : `${contact.first_name} ${contact.last_name}`,
        id : contact.id,
      }))]);
      setShow(false);
      setError();
    });

  }


  return(
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            <Form.Group>
              <Form.Control required type="hidden" name="company_id"  defaultValue={company_id} />
            </Form.Group>
            <Row>
              <Col sm="2" xs="4">
                <Form.Group>
                  <Form.Label>Salutation</Form.Label>
                  <Form.Control type="text" name="salutation" defaultValue={salutation} />
                </Form.Group>
              </Col>
              <Col sm="4" xs="8">
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control required type="text" name="first_name" defaultValue={first_name} />
                </Form.Group>
              </Col>
              <Col sm="6" xs="12">
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control required type="text" name="last_name"  defaultValue={last_name} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6" xs="12">
                <Form.Group>
                  <Form.Label>Adresse mail</Form.Label>
                  <Form.Control type="mail" name="mail" defaultValue={mail} />
                </Form.Group>
              </Col>
              <Col sm="6" xs="12">
                <Form.Group>
                  <Form.Label>Numéro telephone</Form.Label>
                  <Form.Control type="text" name="tel" defaultValue={tel} />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddContactModal
