import React, { Suspense, useState, useEffect} from 'react'

import * as Icon from 'react-bootstrap-icons'



function ReactTableBody ({
   rowDeleted = (row) => (false),
   getTableBodyProps = () => {},
   rowRenderStyle = [],
   prepareRow = () => {},
  }) {


    return (
      <div className="tbody" {...getTableBodyProps()}>
        {rowRenderStyle.map((row, i) => {
          prepareRow(row);

          return (
            <div className={`tr ${rowDeleted(row) ? 'delete' : ''}`}
            {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return (
                  <div className={`td`}
                    {...cell.getCellProps()}
                  >
                    {cell.isGrouped ? (
                         // If it's a grouped cell, add an expander and row count
                         <>
                          <span {...row.getToggleRowExpandedProps()}>
                             {row.isExpanded ? <Icon.ArrowDownCircle /> : <Icon.ArrowRightCircle />}
                           </span>{' '}
                           {cell.render('Cell')}
                         </>
                       ) : cell.isAggregated ?
                         // If the cell is aggregated, use the Aggregated
                         // renderer for cell
                       //  <span {...row.getToggleRowExpandedProps()}>{cell.render('Aggregated')}</span>
                         <span>{cell.render('Aggregated')}</span>
                        : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                         // Otherwise, just render the regular cell
                         cell.render('Cell')
                       )}
                     {cell.isPlaceholder && cell.column.hasOwnProperty('Placeholder') ? (
                       <span>{cell.render('Placeholder')}</span>
                     ) : null}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
}



export default ReactTableBody
