import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RangeSlider from 'react-bootstrap-range-slider'

import cloneDeep from 'lodash'

import CurrencyInput, { formatValue } from 'react-currency-input-field'
import currency from 'currency.js'

import { Link, Navigate, useParams } from 'react-router-dom'
import { apiProducts, apiProductCategories } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import TableContext, { Consumer } from './TableContext'
import { useAsyncDebounce } from 'react-table'
import ReactTable from './ReactTable'


const currencyFormat = {
  groupSeparator : ', ',
  decimalSeparator : '.',
  symbol : '€ ',
  fixedDecimalLength : 2,
};

const defaultQuantity = 1;

function BillArticlesTable({
    bill = {},
    // articles = [],
    onUpdate = (articles) => {},
    readOnly = false,

  }) {
  const [articles, setArticles] = useState([...bill.articles.map(article => ({...article}))]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setArticles([...bill.articles.map(article => ({...article}))]);
  },[bill]);

  const handleDelete = ({id}) => {
    let newArticles = articles.filter((article) => article.id !== id);
    setArticles(newArticles);
    onUpdate(newArticles);
  }

  const updateArticle = (updatedArticle) => {
    let newArticles = articles.map((article) => (
      article.id === updatedArticle.id ?
        {...updatedArticle, sum : getRowTotal(updatedArticle)} :
        article
    ));
    setArticles(newArticles);
    onUpdate(newArticles);
  }

  const getRowTotal = ({pa, quantity}) => (pa*quantity);


  const deleteArticle = (article) => {
    setTimeout(() => handleDelete(article), 500);
  }
  const data = [...articles.map(article => ({...article, sum : getRowTotal(article)}))];



  let table = {
    data : data,
    initialState : {},
    columns : React.useMemo(() => (
    [{
      Header: 'Article',
      accessor: 'product_name',
      canGroupBy : false,
      sortType : 'alphanumeric',
      Cell : ({ row, cell}) => (
        <Link to={`/product/${row.original.product_id}#articles`}>
          {cell.value}
        </Link>
      )
    },{
      Header: 'Taille',
      accessor: 'size',
      canGroupBy : false,
      sortType : 'alphanumeric',
      Cell : ({cell}) => (`${cell.value} cm`)
    },{
      Header: 'PV',
      accessor: 'pv',
      canGroupBy : false,
      sortType : 'basic',
      Cell : ({cell}) => (
        <div style={{ textAlign:'right'}}>
          {currency(cell.value, currencyFormat).format()}
        </div>
        ),
      Footer : ({data}) => (
        <div style={{ textAlign:'right'}}>
          {data.reduce(
            (total, { pv, quantity }) =>
              currency(total,currencyFormat).add(pv*quantity).format()
            , 0)}
        </div>
      )
    },{
      Header: 'PA',
      accessor: 'pa',
      canGroupBy : false,
      sortType : 'basic',
      Cell : ({cell}) => (
        <div style={{ textAlign:'right'}}>
          {currency(cell.value, currencyFormat).format()}
        </div>
        ),
      Footer : ({data}) => (
        <div style={{ textAlign:'right'}}>
          {data.reduce(
            (total, { pa, quantity }) =>
              currency(total,currencyFormat).add(pa*quantity).format()
            , 0)}
        </div>
        )
    },{
      Header: 'Quantité',
      accessor: 'quantity',
      canGroupBy : false,
      sortType : 'basic',
      Cell : ({cell,row}) => (
        <Form.Control
          id={`article-${row.values.id}-quantity`}
          disabled={readOnly}
          type="number"
          min={0}
          defaultValue={cell.value}
          onChange={useAsyncDebounce((e) => {
            updateArticle({
              ...row.original,
              quantity : e.target.value,
              attach_images : e.target.value > 0 ? row.values.attach_images : 0
            })
          })}/>
      ),
      Footer : ({data}) => data.reduce((total, { quantity }) => total += parseInt(quantity), 0)
    },{
      Header: 'Somme',
      accessor: 'sum',
      canGroupBy : false,
      sortType : 'basic',
      Cell : ({cell}) => (
        <div style={{ textAlign:'right'}}>
          {currency(cell.value, currencyFormat).format()}
        </div>
        ),
      Footer : ({ data }) => (
        <div style={{ textAlign:'right'}}>
          {currency(data.reduce((total, { sum }) =>
            total += sum, 0), currencyFormat).format()}
        </div>
      )
    },{
      Header: () => (
        <div>
          <p>Attacher Images</p>
          {readOnly ? null : <><Button
            onClick={() => {
              let newArticles = articles.map((article) => ({...article, attach_images : 1 }));
              setArticles(newArticles);
              onUpdate(newArticles);
            }}
            variant="link">
            Tout
          </Button>
          {' / '}
          <Button
            onClick={() => {
              let newArticles = articles.map((article) => ({...article, attach_images : 0 }));
              setArticles(newArticles);
              onUpdate(newArticles);
            }}
            variant="link">
            Aucune
          </Button></>}
        </div>
      ),
      accessor: 'attach_images',
      canGroupBy : false,
      sortType : 'basic',
      Cell : ({cell, row}) => (
        <Form.Check
          type="checkbox"
          id={`attach-images-${row.original.id}`}
          disabled={parseInt(row.values.quantity) < 1 || readOnly}
          onChange={useAsyncDebounce((e) => {

            updateArticle({...row.original, attach_images : e.target.checked ? 0 : 1})
          })}
          checked={cell.value===1 && row.values.quantity>0 ? 'on' : ''} />),
      Footer : ({ data }) => `${data.reduce((total, { attach_images, images }) => total += attach_images ? images.length : 0, 0)} images`
    }]))
  };



  return (
    <TableContext table={table} loading={loading}>
      <Consumer>
        {(reactTable) =>
          <ReactTable
            rowDeleted={(row) => {return parseInt(row.values.quantity)===0;}}
            className="bill-articles-table"
            {...reactTable} />
        }

      </Consumer>
    </TableContext>
  )
}

export default BillArticlesTable
