import React, { useEffect, useState, useRef } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Link, Navigate, useParams } from 'react-router-dom'
import { apiContacts, apiCompanies } from './services/api/utilities'

import {cloneDeep} from 'lodash'



function ContactForm() {
  const id = useParams().contactId;
  const [contact, setContact] = useState(false);
  const [error, setError] = useState();
  const [fieldCompany, setFieldCompany] = useState([]);
  const [companies, setCompanies] = useState([]);


  useEffect(() => {
    let active = true;

    apiContacts.getSingle(id).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data);
        return data;
      }
      setContact(data);
      if(data.company_name)
        setFieldCompany([data.company_name]);
    });

    apiCompanies.getAll().then(data => setCompanies(data));


    return () => {
      active = false;
    }
  },[]);



  const onSubmit = (e) => {
    e.preventDefault();

    let contactUpdate = {};

    let fields = Array.from(e.target.elements).filter(f => f.name);
    let contactFields = ["first_name", "last_name", "salutation", "mail", "tel"]
                        .map(key =>
                          contactUpdate[key] = key.substr(-2) === "id" ?
                            parseInt(fields.filter(input => input.name === key)[0].value) :
                            fields.filter(input => input.name === key)[0].value);

    contactUpdate["company_id"] = fieldCompany[0].id;

    apiContacts.patch(contact.id, contactUpdate);

  }

  if(!contact) return("...");

  let {
      first_name,
      last_name,
      salutation,
      mail,
      tel
  } = contact;


  return (
    <>
    <Form onSubmit={onSubmit}>
      <Row>
        <Col><h2><Link to="/contacts" style={{color:'white'}}>Contact</Link></h2></Col>
      </Row>
      <Row>
        <Col><h3>Details</h3></Col>
      </Row>
      <Row>
        <Col xs="4" sm="3" md="2" lg="1">
          <Form.Control type="text" name="salutation" defaultValue={salutation}/>
          <Form.Label>Salutation</Form.Label>
        </Col>
        <Col xs="8" sm="4" md="5" lg="5">
          <Form.Control type="text" name="first_name" defaultValue={first_name}/>
          <Form.Label>Prénom</Form.Label>
        </Col>
        <Col xs="12" sm="5" md="5" lg="6">
          <Form.Control type="text" name="last_name" defaultValue={last_name}/>
          <Form.Label>Nom</Form.Label>
        </Col>

      </Row>

      <Row>
        <Col xs="12" md="6" lg="6">
          <Form.Control type="text" name="mail" defaultValue={mail} />
          <Form.Label>E-Mail</Form.Label>
        </Col>
        <Col xs="12" md="6" lg="6">
          <Form.Control type="text" name="tel" defaultValue={tel} />
          <Form.Label>Tel</Form.Label>
        </Col>
      </Row>
      <Row>
        <Typeahead
          id="company"
          selected={fieldCompany}
          clearButton={true}
          onChange={(selection) => { setFieldCompany(selection)}}
          options={companies.map((company, i) => ({id : company.id, label: company.name }))}
           />
        <Form.Label>Entreprise</Form.Label>
      </Row>
      <Button type="submit">Sauvegarder des changements</Button>
    </Form>
    </>
  )
}

export default ContactForm


// <Col xs="12" md="4" lg="3">
//   <Form.Control type="hidden" value={fieldCompany.length ? parseInt(fieldCompany[0].id) : undefined} name="company_id" />
//   <Typeahead
//     id="company"
//     selected={fieldCompany}
//     onChange={(selection) => { setFieldCompany(selection)}}
//     options={["AUCHAN","BRICOMARCHE","N'IMPORTE"].map((label, id) => ({id, label }))}
//      />
//   <Form.Label>Entreprise</Form.Label>
// </Col>
