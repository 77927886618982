import React, { useState, useEffect } from 'react';
import useToken from './hooks/useToken';
import Table from './Table';
import * as Icon from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';

import PermissionsGate from './PermissionsGate';
import { apiUsers } from './services/api/utilities';
import AddUserFormModal from './Modals/AddUserFormModal';
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal';


function UsersTable() {
  const {token} = useToken();
  const [update, setUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    let active = true;
    const parseUsers = (usersData) => {
      let parsedUsers = [];
      usersData.forEach((u, i) => {
        parsedUsers.push({
          ...u,
          name : <Link to={`/users/${u.id}`}>{u.first_name} {u.last_name}</Link>,
          roles : <>
                    {u.roles.map((r,i) => (
                      <Link key={`role-link-${r.id}`} to={`/roles/${r.id}`} style={{margin : '0 0.3em 0 0'}}>
                        <Badge size="sm">{r.name}</Badge>
                      </Link>
                    ))}
                  </>,//rolesString,
          edit:
                <>
                  <Link to={`/users/${u.id}`}>
                    <Icon.Gear />
                  </Link>
                  <PermissionsGate permissions={['rm_users']}>
                    <ConfirmDeleteModal
                     handleDelete={() => handleDelete(u.id)}
                     title="Supprimer utilisateur"
                     message={`Vous souhaitez supprimer l'utilisateur ${u.first_name} ${u.last_name}?`} />
                  </PermissionsGate>
                </>
        });
      });
      setUsers(parsedUsers);
    };

    const handleDelete = (id) => {
      apiUsers.remove(id).then(data => {
        if(data.hasOwnProperty('error')) {
          setError(data.error);
          return data;
        }
        setUpdate(true);
      });
    }
    apiUsers.getAll().then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);

      }
      if(active) {
        parseUsers(data);
        setUpdate(false);
      }
      return data;
    });

    return () => {
      active = false;
    };
  },[token, update]);



  return(
    <>
      <Table
        className="manageTable"
        data={users}
        columns={[{
          Header: 'Nom',
          accessor: 'name',
          sortType : 'basic',
          width : 160,
        },{
          Header: 'E-Mail',
          accessor: 'mail',
          sortType : 'basic',
          width: 300,

        },{
          Header: 'Roles',
          accessor: 'roles',
          disableSortBy : true,
          width: 300
        },{
          Header: 'Edit',
          accessor: 'edit',
          disableSortBy : true,
          width : 120,
        }]}/>
        <PermissionsGate permissions={['add_user']}>
          <AddUserFormModal setUpdate={setUpdate} />
        </PermissionsGate>
      </>
  );
}


export default UsersTable;
