import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PermissionFormModal from './Modals/PermissionFormModal'
import * as Icon from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import ListGroup  from 'react-bootstrap/ListGroup'
import PermissionsGate from './PermissionsGate'

import {apiRoles, apiTables} from './services/api/utilities'


function PermissionsForm({role_id, token}) {
  const dispatch = useDispatch();
  const [permissions,setPermissions] = useState([]);
  const [update,setUpdate] = useState(false);
  const [error,setError] = useState(false);
  const [unusedPermissions,setUnusedPermissions] = useState({permissions : [], tables : []});


  useEffect(() => {
    if(!role_id)
      return;
    let active = true;
    apiRoles.getPermissions({id : role_id}).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      if(active)
        setPermissions(parsePermissions(data));
    });

    apiRoles.getUnusedPermissions({id : role_id}).then(data => {
      if(data.hasOwnProperty('error')) {
        if(active)
          setError(data.error);
        return data;
      }
      if(active)
        setUnusedPermissions(data);
    });

    setUpdate(false);

    return () => {
      active = false;
    };
  }, [role_id,update,token]);

  useEffect(() => {
    updateDashboard();
  },[update]);

  const parsePermissions = (permissions) => {
    permissions.filter(p => p.description.includes('$')).forEach((p, i) => {
      switch (p.accessor) {
        case "get_table":
          p.description = p.description.replace('$',p.table_name);
          break;
        case "get_rows":
          p.description = p.description.replace('$',`${p.column_value} (${p.column_name})`).replace('$',`"${p.table_name}"`);
          break;
        default:
        //  break;
      }
    });
    return permissions;
  }

  const updateDashboard = () => {
    apiTables.getAll().then(data =>
      dispatch({ type : 'SET_TABLES', payload: data}));
  }

  const handleDelete = (permission_role_rel_id) => {
    setPermissions(parsePermissions(permissions.filter(p => p.rel_id !== permission_role_rel_id)));
    apiRoles.removePermission({ id: role_id, rel_id : permission_role_rel_id }).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setError();
      setUpdate(true);

    });
  }
  if(!permissions || !unusedPermissions)
    return ("Loading");
  return(
    <PermissionsGate permissions={['edit_permissions']}>
      <h3>Permissions</h3>
      {error ? <Alert>{error}</Alert> : null}
      <ListGroup>
        {permissions.sort((a,b) => a.rel_id - b.rel_id).map(p => {
          return (
            <ListGroup.Item key={p.rel_id}>
              {`${p.description}`} <Button onClick={() => {handleDelete(p.rel_id)}} size="sm" style={{float: "right", padding : '0em 0.2em'}}><Icon.X /></Button>
            </ListGroup.Item>
          )
        })}
      </ListGroup>

      {unusedPermissions.permissions.length > 0 ?
      <>
        <h4>Ajouter des permissions</h4>
        <ul className="addPermissions">
          {unusedPermissions.permissions.map(p => (
            <li key={`new-${p.id}`}>
              <PermissionFormModal
                permission={p}
                setUpdate={setUpdate}
                role_id={role_id}
                permission_id={p.id}
                tables={unusedPermissions.tables}/>
            </li>
          ))}
        </ul>
      </> : null}




    </PermissionsGate>
  );

}


export default PermissionsForm;
