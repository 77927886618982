import React, { Suspense } from 'react'
import PermissionsGate from './PermissionsGate'

const TableViewer = React.lazy(() => import('./TableViewer'));


function Dashboard() {

  return (

    <div className="dashboard">
      <h2>Tableau de bord</h2>

      <PermissionsGate any={true} permissions={['get_table','get_rows']}>
        <TableViewer />
      </PermissionsGate>


    </div>
  );
}

export default Dashboard;
