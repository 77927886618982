import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import { Form } from 'react-bootstrap'
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'
import { apiClients } from '../services/api/utilities'
import useToken from '../hooks/useToken'
import AddressForm  from '../AddressForm'




function AddAddressModal({setUpdate, clientId}){
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [addressReady, setAddressReady] = useState(false);
  const addressFormName = `addr-new`;
  const [formValues, setFormValues] = useState({});
  const myCountries = useSelector(state => state.interface.geography.countries);


  const handleConfirm = (e) => {

    e.preventDefault();
    let elems = e.target.elements;
    let fields = ['address1','address2','locality','postal_code','country_code'];
    let values = {};
    fields.map(field => {
      values[field] = elems.namedItem(`${addressFormName}-${field}`).value;
    });

    let cc2 = values.country_code;
    let country = myCountries.find(country => country.Code2===cc2);
    values['country_code'] = country.Code;


    apiClients.addAddress({...values, id : clientId}).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setUpdate({...data, country: country.Name});
      setError();
      handleClose();
    });

  }

  const onAddressUpdate = (props) => {
    
    let allReady = Object.keys(props).length;
    let ready = 0;
    Object.keys(props).map((addressPart) => {
      if(props[addressPart].length) ready++;
    });
    if(ready === allReady) setAddressReady(true);
    else setAddressReady(false);
  }


  return(
    <>
      <Button size="sm" variant="secondary" onClick={handleShow}>
        Ajouter une addresse <Badge><Icon.Plus /></Badge>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Saisissez une addresse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form name={`${addressFormName}-form`} onSubmit={handleConfirm}>
            <AddressForm
              onUpdate={onAddressUpdate}
              name={addressFormName}
              useMap={true} />
            <Button type="submit" disabled={addressReady ? false : true}>Ajouter l'addresse</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddAddressModal
