import React, { Suspense, useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as Icon from 'react-bootstrap-icons'
import Pagination from 'react-bootstrap/Pagination'


function FlexTablePagination ({
   initialState = {},
   pageCount,
   pageSize,
   setPageSize,
   gotoPage,
   nextPage,
   previousPage,
   canPreviousPage,
   canNextPage,
   pageOptions,
   state
  }) {
    const {pageIndex} = state;

    return (
      <>
       <Pagination size="lg">
         <Pagination.First onClick={() => gotoPage(0)}  disabled={!canPreviousPage} />
         <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />

         {pageCount>=6 && pageIndex>=3 ? (<Pagination.Ellipsis onClick={() => gotoPage(pageIndex-3)}></Pagination.Ellipsis>) : null}
         {pageCount>=5 && pageIndex+2>=pageCount ? (<Pagination.Item onClick={() => gotoPage(pageIndex-4)}>{pageIndex-3}</Pagination.Item>) : null}
         {pageCount>=4 && pageIndex+1>=pageCount ? (<Pagination.Item onClick={() => gotoPage(pageIndex-3)}>{pageIndex-2}</Pagination.Item>) : null}
         {pageIndex>1 ? (<Pagination.Item onClick={() => gotoPage(pageIndex-2)}>{pageIndex-1}</Pagination.Item>) : null}
         {pageIndex>0 ? (<Pagination.Item onClick={() => gotoPage(pageIndex-1)}>{pageIndex}</Pagination.Item>) : null}

         <Pagination.Item active>{pageIndex + 1}</Pagination.Item>

         {pageIndex+1<pageCount ? (<Pagination.Item onClick={() => gotoPage(pageIndex+1)}>{pageIndex+2}</Pagination.Item>) : null}
         {pageIndex+2<pageCount ? (<Pagination.Item onClick={() => gotoPage(pageIndex+2)}>{pageIndex+3}</Pagination.Item>) : null}
         {pageCount>=4 && pageIndex<=1 ? (<Pagination.Item onClick={() => gotoPage(pageIndex+3)}>{pageIndex+4}</Pagination.Item>) : null}
         {pageCount>=5 && pageIndex<=0 ? (<Pagination.Item onClick={() => gotoPage(pageIndex+4)}>{pageIndex+5}</Pagination.Item>) : null}
         {pageCount>=6 && pageIndex<pageCount-3 ? (<Pagination.Ellipsis onClick={() => gotoPage(pageIndex+3)}></Pagination.Ellipsis>) : null}

         <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage}/>
         <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
       </Pagination>

       <Row className="pagination">
        <Col xs="12">
           <Form.Label>
             Page{' '}
             <strong>{pageIndex + 1}</strong>
              {' '}sur{' '}
              <strong>{pageOptions.length}</strong>
              | Voir page:{' '}

           </Form.Label>
           <Form.Control
             type="number"
             value={pageIndex + 1}
             onChange={e => {
               const page = e.target.value ? Number(e.target.value) - 1 : 0
               gotoPage(page)
             }}
             style={{ width: '100px' }} />
         </Col>
         <Col xs="3">
           <Form.Group>

           </Form.Group>
          </Col>
          <Col
            xs={{ span : 6, offset: 3}}
            sm={{ span : 4, offset: 4}}
            lg={{ span : 2, offset: 5}} >
           <Form.Select
              defaultValue={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}>
             {[10, 20, 30, 40, 50].map(pageSize => (
               <option key={`pagesize-${pageSize}`} value={pageSize}>
                 Afficher {pageSize}
               </option>
             ))}
           </Form.Select>
          </Col>
       </Row>
      </>
    )
}



export default FlexTablePagination
