import React, { cloneElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'


const hasPermission = (permissions, userPermissions, options = []) => {
  // let oChecks = optionsChecks(permissions,userPermissions,options);
  // if(oChecks.some(c => c === false))
  //   return false;
  if(userPermissions.length < 1 && permissions.length > 1)
    return false;
  let checks = permissions.map((a, i) => {
    if(!userPermissions.some(p => p.accessor === a))
      return false;
    return true;
  });
  if(checks.some(c => c === false))
    return false;
  return true;
}

const anyPermission = (permissions, userPermissions, options = []) => {
  
  // let oChecks = optionsChecks(permissions,userPermissions,options);
  if(userPermissions.length < 1 && permissions.length > 1)
    return false;
  let checks = permissions.map((a, i) => {
    if(!userPermissions.some(p => p.accessor === a))
      return false;
    return true;
  });
  if(!checks.some((c,i) => c === true))
    return false;
  return true;
}



const optionsChecks = (permissions, userPermissions, options) => {
  if (options.length === 0)
    return new Array(permissions.length).fill(true);
  if (options.length !== permissions.length) {
    console.warn('There must be as many options as permissions provided');
  }
  let checks = options.map((o,i) => {
    let propChecks = Object.keys(o).map((prop, j) => {
      let val = options[i][prop];
      let accessor = permissions[i];
      if(!userPermissions.some(p => p[prop] === val && p.accessor === accessor))
        return false;
      return true;
    });
    if(propChecks.some(c => c === false))
      return false;
    return true;
  });
  return checks;
}


function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  permissions = [],
  any = false,
  // options = []
}) {
  const userPermissions = useSelector(state => state.user.permissions);

  const permissionGranted = any ?
    anyPermission(permissions, userPermissions)
    : hasPermission(permissions, userPermissions);

  if(!permissionGranted && !errorProps) return <RenderError />

  if(!permissionGranted && errorProps)
    return cloneElement(children, {...errorProps});

  return <>{children}</>;



};

export default PermissionsGate;
export {hasPermission, anyPermission};
