import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import cloneDeep from 'lodash'

import CurrencyInput, { formatValue } from 'react-currency-input-field'
import currency from 'currency.js'

import { Link, Navigate, useParams } from 'react-router-dom'
import { apiProducts, apiProductCategories } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import TableContext, { Consumer } from './TableContext'
import { useAsyncDebounce } from 'react-table'
import ReactTable from './ReactTable'


const currencyFormat = {
  groupSeparator : ', ',
  decimalSeparator : '.',
  symbol : '€ ',
  fixedDecimalLength : 2,
};


function ArticlesGallery({
    articles = [],
    loading = false,
  }) {

  return (
    <Carousel className="articles-gallery">
      {articles.map((article, index) => (
        article.images.map((image, i) => (
            <Carousel.Item style={{backgroundImage: `url(${image.image_path})`}}>
                <Carousel.Caption>
                  <strong>{article.product_name}</strong> ({article.size} cm)
                  {image.alt === null ? null :
                    <div className="article-image-description">
                      {image.alt}
                    </div>
                  }
                </Carousel.Caption>
            </Carousel.Item>
          ))
        ))
      }
  </Carousel>
  )
}

export default ArticlesGallery
