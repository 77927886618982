import React, {useState, useRef, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import { Typeahead } from 'react-bootstrap-typeahead'
import ArticleEdit from '../ArticleEdit'
import { Form } from 'react-bootstrap'
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'
import { apiArticles, apiProducts } from '../services/api/utilities'

import cloneDeep from 'lodash'


function AddArticleModal({setUpdate = () => (null), initialState = {}, show, setShow}){
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [products, setProducts] = useState([]);
  const [fieldProduct, setFieldProduct] = useState([]);

  useEffect(() => {
    let active = true;

    apiProducts.getAll().then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      if(active) {
          setProducts(data);
          setError();
      }
    });

    return () => {
      active = false;
    }
  },[]);



  const article = useRef(initialState);
  useEffect(() => {
    if(initialState.product_id !== undefined) {
      let {id, name} = products.find(p => p.id==initialState.product_id);
      setFieldProduct([{ id, label: name }]);
    }
    updateArticle(initialState);
  }, [initialState]);


  const {
    pa = null,
    pv = null,
    size = null,
    product_id = null,
  } = initialState;

  const updateArticle = (newArticle) => {
    article.current = { ...article.current, ...newArticle };
  }


  const handleConfirm = (e) => {
    e.preventDefault();
    let newArticle = {...article.current, type : 'plante'};

    ['product_id'].map((field) => newArticle[field] = e.target[field].value);
    apiArticles.post({ articles : [newArticle] }).then(data => {
      if(data.hasOwnProperty('error') || !data.length) {
        setError(data.error);
      } else {
        setUpdate(data[0]);
        setShow(false);
      }
    });
  }

  return(
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selectionnez le produit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            <Form.Group>
              <Form.Control
                required
                type="hidden"
                name="product_id"
                defaultValue={fieldProduct.length ? fieldProduct[0].id : null} />
            </Form.Group>
            <Row>
              <Col xs="12">
                <Form.Group>
                  <Form.Label>Produit</Form.Label>
                  <Typeahead
                    id="product"
                    selected={fieldProduct}
                    onChange={(selection) => {setFieldProduct(selection)}}
                    options={[...products].map((product, i) => ({ id: product.id, label: product.name }))}
                    name="product" />
                </Form.Group>
              </Col>
            </Row>
            <ArticleEdit
              onUpdate={(newArticle) => {updateArticle(newArticle)}}
              article={article.current}
              layout="mini" />
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddArticleModal
