import React from 'react';
import { Link, Outlet } from 'react-router-dom';

function Roles() {
  return (
    <div className="form-container">
      <Link to="/roles" style={{color : 'white'}}><h2>Roles</h2></Link>
      <Outlet />
    </div>
  )
}

export default Roles;
