import React, { Suspense, useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as Icon from 'react-bootstrap-icons'



function ReactTableHeader ({
   headerGroups = [],
   initialState = {},
  }) {


    return (
      <div className="thead">
        {headerGroups.map(headerGroup => (
          <div className="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column,i) => (
              <div className={`th ${column.headerClassName} ${i === 0 ? 'col-sticky' : ''}`}
                {...column.getHeaderProps()}
              >
                <span {...column.getSortByToggleProps()}>
                 {column.render('Header')}
                </span>{' '}
                {column.canGroupBy ? (
                       // If the column can be grouped, let's add a toggle
                       <span {...column.getGroupByToggleProps()}>
                         {column.isGrouped ?  <Icon.BoxArrowDownRight />  : <Icon.BoxArrowInUpLeft />}
                       </span>
                     ) : null}
                <span>
                   {column.isSorted
                     ? column.isSortedDesc
                       ? <Icon.ChevronUp />
                       : <Icon.ChevronDown />
                     : ''}
                 </span>


              </div>
            ))}
          </div>
        ))}
        {headerGroups.map(headerGroup => (
          <div className="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column,i) => (
              <div className={`th`} {...column.getHeaderProps()}>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
}



export default ReactTableHeader
