import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { sidebarToggle } from './actions';
import * as Icon from 'react-bootstrap-icons';


const Toggle = ({onClick, isCollapsed}) => {
  return(
    <a className="sidebar-toggle" onClick={onClick}>
      {isCollapsed ? <Icon.List /> : <Icon.ArrowLeft />}
    </a>);
}

const Group = ({label, children}) => {
  return (
    <>
      <h3 className="sidebar-group-header">{label}</h3>
      <ul className="sidebar-group">
        {children}
      </ul>

    </>
  );
}

const Item = ({children,icon}) => {
  return(
    <li onClick={() => {}} className="sidebar-item">
      <span className="sidebar-item-icon">{icon}</span>
      {children}
    </li>
  )
}

function Sidebar ({
  container,
  children,
  collapsed,
  onExpand,
  onCollapse
}) {
  // const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const isCollapsed = useSelector(state => state.interface.sidebarCollapsed);
  const dispatch = useDispatch();

  // const expand = () => {
  //   onExpand();
  //   setIsCollapsed(false);
  // }
  // const collapse = () => {
  //   onCollapse();
  //   setIsCollapsed(true);
  // }
  const handleToggle = () => {
    if(isCollapsed) onExpand();
    else onCollapse();
    dispatch(sidebarToggle());
  }

  useEffect(() => {
    if(isCollapsed) onCollapse();
    else onExpand();

    return () => {

    }
  },[]);


  return (
    <aside className={`sidebar ${(isCollapsed ? 'sidebar-collapsed' : '')}`}>
      <Toggle isCollapsed={isCollapsed} onClick={handleToggle} />
      {children}
    </aside>
  );

}

Sidebar.Toggle = Toggle;
Sidebar.Group = Group;
Sidebar.Item = Item;
export default Sidebar;
