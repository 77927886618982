import React, {useState, useEffect} from 'react';
import { Form } from 'react-bootstrap';
import Button  from 'react-bootstrap/Button';
import Alert  from 'react-bootstrap/Alert';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import PermissionsForm from './PermissionsForm';
import { apiRoles } from './services/api/utilities';

import useToken from './hooks/useToken';

function RoleDetail() {
  let params = useParams();
  let id = params.roleId;
  const { token } = useToken();
  const [change, setChange] = useState(false);
  const [error, setError] = useState(false);
  const [role,setRole] = useState({});

  useEffect(() => {
    let active = true;
    apiRoles.getSingle(id).then((data) => {
      if(data.hasOwnProperty('error') && active) {
        setError(data.error);
        return data;
      }
      if(active) {
        setRole(data);
      }
    });

    return () => {
      active = false;
    };
  },[id,token]);

  let {name} = role;

  const handleUpdate = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = {};

    for(let i=0;i<(elems.length-1);i++) {
      fields[elems.item(i).name] = elems.item(i).value;
    }

    apiRoles.patch(id, fields).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setChange(false);
    });
  }

  if(name === undefined)
    return <>...</>;

  return (
    <>
      {error ? <Alert>{error}</Alert> : null}
      <Form size="md" onSubmit={handleUpdate}>
        <Row>
          <Col sm="9" xs="12">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={() => {setChange(true)}}
                name="name"
                defaultValue={role.name} />
            </Form.Group>
          </Col>
        </Row>
        { change ? <Button type="submit">Sauvegarder les changements</Button> : null}
      </Form>
      <br />
      <PermissionsForm role_id={role.id}  token={token} />
    </>
  )
}

export default RoleDetail;
