import React, { Suspense, useEffect, useState, useRef, useMemo,useCallback, createContext, useContext}  from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'



import moment from 'moment'
import {cloneDeep} from 'lodash';
import { useTable, useSortBy, useFilters, useGlobalFilter, useFlexLayout, useGroupBy, useExpanded, usePagination, useRowSelect  } from 'react-table'


import { sortFloatNegative, handleScroll, defaultFilterTypes, defaultColumnProps, IndeterminateCheckbox } from './services/react-table/defaults'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


import { apiTables } from './services/api/utilities'

const { Provider, Consumer } = createContext();



function TableContext ({table, loading, children}) {
  const dispatch = useDispatch();

  const {
    columns,
    data,
    defaultColumn,
    filterTypes,
    initialState,
  } = table;


  const reactTable = useTable({
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState,
    },
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useRowSelect,


  );


  if(!reactTable) return (<></>)

  return (
    <Provider value={{...reactTable, name : table.name, accessor : table.accessor}}>
      {children}
    </Provider>
  )
}



export default TableContext
export {Provider, Consumer}
