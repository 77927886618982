import React, { useEffect, useState, useRef } from 'react';
import FlexTable from './Table';
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import { FileUploader } from 'react-drag-drop-files';
import Reorder from 'react-reorder';
import { Link, useParams } from 'react-router-dom';
import { apiProducts } from './services/api/utilities';
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal';
import PermissionsGate from './PermissionsGate';

import useToken from './hooks/useToken';


function ImageDropper ({ handleChange, moveImage, deleteImage, editImageAlt, images, types }) {
  const inputRef = useRef();
  const containerRef = useRef();
  const [reorderDisabled, setReorderDisabled] = useState(false);


  const handleReorder = (e, previousIndex, nextIndex, fromId, toId) => {
      moveImage(previousIndex,nextIndex);
  }

  const handleTextEdit = (e,image) => {
    editImageAlt(image.idx,e.target.value);
    e.target.value = '';
    setReorderDisabled(false);
  }

  const handleTextEnter = (e) => {
    if(e.key === 'Enter') {
      e.target.blur();
    }
  }


// onChange={handleTextEdit}
  // color="rgba(0,0,0,0.5)"
  // <Form.Control as="div"  name={`image-alt-${image.idx}`} placeholder={`caption`} />
  const dropperThumbnail = (image) => (

      <div
        key={`image-dropper-thumbnail-${image.idx}`}
        className={`image-dropper-thumbnail ${image.deleted ? 'image-dropper-deleted' : ''}`}
        style={{
          backgroundImage : `url(${image.thumbnail_path})`
        }}>
        <div
          onMouseEnter={() => setReorderDisabled(true)}
          onMouseLeave={() => setReorderDisabled(false)}
          className="image-dropper-thumbnail-controls">
          <Icon.Trash onClick={() => deleteImage(image.idx)} />
        </div>
        <div className="image-dropper-thumbnail-alt">
          <Form.Control name={`image-alt-${image.idx}`}
            onClick={(e) => setReorderDisabled(true)}
            onFocus={(e) => setReorderDisabled(true)}
            onBlur={(e) => handleTextEdit(e,image)}
            onKeyDown={(e) => handleTextEnter(e,image)}
            placeholder={`${image.alt === null ? "Description" : image.alt}`} />
        </div>
      </div>
  );

  const dropperTarget = () => (
    <div className="image-dropper-target">
      <Icon.FileImage size="lg" />
    </div>
  );
  
  return (
    <div className="image-dropper" ref={containerRef}>
      <Reorder
        holdTime={50}
        reorderId="image-dropper"
        itemKey="idx"
        template={dropperThumbnail}
        onReorder={handleReorder}
        disabled={reorderDisabled}
        >
        {images.filter(img => !img.added).map(image => dropperThumbnail(image))}

      </Reorder>

      <FileUploader
        handleChange={handleChange}
        name="file"
        types={types}
        children={dropperTarget()}
        multiple={true}
        />
        {images.filter(img => img.added).map(image => (
          <div className="image-dropper-added"></div>))}

    </div>
  )

};

export default ImageDropper;
