import React, { Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Link, useNavigate } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons'

import { Online, Offline } from 'react-detect-offline';

import PermissionsGate, {hasPermission, anyPermission} from './PermissionsGate'
import Sidebar from './Sidebar';

function AppSidebar ({handleLogout, setSidebarExpanded}) {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const logoSrc = process.env.REACT_APP_CLIENT_LOGO;
  const logoAlt = process.env.REACT_APP_CLIENT_NAME;

  const linkProps = (link) => ({
    onClick : () => dispatch({type : 'UPDATE_PATH', payload : link}),
    to : link
  });


  const sitemap = [
    {
      label : "",
      permissions : [],
      items : [
        {
          body :  <Link {...linkProps('/')}>Tableau de bord</Link>,
          icon : <Icon.Table />,
          permissions : ['get_table','get_rows'],
          permissionsAny : true
        }
      ]
    },{
      label : "Admin",
      permissions : [],
      items : [
        {
          body :  <Link {...linkProps('/roles')}>Roles</Link>,
          icon : <Icon.PersonBadge />,
          permissions : ['edit_permissions']
        },
        {
          body :  <Link {...linkProps('/users')}>Users</Link>,
          icon : <Icon.PeopleFill />,
          permissions : ['edit_permissions']
        },
        {
          body :  <Link {...linkProps(`/users/${user.id}`)}>Preferences</Link>,
          icon : <Icon.GearFill />,
          permissions : []
        }
      ]
    },{
        label : "Comptabilité",
        permissions : ['comptabilite'],
        items : [{
          body :  <Link {...linkProps('/products')}>Produits</Link>,
          icon : <Icon.Flower1 />,
          permissions : []
        },
        {
          body :  <Link {...linkProps(`/articles`)}>Articles</Link>,
          icon : <Icon.Boxes />,
          permissions : []
        },
        {
          body :  <Link {...linkProps(`/clients`)}>Clients</Link>,
          icon : <Icon.Shop />,
          permissions : []
        },
        {
          body :  <Link {...linkProps(`/contacts`)}>Contacts</Link>,
          icon : <Icon.PersonRolodex />,
          permissions : []
        },
        {
          body :  <Link {...linkProps(`/articles`)}>Articles</Link>,
          icon : <Icon.Box />,
          permissions : []
        },
        {
          body :  <Link {...linkProps(`/bills`)}>Factures</Link>,
          icon : <Icon.Receipt />,
          permissions : []
        }]
    },{
      label : "",
      permissions : [],
      items : [
        {
          body :  <Link to="/" onClick={handleLogout}>Log out</Link>,
          icon : <Icon.DoorOpen />,
          permissions : []
        }
      ]
    }
  ];




  return (
    <Sidebar onExpand={() => setSidebarExpanded(true)} onCollapse={() => setSidebarExpanded(false)}>

        {/* Sidebar Logo*/}
        <img className="sidebarRPALogo" src={logoSrc} alt={logoAlt} />

        {/* Map Sitemap*/}
        {sitemap.map((group, groupIndex) => (
          <PermissionsGate key={`sidebar-group-${groupIndex}`} permissions={group.permissions}>
            <Sidebar.Group label={group.label}>
              {group.items.map((item,itemIndex) => (
                <PermissionsGate
                  key={`sidebar-item-${groupIndex}-${itemIndex}`}
                  permissions={item.permissions}
                  any={item.permissionsAny ? true : false}>
                  <Sidebar.Item icon={item.icon}>
                    {item.body}
                  </Sidebar.Item>
                </PermissionsGate>
              ))}
            </Sidebar.Group>
          </PermissionsGate>
        ))}

        {/* Show on Connection Error*/}
        <Offline>
          <Sidebar.Item key={`sidebar-item-wifi-off`} icon={<Icon.WifiOff />} />
        </Offline>
    </Sidebar>
  )
}


export default AppSidebar
