import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from './Table'
import * as Icon from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import currency from 'currency.js'

import { apiClients } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import PermissionsGate from './PermissionsGate'

import TableContext, {Consumer} from './TableContext'
import ReactTable from './ReactTable'
import GlobalFilter from './GlobalFilter'


function ClientsOverview () {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  // const clientFilters = useSelector(state => state.clients.filters);
  const dispatch=useDispatch();

  const initialState = useSelector(state => state.clients.tableState);

  let table = {
    data : [...clients],
    initialState,
    columns : React.useMemo(() => (
    [{
      Header: 'Magazin',
      accessor: 'name',
      canGroupBy : false,
      sortType : 'basic',
      width : 280,
      Cell: ({cell}) => {
        return <Link to={`/client/${cell.row.original.id}`}>{cell.value}</Link>
      },
      Aggregated : ({cell}) => {
        return cell.value
      }
    },{
      Header: 'Client',
      accessor: 'company_name',
      sortType : 'alphanumeric',
      width: 220,
      Cell : ({cell}) => {
        return cell.value
      },
      // Placeholder : ({cell, row}) => {
      //   return <Link to={`/client/${row.original.id}`}>{row.original.name}</Link>
      // }
    },{
      Header: 'RIB',
      accessor: 'rib',
      canGroupBy : false,
      sortType : 'basic',
      width: 120,
    },{
      Header: 'RCS',
      accessor: 'rcs',
      canGroupBy : false,
      sortType : 'basic',
      width: 120
    },{
      Header: 'Contact',
      accessor: 'contact_name',
      canGroupBy : false,
      sortType : 'alphanumeric',
      width: 120
    },{
      Header: 'Ville',
      accessor: 'locality',
      sortType : 'alphanumeric',
      width: 120
    },{
      Header: 'Edit',
      accessor: 'edit',
      disableSortBy : true,
      width : 120,
      Aggregated : ({cell}) => {
        return null
      },
      Cell : (fields) => {

        const {cell, row} = fields,
          {values} = row;
          let original = values;

        return(
          <>
            <ConfirmDeleteModal
              message={`Vous souhaitez supprimez le client ${original.name}`}
              handleDelete={() => {
                apiClients.remove(original.id + 100).then(data => {
                  if(data.hasOwnProperty('error')) {
                    setError(data.error);
                    return data;
                  }
                  setClients(clients.filter(client => client.id !== original.id));
                });
            }} />
            <Link to={`/client/${original.id}`}>
              <Icon.Gear />
            </Link>
          </>
        );
      }
    }]),[])
  };

  useEffect(() => {
    let active = true;
    apiClients.getAll().then(data =>{
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        setLoading(false);
      }
      if(active) {
         setClients(data);
         setLoading(false);
       }
    });

    return () => {
      active = false;
    }
  },[clients]);

  useEffect(() => {
    table = {
      ...table,
      data : [...clients]
    }
  },[clients.length]);




  return (
    <>
      <h2>Clients</h2>
      {error ? <Alert>{error}</Alert> : null}
      <TableContext table={table} loading={loading}>
        <Consumer>
          {(reactTable) =>
            <GlobalFilter
              globalFilter={reactTable.state.globalFilter}
              onUpdate={(state) => {dispatch({ type: 'UPDATE_CLIENT_TABLE_STATE', payload: state})}}
              className="manageTable"
              {...reactTable} />
          }

        </Consumer>
        <Consumer>
          {(reactTable) =>
            <ReactTable
              className="manageTable"
              {...reactTable} />
          }

        </Consumer>
      </TableContext>


    </>
  )

}

export default ClientsOverview;
