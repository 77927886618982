export const setUser = (userData) => {
  return {
    type : 'SET_USER',
    payload : userData
  }
}

export const updateUser = (userData) => {
  return {
    type: 'UPDATE_USER',
    payload : userData
  }
}

export const setUserPermissions = (userPermissions) => {
  return {
    type : 'SET_USER_PERMISSIONS',
    payload : userPermissions
  }
}

export const setUserCoordinates = (coords) => {
  return {
    type : 'SET_USER_COORDINATES',
    payload: coords
  }
}
