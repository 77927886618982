import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';
import { apiRoles, apiUsers } from '../services/api/utilities';
import useToken from '../hooks/useToken';


function AssignRoleModal({setUpdate, role, rolesData, parseRoles}){
  const { token } = useToken();
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    apiUsers.getAll().then(data => {
       setUsers(data);
       setShow(true);
     });

  };
  const [users,setUsers] = useState([]);

  useEffect(() => {
    let active = true;


    return () => {
      active = false;
    }
  },[]);

  const handleConfirm = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = { id : role.id, assign: [], remove: [] };

    for(let i=0;i<(elems.length-1);i++) {
      // Assign checked fields to user_id, others to not_user_id
      // if any already assigned, add next with a comma
      if(elems.item(i).checked)
        fields['assign'].push(elems.item(i).id);
      else
        fields['remove'].push(elems.item(i).id);

    }

    // PRERENDER
    let roleRef = rolesData[rolesData.indexOf(role)];
    
    roleRef.users = users.filter(u => fields['assign'].includes(`${u.id}`)).sort((a,b) => a.id - b.id);
    parseRoles(rolesData);

    handleClose();
    apiRoles.assignUsers(fields).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setUpdate(true);
      setError();

    });

  }

  return(
    <>
      <Button size="sm" variant="link" onClick={handleShow}>
        <Icon.PersonPlus />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{role.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            {users.map((u,i) => (
              <Form.Check
                defaultChecked={role.users.find(ru => ru.id === u.id) ? true : false}
                type="checkbox"
                key={`user-role-${i}`}
                id={u.id}
                label={`${u.first_name} ${u.last_name}`}>
              </Form.Check>
            ))}
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AssignRoleModal;
