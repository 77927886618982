import React, {useState, useRef, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import { Typeahead } from 'react-bootstrap-typeahead'
import ArticleEdit from '../ArticleEdit'
import { Form } from 'react-bootstrap'
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'
import { apiBills, apiClients } from '../services/api/utilities'


function AddBillModal({setUpdate, initialState = {}, show, setShow}){
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const author_id = useSelector(state => state.user.id);
  const [clients, setClients] = useState([]);
  const [fieldClient, setFieldClient] = useState([]);

  useEffect(() => {
    let active = true;

    apiClients.getAll().then(data => {
      setClients(data);
    });

    return () => {
      active = false;
    }
  },[]);

  const {
    bill_no = null,
    client_id = null
  } = initialState;

  const bill = useRef(initialState);
  useEffect(() => {
    updateBill(initialState);
  }, [initialState]);

  const updateBill = (newBill) => {
    bill.current = { ...bill.current, ...newBill };
  }

  const handleConfirm = (e) => {
    e.preventDefault();
    let newBill = { author_id };

    ['bill_no','client_id'].map((field) =>
      field.substr(-3) === "_id" ?
      newBill[field] = parseInt(e.target[field].value)
      : newBill[field] = e.target[field].value
    );
    apiBills.post({ bills : [newBill] }).then(data => {
      if(data.hasOwnProperty('error') || !data.length) {
        setError(data.error);
      } else {
        setUpdate(data[0]);
        setError();
        setShow(false);
      }
    });

  }

  return(
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Créer une nouvelle facture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            <Row>
              <Col xs="12">
                <Form.Group>
                  <Form.Label>Facture Numéro</Form.Label>
                  <Form.Control type="text" name="bill_no" defaultValue={initialState.bill_no} />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Control
                required
                type="hidden"
                name="client_id"
                defaultValue={fieldClient.length ? fieldClient[0].id : null} />
            </Form.Group>
            <Row>
              <Col xs="12">
                <Form.Group>
                  <Form.Label>Client <Icon.Shop /></Form.Label>
                  <Typeahead
                    id="client"
                    selected={fieldClient}
                    onChange={(selection) => {setFieldClient(selection)}}
                    options={[...clients].map((client, i) => ({ id: client.id, label: client.name }))}
                    name="client" />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddBillModal
