import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { apiSession } from './services/api/utilities';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Alert } from 'react-bootstrap';
import rsLogo from './images/RPAsimple_carts.png';

import RequestResetModal from './Modals/RequestResetModal';

async function loginUser(credentials) {
  return apiSession.login(credentials).then(data => {
    return data;
  });
}

const responseRel = {
  401 : <span>Mot de passe <strong>incorrect</strong></span>,
  403 : <span>Vous n'êtes pas autorisé à accéder à ce contenu</span>,
  404 : <span>Courriel <strong>non trouvé:</strong> <br />vérifiez l'orthographe</span>,
  422 : <span>Courriel <strong>non valide:</strong> <br />vérifiez l'orthographe</span>,
}


function Login({ setToken, mailPlaceholder }) {
  const [mail, setMail] = useState(mailPlaceholder);
  const [password, setPassword] =  useState();
  const [success, setSuccess] = useState(mailPlaceholder);
  const [error, setError] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);

    const response = await loginUser({
      username : mail.toLowerCase(),
      password
    });

    if(response.hasOwnProperty('error')){
      if(responseRel.hasOwnProperty(response.status)) {
        setError(responseRel[response.status]);
      } else {
        setError(response.error);
      }
    } else {
      setToken(response);
    }
  }

  return (
    <div className="login-wrapper">
      <Row>
        <Col md="7" xs="12" sm="12">
          <img src={rsLogo} alt="Logo RPAsimple" />
        </Col>
        <Col md="1" xs="12" sm="0"></Col>
        <Col md="4" xs="12" sm="12">
          <Form onSubmit={handleSubmit}>
            <h2>{process.env.REACT_APP_CLIENT_NAME}</h2>
            {error ? <Alert variant="warning">{error}</Alert> : null}
            {success ? <Alert variant="success">{success}</Alert> : null}
            <Form.Group>
              <Form.Label>E-Mail</Form.Label>
              <Form.Control defaultValue={mailPlaceholder} required onChange={e => setMail(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <Button type="submit">Submit</Button>
          </Form>
          <RequestResetModal setSuccess={setSuccess} />
        </Col>
      </Row>
    </div>
  )
}

Login.propTypes = {
  setToken : PropTypes.func.isRequired
}

export default Login;
