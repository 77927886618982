import { ApiCore } from './core.js';
import { apiProvider } from './provider.js';
import { handleResponse, handleError } from './response';


/*
https://github.com/flowerchecker/Plant-id-API/wiki/Plant-identification

{
    "images" : ["data:image/png;base64,iVBORw0KGg..."],
    "plant_language" : "fr",
    "plant_details" : ["wiki_description","taxonomy","url","wiki_images"]
}
*/
const apiPlantIdentify = new ApiCore({
  post: true,
  test : true,
  url: 'identify'
});



export {
  ApiCore,
  apiProvider,
  handleResponse,
  handleError,

  apiPlantIdentify,
};
