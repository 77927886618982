import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { Form, InputGroup } from 'react-bootstrap';
import { apiPermissions, apiRoles, apiAddPermission } from '../services/api/utilities';
import useToken from '../hooks/useToken';


function SeeRowsForm ({tables}) {
  //const [errors, setErrors] = useState({});

  return(
    <Form.Group>
      <Form.Group>
        <Form.Label>Dans le tableau</Form.Label>
        <Form.Select name="table_id">
          {tables.map(t => {
            return (
              <option key={t.id} value={t.id}>
                {`${t.name}`}
              </option>
            )
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>donner accès aux données où</Form.Label>
        <InputGroup>
          <Form.Control required name="column_name" placeholder="colonne" />
          <Form.Control.Feedback type="invalid">
            Specifiez le nom de la colonne.
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Label>correspond à la valeur</Form.Label>
        <Form.Control required name="column_value"  placeholder="(x)" />
      </Form.Group>
    </Form.Group>
  )
}

function SeeTableForm ({ tables }) {
  return(
    <Form.Group>
      <Form.Label>Donner accès à tout les données dans le tableau</Form.Label>
      <Form.Select name="table_id">
        {tables.map(t => {
          return (
            <option key={t.id} value={t.id}>
              {`${t.name}`}
            </option>
          )
        })}
      </Form.Select>
    </Form.Group>
  )
}

function ConfirmPermissionForm({permission}) {
  return (
    <Form.Group>
      <Form.Label> Donner la permission <em>{`${permission.description}`}</em> ?</Form.Label>
    </Form.Group>
  )

}



function PermissionFormModal({permission,role_id,tables,setUpdate}){
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleConfirm = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = { permission_id: permission.id, id: role_id };

    for(let i=0;i<(elems.length-1);i++) {
      fields[elems.item(i).name] = elems.item(i).value;
    }

    // should be apiRoles.assignPermission()
    apiRoles.assignPermission(fields).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setUpdate(true);
      handleClose();
    });
    handleClose();
  }

  const FormSwitch = () => {
    switch (permission.accessor) {
      case "get_table":
        return <SeeTableForm tables={tables} />
      case "get_rows":
        return <SeeRowsForm tables={tables} />
      default:
        return <ConfirmPermissionForm permission={permission} />;
    }
  }



  return(
    <>
      <Button size="sm" variant="secondary" onClick={handleShow}>
        {permission.description}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            {FormSwitch(permission.accessor)}
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default PermissionFormModal;
