import React, {useState} from 'react';
import {useAsyncDebounce} from 'react-table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import * as Icon from 'react-bootstrap-icons';

const TWO_HUNDRED_MS = 200;


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  state,
  onUpdate = () => {},
  columns
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
    onUpdate({ ...state, globalFilter : value });
    
  }, TWO_HUNDRED_MS);

  return (
    <Form size="lg">
      <InputGroup>
        <InputGroup.Text><Icon.Search /></InputGroup.Text>
        <Form.Control
          defaultValue={globalFilter}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search`}
        />

      </InputGroup>
    </Form>
  )
}

export default GlobalFilter;
