import ReactMapboxGL, { Layer, Feature } from 'react-mapbox-gl';
import React, { useEffect, useState, useRef } from 'react';
import { apiGeography } from './services/api/utilities';

const config = {
  style : "mapbox://styles/phuet/cl1lvdz6b002715p2uikp7pmn",
  defaultZoom : [8],
};

const Map = ReactMapboxGL({
  accessToken: "pk.eyJ1IjoicGh1ZXQiLCJhIjoiY2wxa2kydjI0MDExZTNkbXk0ZHZsb2RsayJ9.pBGTzSwMHRWdpDqBM4G8kQ",
});



// center : [lon,lat]
// bounds : [[min,min],[max,max]]
// zoom : [0-25]
// viewport : [center||bounds,zoom]

// viewport
// isarray ?
// length: 1 => country code (bbox)
// length: 2 => center (default zoom)
// length: 3 => center, zoom
// length: 4 => bounds
// length: 5 => bounds, zoom

function Mapbox({
  children,
  viewport = ["AT"],
  width = "100%",
  height = "300px",
  pitch = [0]
}) {
  const [goToProps, setGoToProps] = useState();
  const [renderKey, setRenderKey] = useState(0);
  const [opacity, setOpacity] = useState(0);

  const updateGoToProps = () => {
    switch (viewport.length) {
      case 0:
        setGoToProps({});
        break;
      case 1:
        // COUNTRY CODE
        apiGeography.getSingle(viewport[0]).then(country => {
          if(country.hasOwnProperty('error')) return country;

          setGoToProps({
            center : 0,
            zoom : 0,
            fitBounds : [
              [country.longmin,country.latmin],
              [country.longmax,country.latmax]
            ],
          });
        });
        break;
      case 2:
        // CENTER, DEFAULT ZOOM
        setGoToProps({
          center : viewport,
          zoom : config.defaultZoom
        });
        break;
      case 3:
        // CENTER, ZOOM
        setGoToProps({
          center : viewport.slice(0,2),
          zoom : [viewport.slice(-1)],
          // fitBounds : 0
        });
        break;
      case 4:
        // BOUNDS
        setGoToProps({
          // center : 0,
          center : [  (viewport[0] + (viewport[2]-viewport[0])/2),
                      (viewport[1] + (viewport[3]-viewport[1])/2)],
          fitBounds : viewport
        });
        break;
      case 5:
        // BOUNDS, ZOOM
        setGoToProps({
          // center : 0,
          center : [  (viewport[0] + (viewport[2]-viewport[0])/2),
                      (viewport[1] + (viewport[3]-viewport[1])/2)],
          fitBounds : viewport.slice(0,4),
          zoom : [viewport.slice(-1)]
        });
        break;
      default:
        throw("Invalid input given for 'viewport'");
    }


  }
  // When viewport prop changes, update Map props
  useEffect(() => {
    updateGoToProps();
  },[viewport]);

  

  return (
    <Map
      key={`mapbox-${renderKey}`}
      style={config.style}
      pitch={pitch}
      containerStyle={{
          height, width, opacity
        }}
      {...goToProps}
      onStyleLoad={() => {
        setOpacity(1);
        updateGoToProps();
      }}
      >
      {children}
    </Map>
  )
}

// <Map
//   style="mapbox://styles/phuet/cl1lvdz6b002715p2uikp7pmn"
//   containerStyle={{
//     height: '300px',
//     width : '100%',
//   }}
//   center={center}
//   zoom={zoom}
//   fitBounds={bounds}
//    >
//    <Layer
//     images={['marker1',testImage]}
//     type="symbol"
//     id="marker"
//     minZoom={0}
//     maxZoom={24}
//     layout={{
//       // 'icon-image' : 'GeoFill',
//       'icon-image' : 'GeoFill_White',
//       'visibility' : 'visible',
//       'icon-allow-overlap' : true,
//       // 'color' : '#ffffff'
//     }}
//     >
//
//      <Feature coordinates={marker} />
//    </Layer>
// </Map>

export default Mapbox
