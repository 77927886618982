import React, { Suspense, useState, useEffect} from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter, useFlexLayout, useGroupBy, useExpanded, usePagination, useRowSelect  } from 'react-table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import * as Icon from 'react-bootstrap-icons'
import PermissionsGate from './PermissionsGate'


import ReactTableHeader from './ReactTableHeader'
import ReactTableBody from './ReactTableBody'
import FlexTableFooter from './FlexTableFooter'
import FlexTablePagination from './FlexTablePagination'

import useExpandedWidth from './services/react-table/hooks/useExpandedWidth'

import { sortFloatNegative, handleScroll, defaultFilterTypes, defaultColumnProps, IndeterminateCheckbox } from './services/react-table/defaults'
import { selectFilters } from './actions'



function ReactTable({
  data,
  columns,
  initialState,
  loading,
  title = '',
  accessor = '',
  state,
  page,
  rows,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  footerGroups,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  gotoPage,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,

  // CUSTOM
  rowDeleted = (( row ) => row.hasOwnProperty('deleted')),
  className = "",
}) {

  const filterTypes = React.useMemo(() => (defaultFilterTypes),[]);
  const defaultColumn = React.useMemo(() => (defaultColumnProps),[]);


  const rowRenderStyle = initialState.usePagination ? page : rows;

  const renderLoader = () => (
    <div style={{textAlign : 'center', padding: '2em'}}>
      <Spinner animation="border" variant="primary" />
    </div>
  )


  return(

      <>

      <div {...getTableProps()}
        className={`table
            ${className}
            ${loading ? 'table-loading ' : ''}
            ${rows.some((r) => r.isExpanded === true) ? 'table-expanded ' : ''}`}
        >

          <ReactTableHeader headerGroups={headerGroups} initialState={initialState} />
          {loading ?
            renderLoader() :
            <ReactTableBody
              rowDeleted={rowDeleted}
              getTableBodyProps={getTableBodyProps}
              rowRenderStyle={rowRenderStyle}
              prepareRow={prepareRow} />
          }
          {loading || columns.filter(c => c.hasOwnProperty('Footer')).length === 0 ? '' : <FlexTableFooter footerGroups={footerGroups} />}

      </div>


    {initialState.usePagination ?
      <FlexTablePagination
        state={state}
        initialState={initialState}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions} />
     : null }
    </>

  );
}

export default ReactTable
