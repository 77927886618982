import axios from 'axios';
import { handleResponse, handleError } from './response';

const testData = require('../../../data/flower_response.json');

const BASE_URL = process.env.REACT_APP_PLANTID_API_ROOT;
const API_KEY = process.env.REACT_APP_PLANTID_API_KEY;




const modelIdentifyDefault = {
  "plant_language" : "fr",
  "plant_details" : ["wiki_description","taxonomy","url","wiki_images"],
  "images" : []
}



/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {

  if(resource === 'identify') model = {...modelIdentifyDefault, ...model};

  return axios
    .post(`${BASE_URL}/${resource}`, model, {
      headers : {
        'Api-Key' : API_KEY,
        'Content-Type' : 'application/json'
      }
    })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const test = (resource, model) => {
  return new Promise((resolve, reject) => {
    resolve(testData);
  });
};


export const apiProvider = {
  post,
  test,
};
