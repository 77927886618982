import { combineReducers } from 'redux'
import tokenReducer from './tokenReducer'
import userReducer from './userReducer'
import dashboardReducer from './dashboardReducer'
import interfaceReducer from './interfaceReducer'
import productReducer from './productReducer'
import clientReducer from './clientReducer'
import billReducer from './billReducer'

export default combineReducers({
  token : tokenReducer,
  user : userReducer,
  dashboard : dashboardReducer,
  interface : interfaceReducer,
  products : productReducer,
  clients : clientReducer,
  bills : billReducer,
});
