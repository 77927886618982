import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RangeSlider from 'react-bootstrap-range-slider'

import CurrencyInput, { formatValue } from 'react-currency-input-field'
import currency from 'currency.js'

import { Link, Navigate, useParams } from 'react-router-dom'
import { apiProducts, apiProductCategories } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import SelectBillModal from './Modals/SelectBillModal'


const currencyFormat = {
  groupSeparator : ', ',
  decimalSeparator : '.',
  prefix : '€ ',
  fixedDecimalLength : 2,
};


const grid = {
  'default' : {
    'pa' : {
      xs : {span : 6, offset : 0, order: 0},
      sm : {span : 4, offset : 0, order: 0},
      md : {span : 6, offset : 0, order: 0},
    },
    'pv' : {
      xs : {span : 6, offset : 0, order: 0},
      sm : {span : 4, offset : 0, order: 0},
      md : {span : 6, offset : 0, order: 0},
    },
    'tarif' : {
      xs : {span : 12, offset : 0, order: 0},
      sm : {span : 8, offset : 0, order: 0},
      md : {span : 12, offset : 0, order: 0},
    },
    'size' : {
      xs : {span : 12, offset : 0, order: 0},
      lg : {span : 5, offset : 0, order: 0},
    }
  },
  'mini' : {
    'pa' : {
      xs : {span : 12, offset : 0, order: 0},
      sm : {span : 6, offset : 0, order: 0},
    },
    'pv' : {
      xs : {span : 12, offset : 0, order: 0},
      sm : {span : 6, offset : 0, order: 0},
    },
    'size' : {
      xs : {span : 12, offset : 0, order: 0},
    }
  },
};




const parseSizes = (config) => {
  let s = [];
  config.map(([from, to, steps], i) => {
    for(let i = from; i<= to; i+=steps) {
        s.push(i);
    }
  });
  return s;
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function reduceArraySize(size, inArray, resultArray = []) {
    if (inArray.length < size) { return inArray }
    const spacing = inArray.length / size;
    var i = -1;
    while (++i < size) {
        resultArray[i] = inArray[Math.round(i * spacing)];
    }
    resultArray.length = size;  //if the result is same array shorten it
    return resultArray;
}

const RangeLabels = ({ values, prefix, suffix, container, color = "#ffffff" }) => {
  const labelsRef = useRef(container.current);
  const [width, height] = useWindowSize();
  const [labelsWidth, setLabelsWidth] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const expWidth = 25;

  const updateLabels = () => {
    setLabelsWidth(labelsRef.current.offsetWidth);
    let factor = Math.floor(labelsWidth/expWidth);
    // let delta = Math.ceil(values.length/factor);
    // let offset = 2;
    return [...reduceArraySize(factor, values), values[values.length-1]];
    // return (values.filter((value,i) => (i) === 0 || (i) % delta === delta - 1));
  }

  useEffect(() => {
    setSelectedValues(updateLabels());
  },[width, labelsWidth]);


  return (
    <ul className="form-range-labels" ref={labelsRef} style={{color}}>
      {selectedValues.map((value,i) => (
        <li key={`label-${value}`} style={{
          left : i*expWidth*((selectedValues.length-1)/selectedValues.length),
          width: `${expWidth}px`,
          // borderColor : color
        }}>
          <span style={{
            transform : `translateY(1.2em) rotate(90deg)`
          }}>
            {prefix ? prefix : null}{Math.round(value/10)}{suffix ? suffix : null}
          </span>
        </li>
      ))}

    </ul>
  )
}


function ArticleEdit({ article, handleDelete, onUpdate, allowDelete, layout="default" }) {
  const labelsContainerRef = useRef(null);
  const [multiplier, setMultiplier] = useState(1);
  const [deleted, setDeleted] = useState(false);
  const [pa, setPa] = useState(article.pa);
  const [pv, setPv] = useState(article.pv);

  const sizeConfig = [[10,200,5],[210, 400, 10],[425, 1000, 25],[1050,1500,50],[1600,2000,100]]; // FROM, TO, STEPS OF
  const sizes = useMemo(() => parseSizes(sizeConfig),[]);
  const [level, setLevel] = useState(article.size);


  // useEffect(() => {
  //   onUpdate({
  //     ...article,
  //     size: level,
  //     pa : pa,
  //     pv : pv,
  //   })
  // },[level, pa, pv]);


  const translateRange = (value) => {
    let index = Math.round((value*sizes.length)/100);
    return sizes[index >= sizes.length? (sizes.length - 1) : index ]/10;
  }

  const translateLevel = (level) => {
    let closest = sizes.reduce(function(prev, curr) {
      return (Math.abs(curr - (level*10)) < Math.abs(prev - (level*10)) ? curr : prev);
    });
    return sizes.indexOf(Math.round(parseInt(closest)));
  }

  const deleteArticle = () => {
    setDeleted(true);
    setTimeout(() => handleDelete(), 500);
  }
  

  return (
    <div className={`article-form-container ${deleted ? "delete" : ""} ${layout==="mini" ? "article-form-container-mini" : ""}`}
      key={`product-article-${article.id}`}>
      <Row>
        <Col xs="12" md="6">
          <Row>
            <Col {...grid[layout].pa}>
              <Form.Group>
                <Form.Label>PV</Form.Label>
                <CurrencyInput
                  id="pv"
                  className="form-control"
                  name="pv"
                  placeholder={`Pv`}
                  min={0}
                  defaultValue={article.pv}
                  {...currencyFormat}
                  onKeyDown={(e) => {
                    if(e.ctrlKey) setMultiplier(0.05);
                  }}
                  onKeyUp={(e) => {
                    if(!e.ctrlKey) setMultiplier(1);
                  }}
                  step={multiplier}
                  onValueChange={(value) => {
                    setPv(currency(value).value);
                    onUpdate({
                        ...article,
                        pv : currency(value).value
                      });
                    }} />
              </Form.Group>
            </Col>
            <Col {...grid[layout].pa}>
              <Form.Group>
                <Form.Label>PA</Form.Label>
                <CurrencyInput
                  id="pa"
                  className="form-control"
                  name="pa"
                  placeholder={`PA`}
                  min={0}
                  defaultValue={article.pa}
                  {...currencyFormat}
                  onKeyDown={(e) => {
                    if(e.ctrlKey) setMultiplier(0.05);
                  }}
                  onKeyUp={(e) => {
                    if(!e.ctrlKey) setMultiplier(1);
                  }}
                  step={multiplier}
                  onValueChange={(value) => {
                    setPa(currency(value).value);
                    onUpdate({
                        ...article,
                        pa : currency(value).value
                      });
                    }} />
              </Form.Group>
            </Col>
            {layout==='mini' ? null : <Col {...grid[layout].tarif}>
              <Form.Group>
                <Form.Label>Tarif</Form.Label>
                <Form.Control
                  id="tarif"
                  className="form-control"
                  name="tarif"
                  placeholder={`Tarif`}
                  onChange={(e) => onUpdate({...article, tarif: e.target.value})}
                  defaultValue={article.tarif} />
              </Form.Group>
            </Col>}
          </Row>
        </Col>
        <Col {...grid[layout].size}>
          <Row>
            <Col xs="12" style={{textAlign : 'left'}}>
              <Form.Label>TAILLE</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={level}
                  max={sizes[sizes.length -1]}
                  min="0"
                  step="any"
                  onChange={(e) => {
                    if(e.target.value >= (sizes[sizes.length - 1]/10)) {
                      setLevel(sizes[sizes.length - 1]/10);
                      onUpdate({
                        ...article,
                        size : sizes[sizes.length - 1]/10
                      });
                    } else {
                      setLevel(e.target.value);
                      onUpdate({
                        ...article,
                        size : e.target.value
                      });
                    }

                  }} />
                    <InputGroup.Text> cm</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" ref={labelsContainerRef}>

              <Form.Range
                onChange={(e) => {
                  setLevel(translateRange(e.target.value));
                  onUpdate({
                    ...article,
                    size : translateRange(e.target.value)
                  });
                }}
                value={translateLevel(level)}
                />
              <RangeLabels
                values={sizes}
                suffix=" cm"
                color="#aaa"
                container={labelsContainerRef} />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="article-edit-controls">
        {article.bills?.length ?
            <SelectBillModal bills={article.bills} />
            : null
        }
        {!allowDelete ? null : <ConfirmDeleteModal
          handleDelete={deleteArticle}
          onShow={() => setDeleted(true)}
          onHide={() => setDeleted(false)}
          message={`Souhaitez-vous supprimer cet article?`}
          title={`Supprimer article`}
           />}
      </div>
    </div>
  )
}

export default ArticleEdit
