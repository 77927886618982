import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from './Table'
import * as Icon from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link, useNavigate } from 'react-router-dom'
import currency from 'currency.js'

import { apiProducts } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import PermissionsGate from './PermissionsGate'
import ProductSelector from './ProductSelector'


const imageRoot=`${process.env.REACT_APP_IMAGES}/`;
const thumbnailRoot=imageRoot + "thumbnails/";

const currencyFormat = {
  groupSeparator : ', ',
  group : ', ',
  decimalSeparator : '.',
  decimal : '.',
  prefix : '€ ',
  symbol : '€ ',
  fixedDecimalLength : 2,
};

function ProductsOverview () {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState();
  const productFilters = useSelector(state => state.products.filters);
  const testRef=useRef();
  const dispatch=useDispatch();
  const navigate=useNavigate();

  useEffect(() => {
    let active = true;
    apiProducts.getAll().then(data =>{
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      if(active) {
        setProducts(data);
        setError();
      }
    });

    return () => {
      active = false;
    }
  },[products]);



  return (
    <>
      <h2>Produits</h2>
      {error ? <Alert>{error}</Alert> : null}
      <ProductSelector onSubmit={([product]) => navigate(`/product/${product.id}`)} />
      <Table
        className="manageTable"
        data={products}
        columns={React.useMemo(() => ([{
          Header: 'Nom',
          accessor: 'name',
          sortType : 'basic',
          width : 280,
          Cell : (fields) => {
            let {cell} = fields;
            let thumbSrc = (cell.row.original.images.length > 0) ? `${thumbnailRoot}product_${cell.row.original.images[0].hash}.png` : false;
            return(
              <Link className={`link-cell`} to={`/product/${cell.row.original.id}`}>
                {cell.value}
                {thumbSrc ?
                  <div className="product-thumbnails">
                    <img src={thumbSrc} alt={cell.row.original.images[0].alt} />
                  </div> : null}
                <span className="product-id">{cell.row.original.code}</span>
              </Link>)
          }
        },{
          Header: 'Status',
          accessor: 'availability',
          sortType : 'basic',
          width: 100
        },{
          Header: 'Category',
          accessor: 'product_category_name',
          width : 220,
          sortType : 'alphanumeric',
          filter: "equals",
          Filter : ({ cell, row,
                      column: {
                        filterValue,
                        setFilter,
                        preFilteredRows,
                        id },
                    }) => {
            return (
              <>
                <Button
                  style={{textAlign : 'left'}}
                  variant="link"
                  onClick={(e) => {
                    let filter = {
                       id,
                       value : row.original.product_category_name
                     };
                    setFilter(filter.value);
                    dispatch({
                        type:"FILTER_PRODUCT_TABLE",
                        payload : filter
                      });
                  }}>
                  {row.original.product_category_name}
                </Button>
                {filterValue !== undefined ?
                  <Button

                    variant="link"
                    onClick={(e) => setFilter()}
                    >
                    <Icon.X />
                  </Button>
                : null}
              </>
            )
          },
          Cell : ({cell}) => {
            return (cell.value===null) ? null : cell.render('Filter')
          },

        },{
          Header: 'Edit',
          accessor: 'edit',
          disableSortBy : true,
          width : 120,
          Cell : (fields) => {
            const {cell} = fields,
              {original} = cell.row;
            return(
              <>
                <ConfirmDeleteModal
                  message={`Vous souhaitez supprimez le produit ${original.name}`}
                  handleDelete={() => {
                    apiProducts.remove(original.id).then(data => {
                      if(data.hasOwnProperty('error')) {
                        setError(data.error);
                        return data;
                      }
                      setProducts(products.filter(product => product.id !== original.id));
                    });
                }} />
                <Link to={`/product/${original.id}`}>
                  <Icon.Gear />
                </Link>
              </>
            );
          }
        }]),[])}/>

    </>
  )

}

export default ProductsOverview;
