const initialState = {
  id : -1,
  first_name : "",
  last_name : "",
  mail: "",
  permissions : [],
  coords : {lat: null, lon: null},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      let { id, first_name, last_name, mail } = action.payload;
      return { ...state, id, first_name, last_name, mail };
    case 'UPDATE_USER':
      return { ...state, ...action.payload };
    case 'SET_USER_PERMISSIONS':
      return {...state, permissions : action.payload};
    case 'SET_USER_COORDINATES':
      return {...state, coords : action.payload};

    case 'RESET_USER':
      return initialState;

    // case 'UPDATE_FROM_DB' :
    //   return { ...state, ...action.payload.user };

    default:
      return state;
  }
}

export default userReducer
