import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import PermissionsGate from './PermissionsGate';

function Users() {
  const currentUser = useSelector(state => state.user);
  return (
    <div className="form-container">
      <PermissionsGate any={true} permissions={['edit_user','add_user','rm_user','edit_permissions']} errorProps={{to : `/users/${currentUser.id}`}}>
        <Link to="/users" style={{color : 'white'}}>
          <h2>Users</h2>
        </Link>
      </PermissionsGate>
      <Outlet />
    </div>
  );
}

export default Users;
