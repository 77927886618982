const initialState = {
  currentView : '',
  sidebarCollapsed : false,
  preload : [],
  path : '/',
  geography : { countries : [] }
};

const interfaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIDEBAR_TOGGLE':
      return { ...state, sidebarCollapsed : !state.sidebarCollapsed};
    case 'PRELOAD_APPEND' :
      return { ...state, preload : [...state.preload, ...action.payload]};
    case 'PRELOAD_PREPEND' :
      return { ...state, preload : [...action.payload,...state.preload]};
    case 'PRELOAD_CLEAR' :
      return { ...state, preload : []};
    case 'UPDATE_PATH' :
      return {...state, path : action.payload }
    case 'UPDATE_FROM_DB' :
      return { ...state, ...action.payload.interface }
    case 'SET_GEOGRAPHY':
      return {...state, geography : { countries : action.payload }}
    case 'RESET_ALL':
      return initialState;
    default:
      return state;
  }
}

export default interfaceReducer;
