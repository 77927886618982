import React, { useEffect, useState, useRef } from 'react'
import * as Icon from 'react-bootstrap-icons'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom'
import { apiBills, apiContacts, apiCompanies, apiClients, apiArticles } from './services/api/utilities'

import AddressForm from './AddressForm'
import ArticlesSelector from './ArticlesSelector'
import BillArticlesTable from './BillArticlesTable'
import ArticlesGallery from './ArticlesGallery'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'


function BillForm() {
  const id = useParams().billId;
  const navigate = useNavigate();
  const [bill, setBill] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [fieldClient, setFieldClient] = useState([]);
  const [fieldContact, setFieldContact] = useState([]);
  const [articles, setArticles] = useState([]);

  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);

  // const [contactModalShow, setContactModalShow] = useState(false);
  // const [contactManagerModalShow, setContactManagerModalShow] = useState(false);
  // const [companyModalShow, setCompanyModalShow] = useState(false);







  useEffect(() => {
    let active = true;

    apiBills.getSingle(id).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data);
        return data;
      }
      if(active) {
        setBill(data);
      }
      let { contact_id, client_id } = data;

      let requests = [];

      requests.push(apiContacts.getAll().then(dataContacts => {
        if(dataContacts.hasOwnProperty('error')) {
          setError(dataContacts);
          return dataContacts;
        }
        if(active) setContacts(dataContacts);
        let selectedContact = dataContacts
                .filter(contact => contact.id === contact_id)
                .map(contact => ({
                  ...contact,
                  id: contact.id,
                  label: `${contact.first_name} ${contact.last_name}`
                }));

        if(contact_id !== undefined && selectedContact.length)
          setFieldContact(selectedContact);
      }));


      requests.push(apiClients.getAll().then(dataClients => {
        if(dataClients.hasOwnProperty('error')) {
          setError(dataClients);
          return dataClients;
        }
        if(active) setClients(dataClients);
        let selectedClient = dataClients
                .filter(client => client.id === client_id)
                .map(client => ({ ...client, label : client.name, id: client.id}));
        if(client_id !== undefined && selectedClient.length)
          setFieldClient(selectedClient);
      }));

      Promise.all(requests).then(() => {
        if(active)
          setLoading(false);
      });
    });




    return () => {
      active = false;
    }
  },[]);

  const handleDelete = () => {
    apiBills.remove(id).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setError();
      navigate('/bills');
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    let billUpdate = {};

    let inputFields = Array.from(e.target.elements).filter(f => f.name);
    let clientFields = [  "bill_no", "client_id", "contact_id",
                          "text_top", "text_bottom", "date_billed"];

    clientFields.map(key =>
      billUpdate[key] = inputFields.find(input => input.name === key)?.value
    );

    apiBills.patch(bill.id, billUpdate);


    let article_bill_rel = bill.articles.map(
      ({quantity, id, attach_images}) => ({
        bill_id : bill.id,
        quantity,
        article_id: id,
        attach_images})
    );

    apiBills.assignArticles({ id : bill.id, article_bill_rel });



  }

  const renderClientLabel = ({
      company_name,
      address2,
      address1,
      postal_code,
      locality,
      country }) => (
      <p>
        <strong>{company_name}</strong><br />
        {address2} {address1}<br />
        {postal_code} {locality}<br />
        {country}
      </p>
    );

  const renderContactLabel = ({
      salutation,
      first_name,
      last_name,
      mail,
      tel }) => (
      <p>
        <strong>{salutation} {first_name} {last_name}</strong><br />
        {mail}<br />
        {tel}
      </p>
    );


  if(loading) return("...");

  const {
    from_address1, from_address2, from_locality, from_country, from_postal_code,
    address1, address2, locality, country, postal_code,
    rcs, tva, rib, company_name,
    contact_salutation, contact_tel, contact_mail, contact_first_name, contact_last_name
  } = bill;


  return (
    <Form onSubmit={onSubmit}>

      <Row>
        <Col><h2><Link to={`/bill/${bill.id}`} style={{color : 'white'}}>Facture {bill.bill_no}</Link></h2></Col>
      </Row>



      <Form.Group className="bill-form-group bill-form-group-details">
        <Row>
          <Col><h4>Details</h4></Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" md="4">
            <Form.Control type="text" name="bill_no" defaultValue={bill.bill_no}/>
            <Form.Label>Numéro</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" md="4">
            <Form.Control type="hidden" value={fieldClient.length ? parseInt(fieldClient[0].id) : undefined} name="client_id" />
            <Typeahead
              id="client"
              labelKey="label"
              allowNew={false}
              clearButton={true}
              defaultSelected={fieldClient}
              onChange={(selection) => { setFieldClient(selection)}}
              options={clients.map((client, id) => ({...client, id : client.id, label : client.name }))}
               />
            <Form.Label>Client</Form.Label>

          </Col>
          <Col>
            { renderClientLabel(fieldClient.length ?
              fieldClient[0]
              : {...bill, ...(bill.addresses.length ?
                  bill.addresses[0]
                  : {})}
            )}
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="6" md="4">
            <Form.Control type="hidden" value={fieldContact.length ? parseInt(fieldContact[0].id) : undefined} name="contact_id" />
            <Typeahead
              id="contact"
              labelKey="label"
              allowNew={false}
              clearButton={true}
              defaultSelected={fieldContact}
              onChange={(selection) => { setFieldContact(selection)}}
              options={contacts.map((contact, id) => ({
                ...contact,
                id : contact.id, label : `${contact.first_name} ${contact.last_name}`
              }))}
               />
            <Form.Label>Contact</Form.Label>
          </Col>
          <Col>
            { renderContactLabel(fieldContact.length ? fieldContact[0] : {
              salutation : bill.contact_salutation,
              first_name : bill.contact_first_name,
              last_name : bill.contact_last_name,
              mail : bill.contact_mail,
              tel : bill.contact_tel
            }) }
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="bill-form-group bill-form-group-text_top">
        <Row>
          <Col><h4 id="text-1">Texte de l'en-tête <Icon.CardText /></h4></Col>
        </Row>
        <Row>
          <Col xs="12" lg="8">
            <Form.Control as="textarea" rows="8" id="text_top" defaultValue={bill.text_top} />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="bill-form-group bill-form-group-articles">
        <Row>
          <Col><h4 id="articles">Articles <Icon.Box /></h4></Col>
        </Row>
        <Row>
          <Col xs="12" lg="8">
            <ArticlesSelector
              onSubmit={(fieldArticles) => (
                setBill({...bill, articles : [...bill.articles, ...fieldArticles]})
              )}
              articlesFilter={(article) => (
                !bill.articles.map((a,i) => (a.id)).includes(article.id)
              )} />
            <br />
            <BillArticlesTable
              bill={bill}
              onUpdate={(articles) => setBill({...bill, articles })} />

          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="bill-form-group bill-form-group-text_bottom">
        <Row>
          <Col><h4 id="text-2">Texte de pied de page <Icon.CardText /></h4></Col>
        </Row>
        <Row>
          <Col xs="12" lg="8">
            <Form.Control as="textarea" rows="8" id="text_bottom" defaultValue={bill.text_bottom} />
          </Col>
        </Row>
      </Form.Group>


      <Button type="submit">Sauvegarder des changements</Button>
      <Button
        onClick={() => navigate(`/bill/${bill.id}`)}
        variant="secondary">Retour à l'aperçu</Button>
      <ConfirmDeleteModal
        message={`Vous souhaitez supprimez la facture ${bill.bill_no}?`}
        handleDelete={handleDelete}>
        <Button variant="danger">Supprimer la facture</Button>
      </ConfirmDeleteModal>
    </Form>
  )
}

export default BillForm
