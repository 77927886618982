import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import useToken from './hooks/useToken';
import { Form } from 'react-bootstrap';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useParams } from 'react-router-dom';
import PermissionsForm from './PermissionsForm';
import { hasPermission } from './PermissionsGate';


import {apiUsers} from './services/api/utilities';


function UserDetail({ currentUser }) {
  const { token } = useToken();
  const [user,setUser] = useState({id:0, first_name : '', last_name: '', 'mail' : ''});
  const [change,setChange] = useState(false);
  const [error,setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const activeUser = useSelector(state => state.user);
  const userPermissions = useSelector(state => state.user.permissions);
  let id = useParams().userId;


  useEffect(() => {
    let active = true;


    setLoading(true);
    apiUsers.getSingle(id).then((data) => {
      if(data.hasOwnProperty('error') && active) {
        setError(data.error);
        return data;
      }
      if(active) {
        setUser(data);
        setLoading(false);
      }
    });

    
    return () => {
      active = false;
    };
  },[id,token]);

  // IF ANY CHANGE IS MADE TO ANY FIELD => SHOW SAVE CHANGES BUTTON
  const handleUpdate = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = { };

    for(let i=0;i<(elems.length-1);i++) {
      fields[elems.item(i).name] = elems.item(i).value;
    }

    apiUsers.patch(id,fields).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setChange(false);
    });
  }

  return (
    <>
      {error ? <Alert>{error}</Alert> : null}
      <Form size="md" onSubmit={handleUpdate} disabled={user ? undefined : 'disabled'}>
        <Row>
          <Col md="6" xs="12">
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="first_name"
                onChange={() => {setChange(true)}}
                defaultValue={user ? user.first_name : ''}
                disabled={((currentUser.id === id || hasPermission(['edit_users'],userPermissions)) && !loading) ? undefined : "disabled"} />
            </Form.Group>
          </Col>
          <Col md="6" xs="12">
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="last_name"
                onChange={() => {setChange(true)}}
                defaultValue={user ? user.last_name : ''}
                disabled={((currentUser.id === id || hasPermission(['edit_users'],userPermissions)) && !loading) ? undefined : "disabled"} />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>E-Mail</Form.Label>
          <Form.Control
            name="mail"
            type="email"
            onChange={() => {setChange(true)}}
            defaultValue={user ? user.mail : ''}
            disabled={((currentUser.id === id || hasPermission(['edit_users'],userPermissions)) && !loading) ? undefined : "disabled"} />
        </Form.Group>
        {change ? <Button type="submit">Sauvegarder les changements</Button> : null}
      </Form>
      <br />
      <PermissionsForm role_id={user.user_role_id} token={token} />
    </>
  );
}

export default UserDetail;
