import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { Form } from 'react-bootstrap';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import { apiUsers } from '../services/api/utilities';
import useToken from '../hooks/useToken';


function AddUserFormModal({setUpdate}){
  const { token } = useToken();
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleConfirm = (e) => {
    e.preventDefault();
    let elems = e.target.elements;
    let fields = { token };
    let except = ['repeat_password'];

    if(elems.repeat_password.value !== elems.password.value) {
      setError("Passwords do not match");
      return true;
    }



    for(let i=0;i<(elems.length-1);i++) {
      if(except.some(x => x === elems.item(i).name))
        continue;
      fields[elems.item(i).name] = elems.item(i).value;
    }

    apiUsers.post(fields).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      setUpdate(true);
      setError();
      handleClose();
    });

  }


  return(
    <>
      <Button size="sm" variant="secondary" onClick={handleShow}>
        Ajouter un utilisateur <Badge><Icon.Plus /></Badge>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <Form size="md" onSubmit={handleConfirm}>
            <Row>
              <Col sm="6" xs="12">
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control required type="text" name="first_name" />
                </Form.Group>
              </Col>
              <Col sm="6" xs="12">
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control required type="text" name="last_name" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Adresse mail</Form.Label>
              <Form.Control type="mail" name="mail" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control required type="password" name="password" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Repeter mot de passe</Form.Label>
              <Form.Control required type="password" name="repeat_password" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Confirmer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AddUserFormModal;
