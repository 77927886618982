import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import storeSetup from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {BrowserRouter} from 'react-router-dom'

let { store, persistor } = storeSetup();

const container = document.getElementById('root');
container.setAttribute('notranslate',true);
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={<h1>Waiting!</h1>} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
