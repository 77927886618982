const initialState = {
  currentTable : 0,
  tables : [],
  tablesState : [],
  dbUpdated : false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TABLES':
      return {
        ...state,
        currentTable : state.currentTable >= action.payload.length ? 0 : state.currentTable,
        tables : action.payload,
        tablesState : action.payload.map((table,i) => ({
          ...table.initialState
        }))};
    case 'SET_TABLE_HIDDEN_COLUMNS':
      return {...state, tablesState : state.tablesState.map((table,index) => {
        if(index !== state.currentTable) return table;
        return {...table, hiddenColumns : action.payload};
      })}
    case 'SAVE_TABLE_STATE':
      return {...state, tablesState : state.tables.map((table,index) => {
        if(index !== state.currentTable) return state.tablesState[index];
        return {...state.tablesState[index], ...action.payload};
      })}

    case 'ADD_TABLE_FILTERS':
      let newFilters = action.payload;
      return {...state, tablesState : state.tablesState.map((tableState,index) => {
        if(index !== state.currentTable) return tableState;
        return {...tableState,
          // return the filters that do not contain any id's contained in new filters
          //        as well as the new filters
          // oldFilters.filter(f => !newFilters.some(n => n.id === f.id))
          // finally remove filters with empty values
          filters : tableState.hasOwnProperty('filters') ?
            [...tableState.filters.filter(f => !newFilters.some(n => n.id === f.id)), ...newFilters].filter(f => f.value !== "")
            : newFilters.filter(f => f.value !== "")
        }
      })}

    case 'SET_TABLE_FILTERS':
      let filters = action.payload;
      return {...state, tablesState : state.tablesState.map((tableState,index) => {
        if(index !== state.currentTable) return tableState;
        return {...tableState, filters}
      })};


    case 'SELECT_TABLE':
      return {...state, currentTable : action.payload}

    case 'RESET_DASHBOARD':
      return initialState;
    case 'UPDATE_FROM_DB' :
      return { ...state, ...action.payload.dashboard, dbUpdated : true }
    case 'RESET_ALL':
      return initialState;

    default:
      return state;
  }
}

export default dashboardReducer
