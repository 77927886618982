import React, { useState, useEffect } from 'react';
import PermissionFormModal from './Modals/PermissionFormModal';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ListGroup  from 'react-bootstrap/ListGroup';
import PermissionsGate from './PermissionsGate';

import {apiRoles} from './services/api/utilities';


function PermissionsForm2({role_id}) {
  /*
  Permissions in this form show as
   - Descriptor || accessor || []* assigned || table_selector (if applies) || column_selector (if applies) + input_field (value)
   - A plus reveals an autofill search tooltip for unused permissions that by confirming can be added to the list
   *checkboxes
  */
  const [permission_rels,setPermissions] = useState([]);
  const [unusedPermissions,setUnusedPermissions] = useState({permissions : [], tables : []});
  const [error,setError] = useState(false);

  useEffect(() => {
    apiRoles.getPermissions({id : role_id}).then(data => setPermissions(data));
    apiRoles.getUnusedPermissions({id : role_id}).then(data => setUnusedPermissions(data));
  }, []);



  return(
    <>
      <h1>Testing</h1>
      <h2>Permissions
        <Form.Select>
          <option disabled><Icon.Plus /></option>
          {unusedPermissions.permissions.map(permission =>  (
              <option>{permission.description}</option>
            ))}
        </Form.Select>
      </h2>
      <ul>
        {permission_rels.map(permission =>
        <li>
          {`${permission.description}`}
        </li>)}
      </ul>
    </>
  )




}

export default PermissionsForm2;
