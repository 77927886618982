import React, { useEffect, useState, useRef } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FileUploader } from 'react-drag-drop-files';
import Reorder from 'react-reorder';
import { Link, useParams } from 'react-router-dom';
import AddArticleModal from './Modals/AddArticleModal';
import ProductSelector from './ProductSelector';
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal';
import PermissionsGate from './PermissionsGate';

import {cloneDeep} from 'lodash'

function ImageDropperAccordion ({
  handleChange,
  handleSelect = () => (null),
  deleteImage,
  editImageAlt,
  data,
  types,
  entryLimit = 5,
  // ACCORDION PROPS
  alwaysOpen = true,
  // defaultActiveKey = 0,
  onSelect = () => {},
  flush = false
 }) {
  const inputRef = useRef();
  const containerRef = useRef();
  const [reorderDisabled, setReorderDisabled] = useState(false);
  const [showCount, setShowCount] = useState(data.map((item) => (entryLimit)));
  const step = 5;
  const accordionProps = {
    alwaysOpen,
    // defaultActiveKey,
    onSelect,
    flush
  }

  const [addArticleShow, setAddArticleShow] = useState(false);
  const [newArticleInitialState, setNewArticleInitialState] = useState({});

  useEffect(() => {
    setShowCount(data.map((item) => (entryLimit)));
  }, [data.length]);




//backgroundImage : `url(https://rpasimple.local/images/thumbnails/product_${image.path}.png)`
  const dropperEntries = (item,i) => {
    let itemLimit = showCount[i];
    return (
      <ul className="ida-entries">
        {item.images.filter((image, j) => j < itemLimit).map((image,j) => (
          <li
            className={`ida-entry ${image.deleted ? 'ida-entry-deleted' : ''}`}
            key={`ida-entry-${i}-${j}`}
            style={{
              backgroundImage : `url(${image.path})`
            }}
            >

            <span onClick={image?.onClick}>{image.name}</span>
            <div className="ida-entry-controls">
                <ConfirmDeleteModal
                  message={"Souhaitez-vous Supprimer cet article?"}
                  title={"Supprimer"}
                  handleDelete={() => {
                    deleteImage(image.id,i);
                  }} />
              </div>
          </li>
        ))}
        {item.images.length <= itemLimit ? null :
          <li
            className={`ida-entry ida-entry-more`}
            key={`ida-entry-${i}-more`}
            onClick={() => setShowCount(showCount.map((limit, j) => (i === j ? limit+step : limit)))}
            >
            ...
          </li>
        }
      </ul>
    )
  }


  const dropperTarget = () => (
    <Button variant="secondary" className="ida-target pull-right">
       <Icon.Flower1 /> Ajouter des images
    </Button>
  );

  return (
    <>
      <Accordion
        bsPrefix="ida-accordion"
        {...accordionProps}
        >
        {data.map((item,i) => (
          <Accordion.Item eventKey={i} key={`ida-item-${i}`}>
            <Accordion.Header as="div">
              <div>{item.header}</div>


            </Accordion.Header>
            <Accordion.Body>
             <div className={`ida-controls`}>
               <FileUploader
                 handleChange={(e) => handleChange(e,i)}
                 name={`ida-images-${i}`}
                 types={types}
                 children={dropperTarget()}
                 multiple={true}
                 />
               <ProductSelector
                placeholder="Ajouter un produit"
                onSubmit={([product]) => handleSelect({
                  ...product, pv: item.price.pv, pa: item.price.pa
                })} />
             </div>

              <FileUploader
                onDrop={(e) => handleChange(e,i)}
                name={`ida-images-${i}`}
                types={types}
                children={dropperEntries(item,i)}
                multiple={true}
                hoverTitle={<Icon.Flower1 size={64} />}
                fileOrFiles={null}

                disabled
                />

                {item.images.filter(img => img.added).map((image, j) => (
                  <div key={`image-added-${j}`} className="image-dropper-added"></div>))}

            </Accordion.Body>
          </Accordion.Item>
        ))}

      </Accordion>

    </>
  )

};

export default ImageDropperAccordion;
//
// <Button className="pull-right" onClick={(e) => {
//   e.preventDefault();
//   setNewArticleInitialState({...item});
//   setAddArticleShow(true)}}>
//   Produit
// </Button>
// <AddArticleModal
//   show={addArticleShow}
//   initialState={newArticleInitialState}
//   setShow={setAddArticleShow}
//   />
