import React, { useEffect, useState } from 'react';
import Table from './Table';
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { apiRoles } from './services/api/utilities';
import AddRoleFormModal from './Modals/AddRoleFormModal';
import AssignRoleModal from './Modals/AssignRoleModal';
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal';
import PermissionsGate from './PermissionsGate';

import useToken from './hooks/useToken';


function RolesTable () {
  const {token} = useToken();
  const [roles, setRoles] = useState([]);
  const [update,setUpdate] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    let active = true;
    const parseRoles = (rolesData) => {
      let parsedRoles = [];
      rolesData.forEach((r, i) => {
        let roleUsers = r.users.filter((u,i) => i<4).map(u => (`${u.first_name} ${u.last_name}`));
        let userString = ``;
        roleUsers.forEach((u, i) => {
          userString = `${userString}${u}`;
          if(i < (roleUsers.length-1))
            userString = `${userString}, `
          if(roleUsers.length < r.users.length)
            userString = `${userString}, ...`
        });
        parsedRoles.push({
        //  ...r.role, <Badge className="pending" size="sm">...</Badge>
          name : <Link to={`/roles/${r.id}`}>{r.name}</Link>,
          assigned_to : <>
                          {r.users.map((u,i) => (
                            <Link key={`user-link-${u.id}`} to={`/users/${u.id}`} style={{margin : '0 0.3em 0 0'}}>
                              <Badge size="sm">{u.first_name} {u.last_name}</Badge>
                            </Link>
                          ))}


                        </>,
          edit: <>
                  <AssignRoleModal
                    setUpdate={setUpdate}
                    role={r}
                    rolesData={rolesData}
                    parseRoles={parseRoles} />
                  <Link to={`/roles/${r.id}`}>
                    <Icon.Gear />
                  </Link>
                  <PermissionsGate permissions={['edit_permissions']}>
                    <ConfirmDeleteModal
                     handleDelete={() => handleDelete(r.id)}
                     title="Supprimer rol"
                     message={`Vous souhaitez supprimer le rol ${r.name}?`} />
                  </PermissionsGate>
                </>
        });
      });
      setRoles(parsedRoles);
    };
    const handleDelete = (id) => {
      apiRoles.remove(id).then(data => {
        if(data.hasOwnProperty('error')) {
          setError(data.error);
          return data;
        }
        setUpdate(true);
      });
    }

    apiRoles.getAll().then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      return data;
    }).then(rolesData => {
      if(active) {
        parseRoles(rolesData);
        setUpdate(false);
      }
    });


    return () => {
      active = false;
    };
  },[token, update]);









  return (
    <>
      <Table
        className="manageTable"
        initialState={{
          hiddenColumns : ['priority'],
        }}
        data={roles}
        columns={[{
          Header: 'Name',
          accessor: 'name',
          sortType : 'basic',
          width: 150,
        },{
          Header: 'Priority',
          accessor: 'priority',
          sortType : 'basic',
        },{
          Header: 'Assigned to',
          accessor: 'assigned_to',
          disableSortBy : true,
          width: 300,
        },{
          Header: 'Edit',
          accessor: 'edit',
          disableSortBy : true,
          width : 140,
        }]}/>
        <AddRoleFormModal setUpdate={setUpdate} />
      </>
  )
}

export default RolesTable;
