import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button'





function HttpStatusError ({code, message}) {
  return(
      <div className={`http-status-error http-status-error-${code}`}>
        <h2>{code}</h2>
        {message}<br /><br />
        <Link to="/">
          <Button size="sm" variant="outline-light">
            Retourner à {process.env.REACT_APP_WEBSITE_NAME}
          </Button>
        </Link>
      </div>
  )

}


export default HttpStatusError
