import React, { useState, useEffect, useRef } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import * as apis from './services/api/utilities';

function EntityTags({api, id, initial = []}) {
  const [error, setError] = useState(false);
  const [tags, setTags] = useState(initial);
  const [highlight, setHighlight] = useState();
  const inputRef = useRef();

  const handleDelete = (tag) => {
    setTags(tags.filter(t => t !== tag));
    api.removeTag({id,tag});
  }

  const handleChange = () => {
    let text = inputRef.current.value;
    let tag;
    // IF ENTER COMMA, ADD TEXT BEFORE COMMA AS NEW TAG, CLEAR
    if(text.includes(',')) {
      tag = text.split(',')[0].trim();
      inputRef.current.value = text.split(',')[1].trimStart();
      addTag(tag);
    }
  }

  const addTag = (tag) => {
    if(!tags.includes(tag)) {
      api.assignTag({id, tag});
      setTags([...tags,tag]);
    } else {
      highlightTag(tag);
    }
  }

  const highlightTag = (tag) => {
    setHighlight(tags.indexOf(tag));
    setTimeout(() => setHighlight(), 2000);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter') {
      let tag = inputRef.current.value.trim();
      inputRef.current.value = '';
      addTag(tag);
    }
  }

  useEffect(() => {
    let active = true;
    if(!api.hasOwnProperty('getTags')
      || !api.hasOwnProperty('assignTag')
      || !api.hasOwnProperty('assignTags')
      || !api.hasOwnProperty('removeTag')) {
        if(active) throw(`Provided api does not support tags`);

    } else {

      api.getTags({id}).then(tagsData => setTags(tagsData));

    }

    return () => {
      active = false;
    }

  }, [api]);

  if(error) return (null);

  return(
    <div className="entity-tags">
      <a className="tag-icon" onClick={() => inputRef.current.focus()}><Icon.TagFill /></a>
      {tags.length > 0 ?
        <ul className="tag-list">
          {tags.map((tag,i) => (
            <li key={`entity-tag-${i}`}>
              <Badge className={`${highlight === i ? 'tag-highlight ' : ''}`}>
                {tag} <Icon.X onClick={() => handleDelete(tag)}/>
              </Badge>
            </li>
          ))}
        </ul>
      : null}
      <input
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        type="text"
        name="new-tag" />
    </div>

  )
}

export default EntityTags;
