import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Icon from 'react-bootstrap-icons'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import rsTop from './images/RPAsimple_carts.png'
import rsTopSmall from './images/RPAsimple.png'


function AppHeader () {
  return (
    <Row>
      <Col md="4" xs="0"></Col>
      <Col md="4" sm="8" xs="12" style={{textAlign : 'center'}}>
        <img className="topRPALogo" src={rsTop} alt="RPAsimple" />
        <img className="topRPALogoSmall" src={rsTopSmall} alt="RPAsimple" />
      </Col>
      <Col sm="4" xs="12" style={{textAlign : 'right'}}>
      </Col>
    </Row>
  )
}



export default AppHeader
