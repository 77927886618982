const initialState = {
  tableState : {
    sortBy : [{id : "bill_no"}],
    usePagination : true,
    pageIndex : 0,
    pageSize : 30,
  }
};

const billReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BILL_TABLE_STATE':
      return { ...state, tableState : {...state.tableState, ...action.payload}};
    case 'RESET_ALL':
      return initialState;
    default:
      return state;
  }
}

export default billReducer;
