import React, {useState, useEffect, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Icon from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import {Form} from 'react-bootstrap';
import {apiProducts, apiProductPrices, apiArticles} from '../services/api/utilities';
import ArticlesForm from '../ArticlesForm';
import ArticleEdit from '../ArticleEdit';
/* Provided 'products' objects must be given in an array, depending on given parameters various prompts are shown:
/ confirm (if name is unique)
- shows form (name, quantity, tags, taxonomy)
- confirm? skip?

/ merge (if name is not unique)
- show/select from products with same name
-

/ identify (if more than one id suggestion are given)

/ manual (if no suggestions are given, no name) */
function ConfirmProductModal({products, onCreate, onMerge,}) {
	const [newProducts, setNewProducts,] = useState(products);

	const [allProducts, setAllProducts,] = useState([]);
	const [isSubmit, setIsSubmit,] = useState(false);
	const [currentIndex, setCurrentIndex,] = useState(
		newProducts.length
		? 0
		: null);
	const currentProduct = newProducts[currentIndex];
	const articles = useRef(products.map(p => ({pv: p.pv, pa: p.pa})));

	const [show, setShow,] = useState(true);
	const ProductThumbnail = ({product}) => {
		let image = product.images.length
			? product.images[0]
			: {
				url: 'blank'
			};
		return (<div className="product-thumbnail" style={{
				backgroundImage: `url(${image.path || image.thumbnail_path})`
			}} alt={product.name}></div>);
	};
	const IdentifyProductThumbnail = ({suggestion}) => {
		let image = suggestion.plant_details.wiki_images
			?.length
				? suggestion.plant_details.wiki_images[0]
				: null;
		let style = image
			? {
				backgroundImage: `url(${image.value})`,
				backgroundSize: 'contain'
			}
			: {};
		return (<> < div className = "product-suggestion-thumbnail" style = {
			style
		}
		alt = {
			suggestion.plant_name
		} > </div>
	<span>
			{suggestion.plant_name} < /span></ >);
	};


	const updateCurrentProduct = (product) => {
		setNewProducts(newProducts.map((p, i) => (
			i !== currentIndex
			? p
			: product)));
	}

	const updateCurrentArticle = (article) => {
		articles.current = (articles.current.map((a, i) => (
			i !== currentIndex
			? a
			: article)));
	}
	const handleClose = (e) => {
		// setNewProducts([]);
		setShow(false);
	}
	const handleIdentify = (e) => {
		e.preventDefault();
		// DEFAULT in case no suggestion was selected
		let suggestion = { plant_name : '[Plante]'};
		let suggestionIndex = e.target.elements.suggestion.value;
		if(currentProduct.suggestions[suggestionIndex] !== undefined)
			suggestion = currentProduct.suggestions[suggestionIndex];
		updateCurrentProduct({
			...currentProduct,
			name: suggestion.plant_name
		});
	}
	const handleUnidentify = (e) => {
		e.preventDefault();
		updateCurrentProduct({
			...currentProduct,
			name: ''
		});
	}
	const handleCustom = (e) => {
		e.preventDefault();
		updateCurrentProduct({
			...currentProduct,
			name : '[Plante]'
		})
	}

	const SuggestionCheckboxCol = ({
		index,
		suggestionsPerRow,
		suggestion,
		selectOther = false,
		rowIndex
	}) => {
		return (
			<Col key={`product-suggestion-${index}`}>
				<Form.Check
					key={`product-suggestion-${ (rowIndex * suggestionsPerRow) + index}`}
					value={(rowIndex * suggestionsPerRow) + index}
					className={'product-suggestion'}
					name={'suggestion'}
					type='radio'
					defaultChecked={rowIndex === 0 && index === 0}
					label={selectOther ? 'Autre' : <IdentifyProductThumbnail suggestion = {suggestion} />}
					id={`product-suggestion-${ (rowIndex * suggestionsPerRow) + index}`}/>
			</Col>
		)
	}

 	const IdentifyProduct = ({product, suggestions,}) => {
		const spr = 2; // suggestions per row
		const suggestionRows = [];
		for (let rowIndex = 0; rowIndex < Math.ceil(suggestions.length / spr); rowIndex++) {
			suggestionRows.push(<Row key={`product-suggestion-row-${rowIndex}`}>
				{
					suggestions
						.slice(rowIndex * spr, (rowIndex * spr) + spr)
						.map((suggestion, i) => (
							<SuggestionCheckboxCol
							 	index={i}
								rowIndex={rowIndex}
								suggestionsPerRow={spr}
								suggestion={suggestion} />
						)
					)
				}
			</Row>)
		}
		// Last checkbox: No suggestion applies
		suggestionRows.push(
			<SuggestionCheckboxCol
				index={suggestions.length}
				rowIndex={suggestionRows.length}
				selectOther={true}
				suggestionsPerRow={spr} />
			);
		return (<Form name="identify-product" onSubmit={handleIdentify}>
			<Row>
				<Col><ProductThumbnail product={product}/></Col>
			</Row>
			{suggestionRows}
			<Row className="modal-form-controls">
				<Col>
					<Button type="submit">Confirmer</Button>
				</Col>
			</Row>
		</Form>);
	}
	const ConfirmProduct = ({product}) => {
		updateCurrentArticle({
			id : 'new',
			pa : 0,
			pv : 0,
			size: 15,
			type : 'blur',
			...articles.current[currentIndex]
		});

		return (<Form name="confirm-product" onSubmit={(e) => {
				e.preventDefault();
				let currentArticle = articles.current[currentIndex];
				// INSERT NEW PRODUCT INTO DB - GET ID
				apiProducts.post({products : [product]}).then(data =>  {
					if(data.hasOwnProperty('error')) {
						return data;
					}
					let newProduct = {...product, ...data[0]};
					updateCurrentProduct(newProduct);
					// PASS PRODUCT ID TO ARTICLE, TO ASSIGN
					apiArticles.putMultiple({
		        articles : [{...currentArticle, product_id : newProduct.id, id : null}]
		      }).then((articles) => {
							updateCurrentArticle(articles[0]);
							handleNewProduct(newProduct);
					});

				});

				return true;

			}}>
			<Row>
				<Col><ProductThumbnail product={product}/></Col>
				<Col>
					<Form.Group>
						<Form.Label>Nom</Form.Label>
						<Form.Control type="text" name="name" defaultValue={product.name}/>
					</Form.Group>

					<ArticleEdit
						article={articles.current[currentIndex]}
						onUpdate={(article) => (updateCurrentArticle(article))}
						allowDelete={false}
						layout="mini" />
				</Col>
			</Row>
			<Row className="modal-form-controls">
				<Col>
					<Button type="submit">Confirmer</Button>
					{
						product.suggestions.length > 1
							? <Button variant="link" onClick={handleUnidentify}>Autre choix</Button>
							: null
					}
				</Col>
			</Row>
		</Form>);
	}
	const MergeProduct = ({product}) => {
		const [name, setName,] = useState(product.name);
		const homonym = allProducts.find(p => p.name.toLocaleLowerCase().trim() === product.name.toLocaleLowerCase().trim()); // suggestions per row

		return (<Form name="confirm-product" onSubmit={(e) => {
				e.preventDefault();
			}}>
			<Row>
				<Col><ProductThumbnail product={product}/></Col>
				<Col><ProductThumbnail product={homonym}/></Col>
			</Row>
			<Row>
				<Col>
					<Form.Group>
						<Form.Label>Nom</Form.Label>
						<Form.Control type="text" onChange={(e) => {
								setName(e.target.value);
							}} name="name" defaultValue={product.name}/>
					</Form.Group>
				</Col>
			</Row>
			<Row className="modal-form-controls">
				<Col>
					<Button onClick={() => handleAddImage(homonym.id, product)}>Ajouter image</Button>
					<Button variant="link" onClick={() => updateCurrentProduct({
							...product,
							name,
						})} disabled={name.toLocaleLowerCase().trim() === homonym.name.toLocaleLowerCase().trim()}>Nouveau produit</Button>
					{
						product.suggestions.length > 1
							? <Button variant="link" onClick={handleUnidentify}>Autre choix</Button>
							: null
					}
				</Col>
			</Row>
		</Form>);
	}
	const handleAddImage = (id, product) => {
		onMerge({...product, id});
		let newList = newProducts.filter((p, i) => i !== currentIndex);
		if (newList.length >= currentIndex)
			setCurrentIndex(0);
		setNewProducts(newList);
	}
	const handleNewProduct = (product) => {
		onCreate(product, articles.current[currentIndex]);
		let newList = newProducts.filter((p, i) => i !== currentIndex);
		if (newList.length >= currentIndex)
			setCurrentIndex(0);
		setNewProducts(newList);
	}
	const getProductMode = (product) => {
		product = {
			suggestions: [],
			name: '',
			...product
		};
		switch (true) {
				// If product name is unique
			case product.name !== '' && allProducts.filter(p => p.name.toLocaleLowerCase().trim() === product.name.toLocaleLowerCase().trim()).length === 0:
				return 'CONFIRM';
				// If product name is not unique
			case product.name !== '' && allProducts.filter(p => p.name.toLocaleLowerCase().trim() === product.name.toLocaleLowerCase().trim()).length >= 1:
				return 'MERGE';
				// If more than one suggestion remain
			case product.suggestions.length > 1:
				return 'IDENTIFY';
				// If no suggestions and no name are given
			case product.suggestions.length === 0 && product.name === '':
				return 'MANUAL';
				// Else
			default:
				return 'NONE';
		}
	}
	const getModalProps = (product) => {
		switch (getProductMode(product)) {
			case 'CONFIRM':
				return {header: "Est-ce correct?"};
			case 'MERGE':
				return {header: "Ajouter l'image à un produit existant ou créer un nouveau produit ?"};
			case 'IDENTIFY':
				return {header: "Quelle est cette plante ou cette fleur ?"};
			case 'MANUAL':
				return {header: "Aucune fleur ou plante identifiable : qu'est-ce que c'est ?"};
		}
	}
	// MOVE OUT
	// const handleSubmit = (product) => {
	//   if(allProducts.filter(p => p.id === product.id))
	//     apiProducts.patch(product.id,product).then(data => onConfirm(data));
	//   else
	//     apiProducts.post(product.id,product).then(data => onConfirm(data));
	// }
	useEffect(() => {
		apiProducts.getAll().then(p => {
			setAllProducts(p);
		});
	}, []);
	useEffect(() => {
		setNewProducts(products);
		setCurrentIndex(0);
		setShow(true);
		articles.current = products.map(p => ({pv: p.pv, pa: p.pa}));
	}, [products]);
	if (!currentProduct || !allProducts.length)
		return (null);
	const {header} = getModalProps(currentProduct);
	return (<Modal className="confirm-product-modal" show={show} onHide={handleClose}>
		<Modal.Header>{header}</Modal.Header>
		<Modal.Body>
			{
				getProductMode(currentProduct) === 'IDENTIFY'
					? <IdentifyProduct product={currentProduct} suggestions={currentProduct.suggestions}/>
					: null
			}
			{
				getProductMode(currentProduct) === 'CONFIRM'
					? <ConfirmProduct product={currentProduct} suggestions={currentProduct.suggestions}/>
					: null
			}
			{
				getProductMode(currentProduct) === 'MERGE'
					? <MergeProduct product={currentProduct}/>
					: null
			}
		</Modal.Body>
		{
			newProducts.length > 1
				? <Modal.Footer>
						<Button variant="link" onClick={() => setCurrentIndex(currentIndex - 1)} disabled={currentIndex === 0}>Prev</Button>
						<Button variant="link" onClick={() => setCurrentIndex(currentIndex + 1)} disabled={currentIndex === newProducts.length - 1}>Next</Button>
						{`${currentIndex + 1}/${newProducts.length}`}
					</Modal.Footer>
				: null
		}
	</Modal>);
}
// {
// `${currentIndex + 1}/${newProducts.length}`,
//}



export default ConfirmProductModal
