import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Link, useNavigate } from 'react-router-dom'


// Route Components
import Login from './Login'
import ResetPassword from './ResetPassword'


import useToken from './hooks/useToken'



function AppOfflineRoutes () {
  const [ mailPlaceholder, setMailPlaceholder ] = useState('');
  const { setToken } = useToken();

  return (
    <Routes>
      <Route path="*" index element={<Login mailPlaceholder={mailPlaceholder} setToken={setToken} />} />
      <Route path="/reset" element={<ResetPassword setMailPlaceholder={setMailPlaceholder} />}>
        <Route path=":reset_hash" element={<ResetPassword setMailPlaceholder={setMailPlaceholder} />} />
      </Route>
    </Routes>
  )
}

export default AppOfflineRoutes
