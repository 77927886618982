import React, { useEffect, useState, useRef } from 'react'
import FlexTable from './Table'
import * as Icon from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import currency from 'currency.js'


import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom'
import { apiArticles } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import PermissionsGate from './PermissionsGate'
import EntityTags from './EntityTags'

const imageRoot=`${process.env.REACT_APP_IMAGES}/`;
const thumbnailRoot=imageRoot + "thumbnails/";

const currencyFormat = {
  groupSeparator : ', ',
  group : ', ',
  decimalSeparator : '.',
  decimal : '.',
  prefix : '€ ',
  symbol : '€ ',
  fixedDecimalLength : 2,
};




function ArticlesSelector ({
  onSubmit = (fieldArticles) => {},
  articlesFilter = (article) => true}
) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [articles, setArticles] = useState([]);
  const [fieldArticles, setFieldArticles] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    let active = true;
    apiArticles.getAll().then(data => {
      setArticles(data);
      setLoading(false);
    });
    return () => {
      active = false;
    }
  },[]);

  const handleChange = (fieldArticles) => {
    setFieldArticles([...fieldArticles.map(article => ({...article, quantity: 1}))]);
  }
  const handleSubmit = (fieldArticles) => {
    onSubmit(fieldArticles);
    setFieldArticles([]);
  }

  const filterSelected = (article) =>
    !fieldArticles.map(a => (a.id)).includes(article.id);

  const renderMenu= (results, menuProps) => {
    return (
      <Menu {...menuProps}>

      {results.map((result, index) => (
          <MenuItem option={result} position={index}>
            {renderArticleLabel(result)}

          </MenuItem>
      ))}
    </Menu>
    )
  }

  const renderArticleLabel = ({ product_name, size, images, pv, pa}) => {
    return (
      <div className="articles-selector-label">
        {!images.length ? null :
            <div className="articles-selector-label-col asl-col-0">
              <img src={images[0].thumbnail_path} />
            </div>

        }
        <div className="articles-selector-label-col asl-col-1">{product_name} ({size} cm)</div>
        <div className="articles-selector-label-col asl-col-2">{currency(pv, currencyFormat).format()}</div>
        <div className="articles-selector-label-col asl-col-3">{currency(pa, currencyFormat).format()}</div>
      </div>
    )
  }


  if(loading || !articles.length) return "...";

  return (
    <div>
        <Typeahead
          className="article-selector"
          id={`article-selector-${Math.random()}`}
          onChange={(c) => handleChange(c)}
          labelKey="label"
          emptyLabel={`Article non trouvable`}
          allowNew={false}
          clearButton={true}
          maxHeight={'40vh'}
          options={articles
              .filter(filterSelected)
              .filter(articlesFilter)
              .map((article) => ({
                ...article,
                label: article.product_name
              }))

          }
          renderMenu={renderMenu}
          placeholder="Chercher des articles"
          selected={fieldArticles}
          multiple={true}
        />
        {!fieldArticles.length ? null : <Button
          className="btn-secondary"
          onClick={() => handleSubmit(fieldArticles)}>
          Ajouter selection
        </Button>}
    </div>
  )

}

export default ArticlesSelector

// renderMenu={renderMenu}
