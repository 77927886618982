export function handleResponse(response) {
  if(!response.hasOwnProperty('data')) {
    console.warn('Error: Malformed response', response);
    return { error : "Malformed response" };
  }
  if(response.data){
    return response.data;
  }
  return response;
}

export function handleError(error) {
  if(error.response) {
    console.warn("Error: ", error.response.status, error.response.statusText);
    return {
      error : error.response.statusText,
      status : error.response.status,
      responseData : error.response.data,
    };
  }
  if (error.message) {
    return {error : error.message};
  }
}
