import {
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import {
	useSelector,
	useDispatch,
} from 'react-redux';
import useToken from './hooks/useToken';
import * as apis from './services/api/utilities';

// const preloadInit = [{
//   name : 'apiProducts',
//   idProp : 'id',
//   getAll : 'getAll',
//   fnCalls : ['getImages'],
//   weight: 40,
// }];
const preloadInit = [ {
	name: 'apiUsers',
	idProp: 'id',
	getAll: 'getAll',
	fnCalls: [ 'getRoles' ],
	weight: 10
}, {
	name: 'apiRoles',
	idProp: 'id',
	getAll: 'getAllPrivate',
	fnCalls: [
		'getPermissions', 'getUnusedPermissions', 'getAll',
	],
	weight: 10
}, {
	name: 'apiPermissions',
	idProp: 'id',
	getAll: 'getAll',
	fnCalls: [],
	weight: 10
}, {
	name: 'apiTables',
	idProp: 'accessor',
	getAll: 'getAll',
	fnCalls: [
		'getPlotly', 'getData',
	],
	weight: 40,
	// },{
	//   name : 'apiProducts',
	//   idProp : 'id',
	//   getAll : 'getAll',
	//   fnCalls : ['getImages'],
	//   weight: 40,,,,
}, ];

function ApiPreloader() {
	const {
		token,
		verifyToken,
	} = useToken();
	// const preloadBar = useRef(null);
	const preloadState = useSelector( state => ( state.interface.preload ) );
	let preload = preloadInit;
	let counter = 0;

	const preloadBar = useCallback( preloadBar => {
		if ( preloadBar === null )
			return false;
		let initloadArray = [];
		let preloadArray = [];
		let fullWeight = preload.length;

		const updateProgress = ( c, fW ) => {
			let barStyle = preloadBar.style;
			barStyle.width = `${ ( c / fW ) * 100 }vw`;
			if ( c >= fW ) {
				// barStyle.opacity = 0;
				setTimeout( () => {
					barStyle.opacity = 0
				}, 1000 );
			}

		}

		// TODO
		// Whenever the user is idle
		// - another promise in the preload queue
		// - is fulfilled every x seconds
		// - until she is active again
		verifyToken().then( valid => {
			if ( !valid )
				return false;

			preload.forEach( ( {
				name,
				getAll,
				idProp,
				fnCalls,
			}, i ) => {
				let preloadApi = apis[ name ];

				initloadArray.push( preloadApi[ getAll ]().then( ( data ) => {
					//
					let fnCount = data.length * ( 1 + fnCalls.length );
					fullWeight += fnCount;
					data.forEach( ( item, i ) => {
						preloadArray.push( preloadApi.getSingle( item[ idProp ] ) );
						fnCalls.forEach( ( fn, i ) => {
							preloadArray.push( preloadApi[ fn ]( {
								[ idProp ]: item[ idProp ]
							} ) );
						} );
					} );
				} ) );
			} );

			initloadArray.map( p => p.then( () => updateProgress( ++counter, fullWeight ) ) );
			Promise.all( initloadArray ).then( () => {
				preloadArray.map( p => p.then( () => updateProgress( ++counter, fullWeight ) ) );
				Promise.all( preloadArray ).then( () => console.log( 'preload complete' ) );
			} );

		} );
	}, [ token ] );

	return ( <div ref={preloadBar} style={{
			width: `0vw`
		}} className="preload-progress"></div> )
}

export default ApiPreloader;