import React from 'react';
import { useTable, useSortBy, useBlockLayout, useFilters } from 'react-table';


function Table({data, columns, initialState, className}) {

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      setFilter,
      rows,
      prepareRow
    } = useTable({ columns, data, initialState },
      useFilters,
      useSortBy,
      useBlockLayout,
    );


  return(
    <table className={className} {...getTableProps()}>
     <thead>
       {headerGroups.map(headerGroup => (
         <tr {...headerGroup.getHeaderGroupProps()}>
           {headerGroup.headers.map(column => (
             <th
               {...column.getHeaderProps(column.getSortByToggleProps())}
             >
               {column.render('Header')}
               <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
             </th>
           ))}
         </tr>
       ))}
     </thead>

     <tbody {...getTableBodyProps()}>
       {rows.map(row => {
         prepareRow(row)
         return (
           <tr {...row.getRowProps()}>
             {row.cells.map(cell => {
               return (
                 <td {...cell.getCellProps()}>
                   {cell.render('Cell')}
                 </td>
               )
             })}
           </tr>
         )
       })}
     </tbody>
   </table>
  );
}

export default Table;
