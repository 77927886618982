import { ApiCore } from './core.js';
import { apiProvider } from './provider.js';
import { handleResponse, handleError } from './response';


const apiSession = new ApiCore({
  getSingle: true,
  url: 'session',
  single: 'session',
  cache: false,
  custom : {
    login : ['login','basicAuth'],
    logout : ['logout','get']
  }
});

const apiPermissions = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: true,
  url: 'permissions',
  plural: 'permissions',
  single: 'permission'
});



const apiRoles = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: true,
  url: 'roles',
  plural: 'roles',
  single: 'role',
  custom : {
    getAllPrivate : ['roles/all','get'],
    getUsers : ['role/{id}/users','get'],
    getPermissions : ['role/{id}/permissions','get'],
    getUnusedPermissions : ['role/{id}/unused-permissions','get'],

    assignUser : ['role/{id}/user','post'],
    removeUser : ['role/{id}/user/{user_id}','delete'],
    assignUsers : ['role/{id}/users','post'], // (array) assign, (array) remove

    assignPermission : ['role/{id}/permission','post'],
    removePermission : ['role/{id}/permission/{rel_id}','delete'],
  }

});

const apiUsers = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  patch: true,
  remove: true,
  url: 'users',
  plural: 'users',
  single: 'user',
  cache : false,
  custom : {
    getRoles : ['user/{id}/roles', 'get'],
    forgotPassword : ['forgot/{mail}', 'get'],
    resetPassword : ['reset/{hash}', 'post'], // password
    getPersist : ['persist','get'],
    makePersist : ['persist','put'],
    deletePersist : ['persist','delete'],
  }
});

const apiTables = new ApiCore({
  getAll: true,
  getSingle: true,
  url: 'tables',
  plural: 'tables',
  single: 'table',
  cache : false,
  custom: {
     getPlotly : ['table/{accessor}/plotly', 'get'],
     getData : ['table/{accessor}/data','get'],
     getArchive : ['table/{accessor}/archive/{index}', 'get']
   }
});

const apiSpreadsheet = new ApiCore({
  getAll: false,
  getSingle: false,
  post: false,
  put: false,
  patch: false,
  remove: false,
  download: true,
  url: 'xlsx',
  plural: 'xlsx',
  single: 'xlsx'
});

const apiProducts = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  patch: true,
  remove: true,
  url: 'products',
  plural: 'products',
  single: 'product',
  cache : true,
  custom : {
    getImages : ['product/{id}/images', 'get'],
    uploadImage : ['product/{id}/images', 'upload'],
    getImage : ['product/{id}/image/{index}', 'get'],
    editImage : ['product/{id}/image/{index}', 'patch'],
    deleteImage : ['product/{id}/image/{index}', 'delete'],
    getTags : ['product/{id}/tags','get'],
    assignTags : ['product/{id}/tags','post'],
    assignTag : ['product/{id}/tag/{tag}','put'],
    removeTag : ['product/{id}/tag/{tag}','delete'],
    assignPrice : ['product/{id}/price/{priceId}','put'],
    removePrice : ['product/{id}/price/{priceId}','delete'],
    getArticles : ['product/{id}/articles','get'],
  }
});

const apiProductCategories = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  patch: true,
  remove: true,
  url: 'product-categories',
  plural: 'product-categories',
  single: 'product-category',
  cache : true,
  custom : {}
});

const apiProductPrices = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  patch: true,
  remove: true,
  url: 'product-prices',
  plural: 'product-prices',
  single: 'product-price',
  cache : true,
  custom : {
    assignProducts : ['product-price/{id}/products','post'],
    assignProduct : ['product-price/{id}/product/{productId}','put'],
    removeProduct : ['product-price/{id}/product/{productId}','delete'], // NOT IMPLEMENTED ON API END YET
  }
});

const apiGeography = new ApiCore({
  getAll: true,
  getSingle: true,
  url: 'countries',
  plural: 'countries',
  single: 'country',
  cache : true
});

const apiClients = new ApiCore({
  getAll: true,
  getSingle: true,
  patch : true,
  remove : true,
  post: true,
  url: 'clients',
  plural: 'clients',
  single: 'client',
  custom : {
    addAddress : ['client/{id}/address', 'post'],
    getAddresses : ['client/{id}/addresses', 'get'],
    getAddress : ['client/{id}/address/{index}', 'get'],
    editAddress : ['client/{id}/address/{index}', 'patch'],
    deleteAddress : ['client/{id}/address/{index}', 'delete'],
    // getTags : ['client/{id}/tags','get'],
    // assignTags : ['client/{id}/tags','post'],
    // assignTag : ['client/{id}/tag/{tag}','put'],
    // removeTag : ['client/{id}/tag/{tag}','delete'],
  }
});

const apiContacts = new ApiCore({
  getAll: true,
  getSingle: true,
  patch : true,
  remove : true,
  post: true,
  cache : true,
  url: 'contacts',
  plural: 'contacts',
  single: 'contact'
});

const apiCompanies = new ApiCore({
  getAll: true,
  getSingle: true,
  patch : true,
  remove : true,
  post: true,
  cache: true,
  url: 'companies',
  plural: 'companies',
  single: 'company'
});

const apiArticles = new ApiCore({
  getAll: true,
  getSingle: true,
  patch : true,
  remove : true,
  post: true,
  cache: true,
  url: 'articles',
  plural: 'articles',
  single: 'article',
  custom : {
    putMultiple : ['articles', 'put'],
  }
});

const apiBills = new ApiCore({
  getAll: true,
  getSingle: true,
  patch : true,
  remove : true,
  post: true,
  // download : true,
  cache: false,
  url: 'bills',
  plural: 'bills',
  single: 'bill',
  custom : {
    'getArticles' : ['bill/{id}/articles', 'get'],
    'assignArticles' : ['bill/{id}/articles', 'put'], // { article_bill_rel : [...]}
    'getPdf' : ['bill/{id}/pdf', 'get']
  }
});



console.log('apiSession',apiSession);
console.log('apiPermissions',apiPermissions);
console.log('apiRoles',apiRoles);
console.log('apiUsers',apiUsers);
console.log('apiTables',apiTables);
console.log('apiSpreadsheet',apiSpreadsheet);
console.log('apiProducts',apiProducts);
console.log('apiProductPrices',apiProductPrices);
console.log('apiProductCategories',apiProductCategories);
console.log('apiGeography',apiGeography);
console.log('apiClients',apiClients);
console.log('apiContacts',apiContacts);
console.log('apiCompanies',apiCompanies);
console.log('apiArticles',apiArticles);
console.log('apiBills',apiBills);

export {
  ApiCore,
  apiProvider,
  handleResponse,
  handleError,

  apiSession,
  apiPermissions,
  apiRoles,
  apiUsers,
  apiTables,
  apiSpreadsheet,
  apiProducts,
  apiProductCategories,
  apiProductPrices,
  apiGeography,
  apiClients,
  apiContacts,
  apiCompanies,
  apiArticles,
  apiBills
};
