import { apiProvider } from './provider';
import storeSetup from '../../../app/store';

function toObservable(store) {
  return {
    subscribe({ onNext }) {
      let dispose = store.subscribe(() => onNext(store.getState()));
      onNext(store.getState());
      return { dispose };
    }
  }
}

export class ApiCore {

  getToken() {
    this.token = this.store.getState().token;
    return this.token;
  }

  clearCache() {
    this.cache.clear();
  }

  cacheResponse({cacheId, res}) {
    if(!this.cache.has(cacheId)) {
      this.cache.set(cacheId,res());
    }
    return this.cache.get(cacheId);
  }

  constructor(options) {

    // Set defaults
    options = {
      getAll: false,
      getSingle: false,
      post: false,
      put: false,
      patch: false,
      delete: false,
      download: false,
      login: false,
      cache : true,
      ...options };

    this.token = "";
    this.store = (storeSetup()).store;
    this.cache = new Map;

    if(options.custom) {
      let customFns = Object.keys(options.custom);
      customFns.forEach((customKey) => {
        this[customKey] = (model) => {
          this.getToken();
          let methodClearCache = ['post','put','patch','delete','upload'].includes(options.custom[customKey][1]);
          if(!options.cache || methodClearCache) this.clearCache();
          return this.cacheResponse({
            cacheId: customKey + JSON.stringify(model) + this.token,
            res: () => apiProvider.custom(options.custom[customKey], model, this.token)
          });
        //  return apiProvider.custom(options.custom[customKey], model, this.token);
        }
      });

    }



    if (options.getAll) {
      this.getAll = () => {
        this.getToken();
        if(!options.cache) this.clearCache();
        return this.cacheResponse({
          cacheId : options.plural + this.token,
          res : () => apiProvider.getAll(options.plural, this.token)
        });
      };
    }

    if (options.getSingle) {
      this.getSingle = (id) => {
        this.getToken();
        if(!options.cache) this.clearCache();
        return this.cacheResponse({
          cacheId : options.single + id + this.token,
          res : () => apiProvider.getSingle(options.single, id, this.token)
        });
      };
    }


    if (options.post) {
      this.post = (model) => {
        this.getToken();
        this.clearCache();
        return apiProvider.post(options.url, model, this.token);
      };
    }

    if (options.put) {
      this.put = (model) => {
        this.getToken();
        this.clearCache();
        return apiProvider.put(options.single, model, this.token);
      };
    }

    if (options.patch) {
      this.patch = (id, model) => {
        this.getToken();
        this.clearCache();
        return apiProvider.patch(options.single, id, model, this.token);
      };
    }

    if (options.remove) {
      this.remove = (id) => {
        this.getToken();
        this.clearCache();
        return apiProvider.remove(options.single, id, this.token);
      };
    }

    if (options.download) {
      this.download = (model) => {
        this.getToken();
        return apiProvider.download(options.url, model, this.token);
      };
    }

    if (options.login) {
      this.login = (model) => {
        this.getToken();
        return apiProvider.basicAuth(options.url, model);
      };
    }
  }
}
