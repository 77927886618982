import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from './Table'
import * as Icon from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import moment from 'moment'

import { apiBills } from './services/api/utilities'
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal'
import AddBillModal from './Modals/AddBillModal'
import PermissionsGate from './PermissionsGate'

import TableContext, {Consumer} from './TableContext'
import ReactTable from './ReactTable'
import GlobalFilter from './GlobalFilter'

const currencyFormat = {
  groupSeparator : ', ',
  decimalSeparator : '.',
  symbol : '€ ',
  fixedDecimalLength : 2,
};


function BillsOverview () {
  const [bills, setBills] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [showNewBill, setShowNewBill] = useState(false);
  const dispatch=useDispatch();

  const initialState = useSelector(state => state.bills.tableState);


  let table = {
    data : [...bills],
    initialState,
    columns : React.useMemo(() => (
    [{
      Header: 'Facture #',
      accessor: 'bill_no',
      canGroupBy : false,
      sortType : 'alphanumeric',
      width : 120,
      Cell: ({cell, row}) => {
        return <Link to={`/bill/${row.original.id}`}>{cell.value}</Link>
      },
      Aggregated : () => (null)
    },{
      Header: 'Client',
      accessor: 'client_name',
      sortType : 'alphanumeric',
      canGroupBy : true,
      width: 220,
      Cell : ({cell}) => {
        return cell.value
      },
      // Placeholder : ({cell, row}) => {
      //   return <Link to={`/bill/${row.original.id}`}>{row.original.name}</Link>
      // }
    },{
      Header: 'Total QTÉ',
      accessor: 'total_qte',
      canGroupBy : true,
      aggregate : (leafValues, aggregatedValues) => {
        return aggregatedValues.reduce((total,prev) => (parseInt(total)+parseInt(prev)),0)
      },
      sortType : 'alphanumeric',
      width: 120,
    },{
      Header: 'Total PV',
      accessor: 'total_pv',
      canGroupBy : true,
      aggregate : 'sum',
      sortType : 'alphanumeric',
      Cell: (({cell}) => (currency(cell.value, currencyFormat).format())),
      aggregate : (leafValues, aggregatedValues) => {
        return aggregatedValues.reduce((total,prev) => (currency(total,currencyFormat).add(prev)),0)
      },
      width: 120,
    },{
      Header: 'TOTAL PA',
      accessor: 'total_pa',
      canGroupBy : true,
      aggregate : 'sum',
      sortType : 'alphanumeric',
      Cell: (({cell}) => (currency(cell.value, currencyFormat).format())),
      aggregate : (leafValues, aggregatedValues) => {
        return aggregatedValues.reduce((total,prev) => (currency(total,currencyFormat).add(prev)),0)
      },
      Aggregated: (({cell}) => (currency(cell.value, currencyFormat).format())),
      width: 120,
    },{
      Header: 'Entreprise',
      accessor: 'company_name',
      canGroupBy : true,
      sortType : 'alphanumeric',
      width: 120,
    },{
      Header: 'Date crée',
      accessor: 'date_created',
      Cell : ({ cell }) => { return cell.value },
      canGroupBy : false,
      sortType : 'basic',
      width: 120
    },{
      Header: 'Edit',
      accessor: 'edit',
      disableSortBy : true,
      canGroupBy : false,
      width : 120,
      Aggregated : ({cell}) => {
        return null
      },
      Cell : (fields) => {

        const {cell, row} = fields,
          {values} = row;

        return(
          <>
            <ConfirmDeleteModal
              message={`Vous souhaitez supprimez le bill ${values.name}`}
              handleDelete={() => {
                apiBills.remove(row.original.id).then(data => {
                  if(data.hasOwnProperty('error')) {
                    setError(data.error);
                    return data;
                  }
                  setBills(bills.filter(bill => bill.id !== row.original.id));
                  setError();
                });
            }} />
            <Link to={`/bill/${row.original.id}/edit`}>
              <Icon.Gear />
            </Link>
          </>
        );
      }
    }]),[])
  };

  useEffect(() => {
    let active = true;
    apiBills.getAll().then(data =>{
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        setLoading(false);
      }
      if(active) {
         setBills(data);
         setLoading(false);
       }
    });

    return () => {
      active = false;
    }
  },[]);

  useEffect(() => {
    table = {
      ...table,
      data : [...bills]
    }
  },[bills.length]);



  return (
    <>
      <h2>Factures <Button variant="secondary" onClick={() => setShowNewBill(true)}>Nouvelle facture</Button></h2>
      {error ? <Alert>{error}</Alert> : null}
      <TableContext table={table} loading={loading}>
        <Consumer>
          {(reactTable) =>
            <GlobalFilter
              globalFilter={reactTable.state.globalFilter}
              onUpdate={(state) => {dispatch({ type: 'UPDATE_BILL_TABLE_STATE', payload: state})}}
              className="manageTable"
              {...reactTable} />
          }

        </Consumer>
        <Consumer>
          {(reactTable) =>
            <ReactTable
              className="manageTable"
              {...reactTable} />
          }

        </Consumer>
      </TableContext>

      <AddBillModal
        initialState={{
          'bill_no' : null,
          'client_id' : null
        }}
        setUpdate={(newBill) => setBills([...bills,newBill])}
        show={showNewBill}
        setShow={setShowNewBill} />

    </>
  )

}

export default BillsOverview
