import axios from 'axios';
import { handleResponse, handleError } from './response';

const BASE_URL = process.env.REACT_APP_API_ROOT;

function axiosConfig(token) {
  if(!token || token==="")
    return {};
  return {
    headers : {'Authorization' : `Bearer ${token}`}
  }
}


function extractArgs(endpoint) {
  let found = [],
    rxp = /{([^}]+)}/g,
    curMatch;

  while( curMatch = rxp.exec( endpoint ) ) {
      found.push( curMatch[1] );
  }
  return found;
}

function placeIdentifier(endpoint, args = ['id'], model = {}) {
  args.forEach((a, i) => {
    endpoint = endpoint.replace(`{${a}}`,model[a]);
  });
  return endpoint;
}

// /hello[/option1]/heres[/option2]/johnny[/option3]
//
//
// match brackets in string
// find match
// add to match array
//     - /hello/heres[/option2]
//     - /hello/option1/heres[/option2]
//
// foreach endpoint, repeat function
// find match
// add - /hello/heres
//     - /hello/heres/option2
//
// and - /hello/option1/heres
//     - /hello/option1/heres/option2


function splitEndpoint(endpoint,it=0) {
  let endpoints = [],
    tmp_endpoints = [],
    found = [],
    regex = /\[([^\][]*)]/g,
    curMatch;

 curMatch = regex.exec(endpoint);
  if ( curMatch != null ) {
    let option = curMatch[1];
    tmp_endpoints.push(endpoint.replace(`[${option}]`,''));
    tmp_endpoints.push(endpoint.replace(`[${option}]`,option));
  } else {
    return endpoint;
  }
  tmp_endpoints.forEach((str, i) => {
      if(str.match(regex) != null)
        endpoints.push(splitEndpoint(str));
      else
        endpoints.push(str);
  });
  return endpoints;
}

function getEndpoints(endpoint) {
  let endpoints = splitEndpoint(endpoint).flat(Infinity);
  endpoints.sort((a, b) => b.length - a.length);
  let args = extractArgs(endpoints[0]);
  return {endpoints, args};
}


/** @param {string} resource */
const getAll = (resource, token) => {
  return axios
    .get(`${BASE_URL}/${resource}`, axiosConfig(token))
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id, token) => {
  let endpoint = (id === undefined) ? `${BASE_URL}/${resource}` : `${BASE_URL}/${resource}/${id}`;
  return axios
    .get(endpoint, axiosConfig(token))
    .then(handleResponse)
    .catch(handleError);
};

const custom = (params, model, token) => {
  let [endpoint, method] = params;
  let args = extractArgs(endpoint);
  let fillEndpoint = placeIdentifier(endpoint, args, model);
  switch (method) {
    case 'get':
      return axios
        .get(`${BASE_URL}/${fillEndpoint}`, axiosConfig(token))
        .then(handleResponse)
        .catch(handleError);
    case 'post':
      return axios
        .post(`${BASE_URL}/${fillEndpoint}`, model, axiosConfig(token))
        .then(handleResponse)
        .catch(handleError);
    case 'put':
      return axios
        .put(`${BASE_URL}/${fillEndpoint}`, model, axiosConfig(token))
        .then(handleResponse)
        .catch(handleError);
    case 'patch':
      return axios
        .patch(`${BASE_URL}/${fillEndpoint}`, model, axiosConfig(token))
        .then(handleResponse)
        .catch(handleError);
    case 'delete':
      return axios
        .delete(`${BASE_URL}/${fillEndpoint}`, axiosConfig(token))
        .then(handleResponse)
        .catch(handleError);
    case 'upload':
      return axios
        .post(`${BASE_URL}/${fillEndpoint}`, model.data, {
          ...axiosConfig(token),
          headers : {
            ...axiosConfig(token).headers,
            'Content-Type' : model.data.type
          }
        })
        .then(handleResponse)
        .catch(handleError);
    case 'basicAuth':
      return axios({
        url : `${BASE_URL}/${fillEndpoint}`,
        method : 'POST',
        auth: model
      })
      .then(handleResponse)
      .catch(handleError);
    default:
      return false;
  }
}

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model, token) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, axiosConfig(token))
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model, token) => {
  return axios
    .put(`${BASE_URL}/${resource}`, model, axiosConfig(token))
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource, id, model, token) => {
  return axios
    .patch(`${BASE_URL}/${resource}/${id}`, model, axiosConfig(token))
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id, token) => {
  return axios
    .delete(`${BASE_URL}/${resource}/${id}`, axiosConfig(token))
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
const download = (resource, model = {}, token) => {
  return axios({
    url : `${BASE_URL}/${resource}`,
    method : 'POST',
    responseType : 'blob',
    data : model,
    headers : axiosConfig(token).headers
    })
    .then(handleResponse)
    .catch(handleError);
};


const basicAuth = (resource, credentials) => {
  return axios({
    url : `${BASE_URL}/${resource}`,
    method : 'POST',
    auth: credentials
  })
  .then(handleResponse)
  .catch(handleError);
}

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
  download,
  basicAuth,
  custom
};
