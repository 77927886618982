import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import { Typeahead } from 'react-bootstrap-typeahead'
import Row  from 'react-bootstrap/Row'
import Col  from 'react-bootstrap/Col'

import cloneDeep from 'lodash'


function SelectBillModal({setUpdate = () => (null), bills = []}){
  const [error, setError] = useState();
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fieldProduct, setFieldProduct] = useState([]);
  const navigate = useNavigate();


  return(
    <>
      <Button size="sm" variant="link" onClick={setShow}>
        <Icon.Receipt />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selectionnez la facture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? <Alert>{error}</Alert> : null}
          <ul class="select-bill-list">
          {bills.map((bill, i) => (
            <li
              key={`select-bill-${i}`}>
              <Button
                variant="secondary"
                onClick={() => navigate(`/bill/${bill.id}`)}>
                {bill.bill_no}
              </Button>
            </li>
          ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default SelectBillModal
