import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';


function ConfirmDeleteModal({
    children,
    handleDelete,
    message,
    title,
    onShow = () => (null),
    onHide = () => (null),
  }){
  const [show, setShow] = useState(false);
  const handleClose = (deleted = false) => {if(!deleted) onHide();setShow(false)};
  const handleShow = () => {onShow();setShow(true)};


  const handleConfirm = (e) => {
    e.preventDefault();
    handleDelete();
    handleClose(true);
  }

  return(
    <>
      {children ?
        React.cloneElement( children, { onClick: handleShow } ) :
        <Button size="sm" variant="link" onClick={handleShow}>
          <Icon.Trash />
        </Button>
      }



      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{message}</div>
          <Button variant="primary" onClick={handleConfirm}>
            Confirmer
          </Button>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default ConfirmDeleteModal;
