import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link, Navigate, useParams } from 'react-router-dom';
import { apiGeography } from './services/api/utilities';
import AddressTypeahead, { apiPhoton } from './FormComponents/AddressTypeahead';
import Mapbox from './Mapbox';
import { Layer, Feature } from 'react-mapbox-gl';


function AddressForm({
    name,
    useMap = false,
    getValues,
    onUpdate = (props) => {},
    initialState = {
      address1 : '',
      address2 : '',
      locality : '',
      postal_code : '',
      country : 'France',
      country_code : 'FR'
    }
  }) {
  const stateDefault = {
    viewport : [initialState.country_code],
  };
  const [selectedStreet, setSelectedStreet] = useState([]);
  const [selectedPostcode, setSelectedPostcode] = useState(initialState.postal_code);
  const [selectedCity, setSelectedCity] = useState([]);
  const [initialCountry, setInitialCountry] = useState();
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [bbox, setBbox] = useState();
  const [marker, setMarker] = useState([]);
  const [viewport, setViewport] = useState(stateDefault.viewport);
  const [bounds, setBounds] = useState();
  const [zoom, setZoom] = useState([11]);
  const [center, setCenter] = useState([1,1]);



  useEffect(() => {
    // IF initialState contains address, set marker/viewport
    let {
      address1, address2, locality, country, postal_code
    } = initialState;

    if(address1?.length && country?.length) {
      let query = new URLSearchParams({
         q : `${address1} ${address2}, ${postal_code.length ? `${postal_code},` : ''} ${locality.length ? `${locality},` : ''} ${country}`
       });
      apiPhoton(query.toString()).then(({data}) =>
        (data.features.length ? setMarker(data.features[0].geometry.coordinates) : null)
      )
    }
  },[initialState]);




  useEffect(() => {
    // TIE bbox to Country
    // If no city set: set viewport to country
    const setInactive = () => { active = false; };
    let active = true;
    if(!selectedCity.length) setBbox();
    if(!selectedCountry.length) return setInactive;
    if(!selectedCountry[0].hasOwnProperty('properties')) return setInactive;
    if(!selectedCountry[0].properties.hasOwnProperty('countrycode')) return setInactive;

    let code = selectedCountry[0].properties.countrycode;
    apiGeography.getSingle(code).then(c => {
      if(c.longmin + c.latmin + c.longmax + c.latmax === 0) return c;
      if(active) {
        if(!selectedCity.length) setViewport([c.Code2]);
        setBbox([c.longmin,c.latmin,c.longmax,c.latmax]);
      }
      return c;
    });
  },[selectedCountry]);


  useEffect(() => {
    // Zoom, center City
    const setInactive = () => { active = false; };
    let active = true;
    if(!selectedCountry.length) setViewport(stateDefault.viewport);
    if(!selectedCity.length) return setInactive;
    let city = selectedCity[0];

    // if(!city.hasOwnProperty('properties')) return setInactive;
    if(city.properties.hasOwnProperty('extent')) {
      let {extent} = city.properties;
      if(active) setViewport(extent);
      return setInactive;
    }

    if(!city.hasOwnProperty('geometry')) return setInactive;
    if(city.geometry) {
      setViewport([...city.geometry.coordinates,11]);
    }





  },[selectedCity]);





  useEffect(() => {
    // TIE marker to street
    const setInactive = () => { active = false; };
    let active = true;
    setMarker([]);
    if(!selectedStreet.length) return setInactive;
    if(!selectedStreet[0].hasOwnProperty('geometry')) return setInactive;
    if(!selectedStreet[0].geometry.hasOwnProperty('coordinates')) return setInactive;

    setMarker(selectedStreet[0].geometry.coordinates);
    setViewport([...selectedStreet[0].geometry.coordinates,14]);

  },[selectedStreet]);


  useEffect(() => {
    onUpdate({selectedStreet, selectedCity, selectedCountry});

  },[selectedStreet, selectedCity, selectedCountry]);



  return (
    <>
      {useMap ? <Mapbox
        height="300px"
        width="100%"
        pitch={[40]}
        viewport={viewport}
        center={center}
        zoom={zoom}
        fitBounds={bounds}
         >
         <Layer
          type="symbol"
          id="marker"
          minZoom={0}
          maxZoom={24}
          layout={{
            // 'icon-image' : 'GeoFill',
            'icon-image' : 'GeoFill_White',
            'visibility' : 'visible',
            'icon-allow-overlap' : true,
            // 'color' : '#ffffff'
          }}
          >

           {marker.length ? <Feature coordinates={marker} /> : null}
         </Layer>
      </Mapbox> : null}
      <Row>
        <Col xs="9">
          <Form.Label>Street</Form.Label>
          <Form.Control
            type="hidden"
            name={`${name}-address1`}
            value={selectedStreet.length ? selectedStreet[0].label : initialState.address1} />
          <AddressTypeahead
            id="street"
            limit={100}
            bbox={bbox ? bbox.reduce((t,c) => (`${t},${c}`),'').substr(1) : false}
            defaultSelected={selectedStreet}
            type="street"
            defaultInputValue={initialState.address1}
            filterSuggestions={(suggestion, index, self) => {
              // return one value per name, city, country
              // return true;
              return (
                self.map(({
                  properties: {city, name, country}
                }) => (JSON.stringify({city, name, country}))).indexOf(JSON.stringify({
                  city : suggestion.properties.city,
                  name : suggestion.properties.name,
                  country : suggestion.properties.country,
                })) === index
              );
            }}
            format={({properties : {name, city, county, state, country}},props) => (
              <>
                <span>
                  <Highlighter search={props.text}>{name}</Highlighter>
                </span>
                <br />
                <span className="option-secondary">{city || county || state}, {country}</span>
              </>
            )}
            onChange={(results) => {
              if(results.length === 0) {
                setSelectedStreet([]);
                return true;
              }

              let [result] = results;
              let {geometry, properties } = result;
              let { city,
                osm_id,
                county,
                locality,
                postcode,
                country,
                state,
                village,
                countrycode } = properties;
              setSelectedStreet([result]);

              if(city)
                setSelectedCity([{id: city.trim(), label: city, type: 'city', geometry, properties}]);
              else if(county)
                setSelectedCity([{id: county.trim(), label: county, type: 'county', geometry, properties}]);
              else if(locality)
                setSelectedCity([{id: locality.trim(), label: locality, type: 'locality', geometry, properties}]);
              else if(state)
                setSelectedCity([{id: state.trim(), label: state, type: 'state', geometry, properties}]);
              else if(village)
                setSelectedCity([{id: village.trim(), label: village, type: 'village', geometry, properties}]);

              
              if(postcode)
                setSelectedPostcode(postcode);
              if(country)
                setSelectedCountry([{id: countrycode, label: country, properties: {countrycode}}]);

            }}
             />
        </Col>
        <Col xs="3">
          <Form.Label>House No.</Form.Label>
          <Form.Control name={`${name}-address2`} type="text" defaultValue={initialState.address2} />
        </Col>
      </Row>
      <Row>
        <Col xs="9">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="hidden"
            name={`${name}-locality`}
            value={selectedCity.length ? selectedCity[0].label : initialState.locality} />
          <AddressTypeahead
            id="city"
            bbox={bbox ? bbox.reduce((t,c) => (`${t},${c}`),'').substr(1) : false}
            type="locality"
            limit={100}
            defaultInputValue={initialState.locality}
            format={({properties : {name, country, countrycode}},props) => (
              <>
                <span>

                  <Highlighter search={props.text}>{name}</Highlighter>
                </span>
                <br />
                <span className="option-secondary">
                  {hasFlag(countrycode) ? `${getUnicodeFlagIcon(countrycode)} ` : null}
                  {country}
                </span>
              </>
            )}
            filterSuggestions={(suggestion, index, self) => {
              // return one value per name, country
              // return true;
              return (
                self.map(({
                  properties: {name, country}
                }) => (JSON.stringify({name, country}))).indexOf(JSON.stringify({
                  name : suggestion.properties.name,
                  country : suggestion.properties.country,
                })) === index
              );
            }}
            selected={selectedCity}
            onChange={(results) => {
              if(results.length === 0) {
                setSelectedCity([]);
                return true;
              }

              let [result] = results;
              let {properties :
                    {
                      country,
                      countrycode,
                      postcode
                     }
                    } = result;
              setSelectedCity([result]);

              if(postcode && !postcode.includes(';'))
                setSelectedPostcode(postcode);
              if(country)
                setSelectedCountry([{id: countrycode, label: country, properties: {countrycode}}]);

            }}
             />
        </Col>
        <Col>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control name={`${name}-postal_code`} type="text" onChange={(e) => setSelectedPostcode(e.target.value)} value={selectedPostcode} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="hidden"
            name={`${name}-country_code`}
            value={selectedCountry.length ? selectedCountry[0].properties.countrycode : initialState.country_code} />
          <AddressTypeahead
            id="country"
            type="country"
            selected={selectedCountry}
            promptLimit={0}
            defaultInputValue={initialState.country}
            onChange={(results) => {
              if(results.length === 0) {
                setSelectedCountry([]);
                return true;
              }

              let [result] = results;
              let {properties :
                    {
                      country,
                      countrycode,
                      postcode
                     }
                    } = result;
              setSelectedCountry([result]);

            }}
            format={({properties : {name, countrycode}},props) => (
              <>
                {hasFlag(countrycode) ? `${getUnicodeFlagIcon(countrycode)} ` : null}
                <Highlighter search={props.text}>{name}</Highlighter>
              </>
            )}
             />
        </Col>
      </Row>
    </>
  )
}


export default AddressForm
