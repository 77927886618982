const initialState = {
  filters : [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // PRODUCT_TABLE
    case 'SET_PRODUCTS_TABLE_FILTER':
      let { filters } = action.payload;
      return { ...state, filters };
    case 'UPDATE_FROM_DB' :
      return { ...state, ...action.payload.products }
    case 'RESET_ALL':
      return initialState;
    default:
      return state;
  }
}

export default productReducer
