import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useParams, Navigate } from 'react-router-dom';

import { apiUsers } from './services/api/utilities';



function ResetPassword({ setMailPlaceholder }) {
  const hash = useParams().reset_hash;
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    if(!hash || hash === undefined)
      setRedirect('/');



    return ()=> {}
  }, [hash]);


  const handleSubmit = (e) => {
    e.preventDefault();
    let password = e.target.elements.password.value,
        repeat_password = e.target.elements.repeat_password.value;
    if(password !== repeat_password) {
      setError('Les mots de passe ne corréspondent pas');
      return;
    };
    apiUsers.resetPassword({hash, password}).then(data => {
      if(data.hasOwnProperty('error')) {
        setError(data.error);
        return data;
      }
      if(data.hasOwnProperty('mail'))
        setMailPlaceholder(data.mail);
      setRedirect('/');
      return data;
    });
  }

  if(redirect)
    return <Navigate to={redirect} />;

  return(
    <div className="reset-wrapper">
      <Form onSubmit={handleSubmit}>
        <h2>Choisir un nouveau mot de passe</h2>
        { error ? <Alert>{error}</Alert> : null}
        <Form.Group>
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control type="password" name="password" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Repetez mot de passe</Form.Label>
          <Form.Control type="password" name="repeat_password" />
        </Form.Group>
        <Button type="submit">Confirmer</Button>
      </Form>
    </div>
  )

}



export default ResetPassword;
