import { useSelector, useDispatch } from 'react-redux';
import { setToken } from '../actions';
import { apiSession } from '../services/api/utilities';

function useToken() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.token === undefined ? "" : state.token);

  const saveToken = (userToken) => {
    if(userToken === undefined) {
      dispatch(setToken(""));
    } else {
      dispatch(setToken(userToken.token));
    }
  };

  const verifyToken = () => {
    // if no token set, return false (wrapped in promise) immediately
    if (token === "")
      return new Promise(resolve => resolve(false));

    // if token is set, return post to session api to verify it is active
    return apiSession.getSingle().then(data => {
      if(data.hasOwnProperty('error')) {
        return false;
      }
      return true;
    });
  }

  return {
    setToken : saveToken,
    token,
    verifyToken
  }

}

export default useToken;
